import React from "react";
import ActionDropdown from "./action_dropdown";
import PropTypes from "prop-types";

function ClientActionDropdown({
  showImportClientsBtn = true,
  showDownloadAllClientsBtn = false,
  showDownloadLocationClientsBtn = true,
  showDownloadHealthReportBtn = false
}) {
  const actions = [
    {
      actionText: "Import Clients",
      actionFn: () => document.querySelector("#client_page_header_import_clients").click(),
      show: showImportClientsBtn
    },
    {
      actionText: "Download All Clients",
      actionFn: () => document.querySelector("#client-page-header-download-all-clients").click(),
      show: showDownloadAllClientsBtn
    },
    {
      actionText: "Download Locations Clients",
      actionFn: () =>
        document.querySelector("#client-page-header-download-location-clients").click(),
      show: showDownloadLocationClientsBtn
    },
    {
      actionText: "Export Client Health card issue Report",
      actionFn: () => document.querySelector("#client-page-header-download-health-report").click(),
      show: showDownloadHealthReportBtn
    }
  ];

  return (
    <ActionDropdown buttonText="Download Clients" actions={actions} dropdownWidth="max-content" />
  );
}

ClientActionDropdown.propTypes = {
  showImportClientsBtn: PropTypes.bool,
  showDownloadAllClientsBtn: PropTypes.bool,
  showDownloadLocationClientsBtn: PropTypes.bool,
  showDownloadHealthReportBtn: PropTypes.bool
};

export default ClientActionDropdown;
