/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup
} from "@mui/material";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { styleForPourAndDispenseModal } from "./pour_and_dispense_utils";
import PourAndDispenseModalCloseIcon from "./pour_and_dispense_close_icon";

export const alcoholAmountOptions = [
  {
    label: "N/A",
    value: -1
  },
  {
    label: 0,
    value: 0
  },
  {
    label: 0.5,
    value: 0.5
  },
  {
    label: 1,
    value: 1
  },
  {
    label: 1.5,
    value: 1.5
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 2.5,
    value: 2.5
  },
  {
    label: 3,
    value: 3
  },
  {
    label: 3.5,
    value: 3.5
  },
  {
    label: 4,
    value: 4
  },
  {
    label: 4.5,
    value: 4.5
  },
  {
    label: 5,
    value: 5
  },
  {
    label: 5.5,
    value: 5.5
  },
  {
    label: 6,
    value: 6
  },
  {
    label: 6.5,
    value: 6.5
  },
  {
    label: 7,
    value: 7
  },
  {
    label: 7.5,
    value: 7.5
  },
  {
    label: 8,
    value: 8
  },
  {
    label: 8.5,
    value: 8.5
  },
  {
    label: 9,
    value: 9
  },
  {
    label: 9.5,
    value: 9.5
  },
  {
    label: 10,
    value: 10
  }
];

const PourAndDispenseInputModal = ({ time, isOpen, onComplete, canDelete }) => {
  const [confirmedCloseWithoutCompleting, setConfirmedCloseWithoutCompleting] = useState(false);
  const [typeOfAlcohol, setTypeOfAlcohol] = useState(undefined);
  const [amountOfAlcohol, setAmountOfAlcohol] = useState(undefined);

  const onClose = () => {
    if (!amountOfAlcohol || !typeOfAlcohol) {
      closeAction();
      return;
    }
    setTypeOfAlcohol(undefined);
    setAmountOfAlcohol(undefined);
    onComplete(typeOfAlcohol, amountOfAlcohol);
  };

  const closeAction = () => {
    if (!confirmedCloseWithoutCompleting) {
      toastr.error("Error did not fill out one or both sections. Click away again to close.");
      setConfirmedCloseWithoutCompleting(true);
    } else {
      setConfirmedCloseWithoutCompleting(false); // resetting variable
      setTypeOfAlcohol(undefined);
      setAmountOfAlcohol(undefined);
      onComplete();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={styleForPourAndDispenseModal}>
        <PourAndDispenseModalCloseIcon onClick={() => onComplete()} />
        <h2>{time}</h2>
        <br />
        {canDelete && (
          <Button onClick={() => onComplete(undefined, undefined, true)}>Delete Entry</Button>
        )}
        <div className="pour_and_dispense_modal">
          <div className="pour_and_dispense_modal_section">
            <FormControl>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                onChange={(event) => setTypeOfAlcohol(event.target.value)}
                value={typeOfAlcohol}
              >
                <FormControlLabel value="sherry" control={<Radio />} label="Sherry" />
                <FormControlLabel value="beer" control={<Radio />} label="Beer" />
                <FormControlLabel value="wine" control={<Radio />} label="Wine" />
                <FormControlLabel value="special_blend" control={<Radio />} label="Special blend" />
                <FormControlLabel value="hard_liquor" control={<Radio />} label="Hard liquor" />
                <FormControlLabel value="extra_special" control={<Radio />} label="Extra special" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="pour_and_dispense_modal_section">
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <RadioGroup
                onChange={(event) => setAmountOfAlcohol(event.target.value)}
                value={amountOfAlcohol}
              >
                {alcoholAmountOptions.map((amount, index) => {
                  return (
                    <FormControlLabel
                      key={`alcoholAmountOptions ${index} ${amount.label}}`}
                      value={amount.value}
                      control={<Radio />}
                      label={amount.label + ""}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PourAndDispenseInputModal;
