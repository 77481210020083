import React, { useEffect, useMemo, useRef, useState } from "react";
import { CompositeDecorator, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { convertFromHTML } from "draft-convert";
import PropTypes from "prop-types";
import CustomEditorUtils from "../../CustomEditorUtils";
import ReadMorePopup from "../read_more_popup";

const END_TAG_REGEX = /\[END\]/g;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text))) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}
// eslint-disable-next-line no-unused-vars
const endStrategy = (contentBlock, callback, contentState) => {
  findWithRegex(END_TAG_REGEX, contentBlock, callback);
};

const EndSpan = ({ children }) => {
  return <span className="endTag">{children}</span>;
};
EndSpan.propTypes = {
  children: PropTypes.node.isRequired
};

const endDecorator = {
  strategy: endStrategy,
  component: EndSpan
};

export default function MentionEditorRenderer({ defaultHTML, clientName, showFullContent }) {
  const ref = useRef(null);
  const editorContainerRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator([endDecorator]);

    const inputHtml = (() => {
      if (clientName) {
        const extractor = new CustomEditorUtils.ExtractClientLog(defaultHTML, clientName);
        const clientsLogList = extractor.clientGroups;

        if (clientsLogList.length > 0) {
          return clientsLogList.join(" ");
        } else {
          return extractor.generalGroups;
        }
      } else {
        return defaultHTML;
      }
    })();

    if (inputHtml) {
      const contentState = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a" && node.className.includes("mention") && node.href) {
            // extract id from mention's href
            const id = node.href.split("/").pop();
            const { publicPath } = CustomEditorUtils.createClientMention(id);
            // When creating the path for rendering, we use the `publicPath` instead of the original `href`. This ensures that the link remains valid even if the host changes.
            return createEntity("mention", "IMMUTABLE", {
              mention: {
                name: node.innerText,
                link: publicPath
              }
            });
          }
          return null;
        }
      })(inputHtml);
      return EditorState.createWithContent(contentState, decorator);
    } else {
      return EditorState.createEmpty(decorator);
    }
  });
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: {
        mention: "mention",
        mentionSuggestions: "mentionSuggestions",
        mentionSuggestionsEntry: "mentionSuggestionsEntry",
        mentionSuggestionsEntryFocused: "mentionSuggestionsEntryFocused",
        mentionSuggestionsEntryText: "mentionSuggestionsEntryText",
        mentionSuggestionsEntryAvatar: "mentionSuggestionsEntryAvatar"
      },
      mentionComponent(mentionProps) {
        if (mentionProps.mention.name === "[END]") {
          return <span className="endTag">{mentionProps.children}</span>;
        }

        const { link } = mentionProps.mention;
        const id = link.split("/").pop();
        const { editorPath } = CustomEditorUtils.createClientMention(id);
        return (
          <a href={editorPath} className={mentionProps.className} target="_blank" rel="noreferrer">
            {mentionProps.children}
          </a>
        );
      }
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = editorContainerRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  }, [editorState]);

  return (
    <>
      <div className="renderer-wrapper">
        <div
          className={`renderer ${showFullContent ? "" : "truncated"}`}
          ref={editorContainerRef}
          onClick={() => {
            ref.current.focus();
          }}
        >
          <Editor
            editorKey={"editor"}
            editorState={editorState}
            readOnly
            decorators={[endDecorator]}
            onChange={setEditorState}
            plugins={plugins}
            ref={ref}
          />
          <MentionSuggestions
            open={false}
            onOpenChange={() => {}}
            onSearchChange={() => {}}
            suggestions={[]}
          />
        </div>
        {isOverflowing && !showFullContent && (
          <a id="readMore" onClick={() => setShowPopup(true)}>
            <u>{"Read more..."}</u>
          </a>
        )}
      </div>
      <ReadMorePopup show={showPopup} setShow={setShowPopup}>
        <div className={`renderer`}>
          <Editor
            editorKey={"editor"}
            editorState={editorState}
            readOnly
            onChange={setEditorState}
            decorators={[endDecorator]}
            plugins={plugins}
            ref={ref}
          />
          <MentionSuggestions
            open={false}
            onOpenChange={() => {}}
            onSearchChange={() => {}}
            suggestions={[]}
          />
        </div>
      </ReadMorePopup>
    </>
  );
}

MentionEditorRenderer.propTypes = {
  defaultHTML: PropTypes.string,
  clientName: PropTypes.string,
  showFullContent: PropTypes.bool
};
