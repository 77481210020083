import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialState = {
  pageParams: {
    date: null,
    shiftNum: null,
    location: null
  },
  selectedRowShiftNum: null,
  selectedShiftLog: null,
  selectedClientId: null,
  selectedClientName: null,
  selectedClientDetail: null,
  selectedDateForNewLog: null,
  action: null,
  popup: false
};
const TableViewContext = createContext(null);

function reducer(state, action) {
  if (action.type === "reset") {
    return { ...initialState, pageParams: { ...state.pageParams } };
  }
  return { ...state, [action.type]: action.payload };
}

function useTableViewContext() {
  const context = useContext(TableViewContext);
  return context;
}

function TableViewContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, pageParams: props.pageParams });

  return (
    <TableViewContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TableViewContext.Provider>
  );
}
TableViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  pageParams: PropTypes.object
};

export default TableViewContextProvider;
export { useTableViewContext };
