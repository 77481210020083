import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomerEditor from "../custom_editor/custom_editor";
import CustomEditorUtils from "../../CustomEditorUtils";
import { ClientList } from "./client_list";
import NurseRecapToggleProvider from "./nurse_recap_toggle_provider";

export const getClientLinks = (clients, mainClient = null) => {
  return clients.map((client) => {
    const isMainClient = mainClient && client.id == mainClient.id;

    const publicUrl = new CustomEditorUtils.createClientMention(client.id).publicPath;
    return `<a href="${publicUrl}" class="mention ${
      isMainClient ? "main-client-mention" : ""
    }" target="_blank">${client.name}</a>`;
  });
};
export const getClientsFromHtml = (html) => {
  const clientNameAndIdRegex = /<a href="[^"]*\/clients\/(\d+)"[^>]*>([^<]*)<\/a>/g;
  const clientNameAndIdMatches = [...html.matchAll(clientNameAndIdRegex)];

  return clientNameAndIdMatches.map((match) => ({
    id: +match[1],
    name: match[2]
  }));
};

export default function NurseLogEditor({
  defaultContent = "",
  client = null,
  isNurseRecapHidden,
  isGeneralLogSeparate,
  textareaName = "client_log[nurse_log]",
  clientFieldName = "client_log[client_ids]"
}) {
  const [content, setContent] = useState("");
  const [taggedClients, setTaggedClients] = useState([]);
  const [isClientMentioned, setIsClientMentioned] = useState(false);

  const defaultClientHtml = client ? getClientLinks([client], client)[0] : "";

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === "attributes") {
          // This is the only way to get the textarea value since this DOM element is being changed programmatically by CustomerEditor Component
          setContent(mutation.target.getAttribute("data-value"));
        }
      }
    });

    const elementNode = document.querySelector(`[name='${textareaName}']`);
    observer.observe(elementNode, { attributes: true });

    return () => observer.disconnect();
  }, []);

  const clientIdsInputRef = useRef(null);

  // Watch changes in Content to find tagged clients
  useEffect(() => {
    const logContent = client ? `${content} ${defaultClientHtml}` : content;
    const taggedClients = getClientsFromHtml(logContent);
    setTaggedClients(taggedClients);
  }, [content]);

  // Watch all list and see which clients are added and which are removed
  useEffect(() => {
    if (clientIdsInputRef.current) {
      const finalClientIds = taggedClients.map((client) => client.id);
      clientIdsInputRef.current.value = finalClientIds.join(",");
    }
  }, [taggedClients]);

  return (
    <div className="nurse-log-editor-container">
      <input ref={clientIdsInputRef} type="text" name={clientFieldName} className="hidden" />
      <NurseRecapToggleProvider
        isNurseRecapHidden={isNurseRecapHidden}
        isGeneralLogSeparate={isGeneralLogSeparate}
      >
        <CustomerEditor
          name={textareaName}
          defaultContent={defaultContent}
          isClientMentioned={isClientMentioned}
          setIsClientMentioned={setIsClientMentioned}
        />
      </NurseRecapToggleProvider>
      {!isNurseRecapHidden && ( // Disabling Nurse Recap Features in Current Deployment
        <ClientList isClientMentioned={isClientMentioned} taggedClients={taggedClients} />
      )}
    </div>
  );
}

NurseLogEditor.propTypes = {
  defaultContent: PropTypes.string,
  client: PropTypes.object,
  isNurseRecapHidden: PropTypes.bool.isRequired,
  isGeneralLogSeparate: PropTypes.bool.isRequired,
  textareaName: PropTypes.string,
  clientFieldName: PropTypes.string
};
