import React from "react";
import PropTypes from "prop-types";
import ShiftLogContainer from "./shift_log_container";
import { useDenseViewContext } from "./DenseViewContext";

const ShiftLogList = ({ shiftLogs }) => {
  const { state: filterFields } = useDenseViewContext();
  // Filter the shiftLogs based on the isSeen filter value
  const filteredShiftLogs = shiftLogs.filter((shiftLog) => {
    if (filterFields.isSeen === "Seen") {
      return shiftLog.seen === true;
    } else if (filterFields.isSeen === "NotSeen") {
      return shiftLog.seen === false;
    }
    return true; // If 'All', no filtering is applied
  });

  return (
    <div className="dense-view">
      {filteredShiftLogs.map((shiftLog, index) => (
        <ShiftLogContainer key={index} shiftLog={shiftLog} />
      ))}
    </div>
  );
};
ShiftLogList.propTypes = {
  shiftLogs: PropTypes.array.isRequired
};

export default ShiftLogList;
