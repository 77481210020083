import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const InputAutocompleteDropdown = ({ inputId, options, value = "" }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [isListVisible, setIsListVisible] = useState(false);
  const dropdownContainerRef = useRef(null);

  // Convert (Array of Array of Strings) to (Array of Strings)
  options = Array.isArray(options[0]) ? options.flat() : options;

  // Convert options to a Set and then back to an array to remove duplicates
  options = [...new Set(options)];

  useEffect(() => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.value = selectedOption;
    }
  }, [selectedOption, inputId]);

  const handleChange = (value) => {
    setSelectedOption(value);
    setIsListVisible(false);
  };
  // Add listener to the input element
  useEffect(() => {
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.addEventListener("click", () => {
        setIsListVisible(true);
      });
    }
    return () => {
      inputElement.removeEventListener("click", () => {
        setIsListVisible(true);
      });
    };
  }, []);

  // Click outside the dropdown, close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownContainerRef.current && dropdownContainerRef.current.contains(event.target)) {
        setIsListVisible(true);
      } else {
        setIsListVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownContainerRef]);

  return (
    <div className="input-autocomplete-dropdown">
      {isListVisible && options.length > 0 && (
        <div ref={dropdownContainerRef} className="dropdown">
          <ul>
            {options.map((option, index) => (
              <li key={`${index}-${option}`} onClick={() => handleChange(option)}>
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

InputAutocompleteDropdown.propTypes = {
  inputId: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  ]).isRequired,
  value: PropTypes.string
};
export default InputAutocompleteDropdown;
