import { useState, useCallback, useEffect } from "react";

const useAsyncCallback = (asyncCallback, defaultData = null, runOnInit = true) => {
  const [loading, setLoading] = useState(runOnInit);
  const [error, setError] = useState(null);
  const [data, setData] = useState(defaultData);

  const execute = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await asyncCallback();
      setData(result);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, [asyncCallback]);
  useEffect(() => {
    execute();
  }, []);

  return { loading, error, data, execute };
};

export default useAsyncCallback;
