import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DynamicTableContext } from "../dynamic_table";

function ColumnHeading({ heading, index }) {
  const { filteredData, setFilteredData, sortingColumnIndex, setSortingColumnIndex } =
    useContext(DynamicTableContext);
  const [sortOrder, setSortOrder] = useState("asc");

  function handleSort(index) {
    let newSortOrder = "asc";
    if (sortingColumnIndex === index) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      const key = Object.keys(a)[index];
      if (a[key] < b[key]) {
        return newSortOrder === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return newSortOrder === "asc" ? 1 : -1;
      }
      // Compare by createdDate if other fields are equal
      if (a["createdDate"] && !b["createdDate"]) {
        return 1;
      }
      if (!a["createdDate"] && b["createdDate"]) {
        return -1;
      }
      if (a["createdDate"] !== b["createdDate"]) {
        const aDate = dayjs(a["createdDate"]);
        const bDate = dayjs(b["createdDate"]);
        return bDate.diff(aDate);
      }
      return 0;
    });
    setSortOrder(newSortOrder);
    setSortingColumnIndex(index);
    setFilteredData(sortedData);
  }
  return (
    <div className="column-cell" onClick={() => handleSort(index)}>
      {heading}
      <span style={{ display: sortingColumnIndex === index ? "unset" : "none" }}>
        {sortOrder === "asc" ? "↓" : "↑"}
      </span>
    </div>
  );
}

ColumnHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
export default ColumnHeading;
