import React from "react";
import PropTypes from "prop-types";

export const NurseLogToggleContext = React.createContext({
  isNurseRecapHidden: true,
  isGeneralLogSeparate: false
});

const NurseRecapToggleProvider = ({ children, isNurseRecapHidden, isGeneralLogSeparate }) => {
  return (
    <NurseLogToggleContext.Provider value={{ isNurseRecapHidden, isGeneralLogSeparate }}>
      {children}
    </NurseLogToggleContext.Provider>
  );
};
export default NurseRecapToggleProvider;

NurseRecapToggleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isNurseRecapHidden: PropTypes.bool.isRequired,
  isGeneralLogSeparate: PropTypes.bool.isRequired
};
