import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

function UnifiedSearchAutocompleteComponent({
  // The name of the input field, used to set the name attribute of the input element
  input_field_name,
  // The initially selected name, used to set the initial state
  selected_name = "",
  // The list of options for the autocomplete, used to filter and display suggestions
  options,
  // A list of hardcoded options for the autocomplete, used to filter and display additional suggestions
  hardCodedOptions,
  // Function to handle form submission, likely used to process the selected name
  handleFormSubmit,
  // Function to handle clicks on dropdown items, used to set the selected name and option
  handleDropdownItemClick,
  // Function to handle clearing the input field, used to reset the selected name and option
  handleClearClick,
  // Function to handle changes in the selected option, used to update the parent component or state
  handleOnSelectedOptionChange,
  // Function to handle changes in the input value, used to update the parent component or state
  handleInputChange,
  // The ID for the input field, used to uniquely identify the input element
  inputFieldId,
  // The placeholder text for the input field, used to guide the user on what to input
  inputFieldPlaceholder,
  // Function to handle input focus event
  handleInputFocus,
  // Style for the dropdown container
  dropdownStyle
}) {
  const [selectedName, setSelectedName] = useState(selected_name);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [isListVisible]);
  // References to the input field and dropdown container elements for managing focus and click events
  const inputRef = useRef(null);
  const dropdownContainerRef = useRef(null);

  // Filtered options from the provided list based on the current input value
  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(selectedName.toLowerCase())
    );
  }, [options, selectedName]);

  const filteredHardCodedOptions = useMemo(() => {
    return hardCodedOptions.filter((option) =>
      option.name.toLowerCase().includes(selectedName.toLowerCase())
    );
  }, [hardCodedOptions, selectedName]);

  // Set the initial selected name from props when the component mounts or updates
  useEffect(() => {
    setSelectedName(selected_name);
  }, [selected_name]);

  const combinedFilteredOptions = useMemo(() => {
    return [...filteredHardCodedOptions, ...filteredOptions];
  }, [filteredHardCodedOptions, filteredOptions]);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();

        if (!isListVisible) {
          setIsListVisible(true);
        }
        setHighlightedIndex((prevIndex) =>
          prevIndex < combinedFilteredOptions.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        if (!isListVisible) {
          setIsListVisible(true);
        }
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : combinedFilteredOptions.length - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < combinedFilteredOptions.length) {
          handleDropdownClickInternal(combinedFilteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        setIsListVisible(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  // Handle clicks outside the input and dropdown to hide the list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (inputRef.current && inputRef.current.contains(event.target)) ||
        (dropdownContainerRef.current && dropdownContainerRef.current.contains(event.target))
      ) {
        setIsListVisible(true);
      } else {
        setIsListVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, dropdownContainerRef]);

  // Handle dropdown item click to set the selected name and option, and redirect to the incident reports page
  const handleDropdownClickInternal = (option) => {
    setSelectedName(option.name);
    setSelectedOption(option);
    setIsListVisible(false);
    if (handleDropdownItemClick) {
      handleDropdownItemClick(option);
    }
    if (handleOnSelectedOptionChange) {
      handleOnSelectedOptionChange(option);
    }
  };

  const handleSubmitBtnClick = () => {
    if (handleFormSubmit) {
      handleFormSubmit(selectedOption);
    }
  };

  // Handle form submission event
  const handleFormSubmitInternal = (e) => {
    e.preventDefault();
    if (handleFormSubmit) {
      handleFormSubmit(selectedOption);
    }
  };

  // Handle clear label click event
  const handleClearClickInternal = () => {
    setSelectedName("");
    setSelectedOption(null);
    if (handleClearClick) {
      handleClearClick();
    }
  };

  // Handle input change event
  const handleInputChangeInternal = (e) => {
    setSelectedName(e.target.value);
    const newSelectedOption = findOption(e.target.value);
    setSelectedOption(newSelectedOption);
    setIsListVisible(true);
    if (handleInputChange) {
      handleInputChange(e);
    }
    if (handleOnSelectedOptionChange) {
      handleOnSelectedOptionChange(newSelectedOption);
    }
  };

  function findOption(name) {
    return options.find((option) => option.name === name) || null;
  }
  return (
    <div className="client-search-input-autocomplete">
      {/* Form to handle search input */}
      <form onSubmit={handleFormSubmitInternal}>
        {/* Input field for name search */}
        <InputField
          inputRef={inputRef}
          value={selectedName}
          onChange={handleInputChangeInternal}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          id={inputFieldId}
          name={input_field_name}
          placeholder={inputFieldPlaceholder}
        />
        {/* Submit button for the search form */}
        <button type="submit" className="search-btn" onClick={handleSubmitBtnClick}>
          <i className="fas fa-search"></i>
        </button>
      </form>

      {/* Clear label to reset the search input */}
      {selectedName && (
        <label className="clear-label" onClick={handleClearClickInternal}>
          <i className="fas fa-times"></i> Clear
        </label>
      )}

      {/* Dropdown list for filtered options */}
      {isListVisible && (filteredOptions.length > 0 || filteredHardCodedOptions.length > 0) && (
        <div ref={dropdownContainerRef} className="dropdown-container" style={dropdownStyle}>
          <DropdownList
            filteredOptions={filteredOptions}
            filteredHardCodedOptions={filteredHardCodedOptions}
            onClick={handleDropdownClickInternal}
            highlightedIndex={highlightedIndex}
          />
        </div>
      )}
    </div>
  );
}

UnifiedSearchAutocompleteComponent.propTypes = {
  // Name of the input field
  input_field_name: PropTypes.string,
  // Name of the selected option
  selected_name: PropTypes.string,
  // Array of options to display in the dropdown
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  // Array of hard-coded options to display in the dropdown
  hardCodedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired
    })
  ),
  // Function to handle form submission
  handleFormSubmit: PropTypes.func,
  // Function to handle dropdown item click
  handleDropdownItemClick: PropTypes.func,
  // Function to handle clear button click
  handleClearClick: PropTypes.func,
  // Function to handle change in selected option
  handleOnSelectedOptionChange: PropTypes.func,
  // Function to handle input change
  handleInputChange: PropTypes.func,
  // ID of the input field
  inputFieldId: PropTypes.string.isRequired,
  // Placeholder text for the input field
  inputFieldPlaceholder: PropTypes.string.isRequired,
  // Function to handle input focus
  handleInputFocus: PropTypes.func,
  // Style object for the dropdown
  dropdownStyle: PropTypes.object
};

// InputField component for the input element
const InputField = ({ inputRef, value, onChange, onKeyDown, onFocus, id, placeholder, name }) => (
  <input
    ref={inputRef}
    autoComplete="off"
    id={id}
    className="input"
    name={name}
    type="text"
    placeholder={placeholder}
    onKeyDown={onKeyDown}
    value={value}
    onChange={onChange}
    onFocus={onFocus}
  />
);

InputField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string
};

// DropdownList component for rendering the dropdown options
const DropdownList = ({ filteredOptions, filteredHardCodedOptions, onClick, highlightedIndex }) => {
  const listRef = useRef(null);
  const itemRefs = useRef([]);
  useEffect(() => {
    itemRefs.current = new Array(filteredHardCodedOptions.length + filteredOptions.length)
      .fill()
      .map(() => React.createRef());
  }, [filteredHardCodedOptions, filteredOptions]);

  useEffect(() => {
    if (highlightedIndex >= 0 && listRef.current && itemRefs.current[highlightedIndex]?.current) {
      const listRect = listRef.current.getBoundingClientRect();
      const itemRect = itemRefs.current[highlightedIndex].current.getBoundingClientRect();

      if (itemRect.top < listRect.top) {
        listRef.current.scrollTop -= listRect.top - itemRect.top;
      } else if (itemRect.bottom > listRect.bottom) {
        listRef.current.scrollTop += itemRect.bottom - listRect.bottom;
      }
    }
  }, [highlightedIndex]);

  return (
    <ul ref={listRef} style={{ maxHeight: "200px", overflowY: "auto" }}>
      {filteredHardCodedOptions.map((option, index) => {
        return (
          <li
            key={`${option.id} ${option.name}`}
            ref={itemRefs.current[index]}
            onClick={() => onClick(option)}
            data-current-index={index}
            className={index === highlightedIndex ? "highlighted" : ""}
          >
            {option.name}
          </li>
        );
      })}
      {filteredOptions.length > 0 && filteredHardCodedOptions.length > 0 && (
        <li className="divider"></li>
      )}
      {filteredOptions.map((option, index) => {
        const actualIndex = index + filteredHardCodedOptions.length;
        return (
          <li
            key={`${option.id} ${option.name}`}
            ref={itemRefs.current[actualIndex]}
            onClick={() => onClick(option)}
            data-current-index={actualIndex}
            className={actualIndex === highlightedIndex ? "highlighted" : ""}
          >
            {option.name}
          </li>
        );
      })}
    </ul>
  );
};

DropdownList.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  filteredOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  filteredHardCodedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired
};

export default UnifiedSearchAutocompleteComponent;
