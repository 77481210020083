/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";
function ClientStatusToggle({ clientId, isActive, setClientInActive }) {
  const btnOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleClientStatus(clientId, isActive, setClientInActive);
  };

  return (
    <a
      explorator_test_id={`client-duplicated-row-mark-as-${
        isActive ? "active" : "inactive"
      }-${clientId}`}
      className={`client-duplicated-row-mark-as-${isActive ? "active" : "inactive"}`}
      onClick={btnOnClick}
    >
      {isActive ? "Mark as Inactive" : "Mark as Active"}
    </a>
  );
}
ClientStatusToggle.propTypes = {
  clientId: PropTypes.number,
  isActive: PropTypes.bool,
  setClientInActive: PropTypes.func
};

function toggleClientStatus(clientId, isActive, setClientInActive) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
  const statusType = isActive ? "inactive_status" : "active_status";
  const url = `/clients/${clientId}/${statusType}.json`;
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  };

  fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      if (Object.keys(data).includes("client_inactive")) {
        setClientInActive(data.client_inactive);
      } else {
        alert("Unknown error, please try again");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
export default ClientStatusToggle;
