import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ReadMorePopup = ({ children, show, setShow }) => {
  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
      }}
      PaperProps={{
        sx: {
          width: "max-content",
          height: "max-content",
          maxHeight: "80dvh",
          maxWidth: "80dvw",
          minWidth: "50dvw",
          overflow: "auto"
        }
      }}
      maxWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          setShow(false);
        }}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        style={{
          padding: "30px",
          whiteSpace: "pre-wrap"
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

ReadMorePopup.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export default ReadMorePopup;
