import React, { useState, createContext, useContext, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import PropTypes from "prop-types";
import { getCorrectShift } from "./utils";

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

const hourOptions = Array.from(Array(24).keys()).map((hour) => {
  return hour.toString().padStart(2, "0");
});
const minuteOptions = Array.from(Array(60).keys()).map((minute) => {
  return minute.toString().padStart(2, "0");
});
hourOptions.unshift("--");
minuteOptions.unshift("--");

const RECURRENCE_RATES = [
  { label: "Daily", value: "daily" },
  { label: "Every weekday (Mon to Fri)", value: "every_weekday" },
  { label: "Every other day", value: "every_other_day" },
  { label: "Weekly", value: "weekly" },
  { label: "Every other week", value: "every_other_week" },
  { label: "Monthly", value: "monthly" },
  { label: "Annually", value: "annually" },
  { label: "Custom", value: "custom" }
];

const CUSTOM_RECURRENCE_RATES = [
  { label: "Hour(s)", value: "hours" },
  { label: "Day(s)", value: "days" },
  { label: "Week(s)", value: "weeks" },
  { label: "Month(s)", value: "months" },
  { label: "Year(s)", value: "years" }
];

const WEEKDAY_MAPPING = [
  { value: 0, label: "S" },
  { value: 1, label: "M" },
  { value: 2, label: "T" },
  { value: 3, label: "W" },
  { value: 4, label: "T" },
  { value: 5, label: "F" },
  { value: 6, label: "S" }
];

const SHIFT_ALL_AUTO_OPTIONS = ["All Shifts", "Auto"];

const RecurrenceTaskContext = createContext();

const RecurrenceSelector = (props) => {
  const [mounted, setMounted] = useState(false);
  const [todoListType, setTodoListType] = useState("recurring");
  const [selectedRecurrenceRate, setSelectedRecurrenceRate] = useState("Daily");
  const [selectedCustomRecurrenceRate, setSelectedCustomRecurrenceRate] = useState(1);
  const [selectedCustomRecurrenceType, setSelectedCustomRecurrenceType] = useState("Hours");
  // today in canada time zone
  const today = dayjs().tz("America/Toronto").format("YYYY-MM-DD");
  const [toDoListStartDate, setToDoListStartDate] = useState(today);
  const [toDoListEndDate, setToDoListEndDate] = useState();
  const [toDoListTime, setToDoListTime] = useState();
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [selectedShiftNum, setSelectedShiftNum] = useState();
  const [isAutoShiftNum, setIsAutoShiftNum] = useState(false);

  const {
    controller_action: controllerAction,
    shifts,
    selected_shift_num: previousShiftNum,
    shift_num: shiftNum
  } = props;

  // Change shift number selection to AUTO on "Appointment" type task selection
  const handleTaskTypeChange = (event) => {
    setIsAutoShiftNum(
      event.detail.taskType === "Appointment" ||
        event.detail.taskType === "Appointment (All locations)"
    );
  };

  useEffect(() => {
    window.addEventListener("taskTypeChanged", handleTaskTypeChange);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("taskTypeChanged", handleTaskTypeChange);
    };
  }, []);

  useEffect(() => {
    const {
      list_type,
      recurrence_rate,
      custom_recur_frequency_interval,
      custom_recur_frequency_number,
      repeat_on,
      recurrence_start_date,
      recurrence_end_date,
      to_do_list_time,
      selected_shift_num
    } = props;

    if (list_type !== "RecurringToDoList") {
      setTodoListType("one time");
    }
    const isHourlyRecurringTask =
      recurrence_rate === "custom" && custom_recur_frequency_interval === "hours";
    if (isHourlyRecurringTask) {
      setIsAutoShiftNum(shiftNum !== "All Shifts");
    } else if (to_do_list_time || controllerAction !== "new") {
      setIsAutoShiftNum(true);
    }
    setSelectedRecurrenceRate(recurrence_rate);
    setSelectedCustomRecurrenceType(custom_recur_frequency_interval);
    setSelectedCustomRecurrenceRate(custom_recur_frequency_number);
    setToDoListStartDate(recurrence_start_date);
    setToDoListEndDate(recurrence_end_date);
    setToDoListTime(dayjs(to_do_list_time).format("HH:mm"));
    setSelectedWeekdays(repeat_on || []);
    setSelectedShiftNum(selected_shift_num);
    setMounted(true);
  }, []);

  const recurringTypeOnChange = (event) => {
    setTodoListType(event.target.value);
  };
  const shiftNumPreview = useMemo(() => {
    if (selectedShiftNum === "All Shifts") {
      return "";
    }
    return selectedShiftNum;
  }, [selectedShiftNum]);

  return (
    <RecurrenceTaskContext.Provider
      value={{
        previousShiftNum,
        controllerAction,
        selectedRecurrenceRate,
        setSelectedRecurrenceRate,
        selectedCustomRecurrenceRate,
        setSelectedCustomRecurrenceRate,
        selectedCustomRecurrenceType,
        setSelectedCustomRecurrenceType,
        selectedWeekdays,
        setSelectedWeekdays,
        toDoListStartDate,
        setToDoListStartDate,
        toDoListEndDate,
        setToDoListEndDate,
        toDoListTime,
        setToDoListTime,
        shifts,
        selectedShiftNum,
        setSelectedShiftNum,
        isAutoShiftNum,
        setIsAutoShiftNum,
        shiftNumPreview
      }}
    >
      <div className="recurrence-selector">
        {mounted && <ShiftSelector />}
        <div className="row">
          <label className="label">Task recurrence*</label>
          <div className="select is-rounded row">
            <select
              name="to_do_list[to_do_list_type_selection]"
              onChange={recurringTypeOnChange}
              value={todoListType}
              disabled={controllerAction !== "new"}
            >
              <option value="one time">One time</option>
              <option value="recurring">Recurring</option>
            </select>
          </div>
          {todoListType === "recurring" && <RecurringTaskConfigurationContainer />}
          {todoListType === "one time" && (
            <div className="one-time-date-selectors row">
              <label className="label">Date</label>
              <input
                type="date"
                name="to_do_list[to_do_date]"
                className="input"
                style={{
                  maxWidth: "max-content"
                }}
                defaultValue={props.to_do_date || today}
                value={toDoListStartDate}
                onChange={(e) => setToDoListStartDate(e.target.value)}
              />
            </div>
          )}
          {mounted && isAutoShiftNum && <TimeSelector />}
          <label
            id="to_do_list_time_user_selected_group-error"
            className="error"
            style={{ display: isAutoShiftNum ? "block" : "none" }}
          ></label>
        </div>
      </div>
    </RecurrenceTaskContext.Provider>
  );
};
RecurrenceSelector.propTypes = {
  list_type: PropTypes.string,
  recurrence_rate: PropTypes.string,
  custom_recur_frequency_interval: PropTypes.string,
  custom_recur_frequency_number: PropTypes.number,
  repeat_on: PropTypes.array,
  recurrence_start_date: PropTypes.string,
  recurrence_end_date: PropTypes.string,
  to_do_list_time: PropTypes.string,
  controller_action: PropTypes.string,
  shifts: PropTypes.array,
  shift_num: PropTypes.string,
  selected_shift_num: PropTypes.string,
  to_do_date: PropTypes.string
};

const RecurringTaskConfigurationContainer = () => {
  const {
    selectedRecurrenceRate,
    setSelectedRecurrenceRate,
    toDoListStartDate,
    setToDoListStartDate,
    toDoListEndDate,
    setToDoListEndDate
  } = useContext(RecurrenceTaskContext);

  const [isInfiniteEndDate, setIsInfiniteEndDate] = useState(false);

  const handleInfiniteEndDate = (checked) => {
    setIsInfiniteEndDate(checked);
    if (checked) {
      setToDoListEndDate(null);
    }
  };

  return (
    <>
      <div className="row">
        <label className="label">Recurrence Rate*</label>
        <div className="recurrence-rate-options">
          {RECURRENCE_RATES.map((recurrenceRate) => (
            <div className="recurrence-rate-selector" key={recurrenceRate.value}>
              <input
                type="radio"
                name="to_do_list[recurrence_rate]"
                value={recurrenceRate.value}
                id={recurrenceRate.label}
                checked={selectedRecurrenceRate === recurrenceRate.value}
                onChange={() => setSelectedRecurrenceRate(recurrenceRate.value)}
              />
              <label htmlFor={recurrenceRate.label}>{recurrenceRate.label}</label>
            </div>
          ))}
          {selectedRecurrenceRate === "custom" && <CustomRecurringRateSelector />}
        </div>
      </div>
      <div className="recurrence-time-range-selectors row">
        <div>
          <label className="label">Task start*</label>
          <input
            type="date"
            name="to_do_list[recurrence_start_date]"
            className="input"
            value={toDoListStartDate}
            onChange={(e) => setToDoListStartDate(e.target.value)}
          />
        </div>
        <div>
          <label className="label">Task end</label>
          {!isInfiniteEndDate && (
            <input
              type="date"
              name="to_do_list[recurrence_end_date]"
              className="input"
              value={toDoListEndDate}
              onChange={(e) => setToDoListEndDate(e.target.value)}
            />
          )}
          <div>
            <input type="checkbox" onChange={(e) => handleInfiniteEndDate(e.target.checked)} />{" "}
            Infinite
          </div>
        </div>
      </div>
    </>
  );
};

const CustomRecurringRateSelector = () => {
  const {
    selectedCustomRecurrenceRate,
    setSelectedCustomRecurrenceRate,
    selectedCustomRecurrenceType,
    setSelectedCustomRecurrenceType,
    selectedWeekdays,
    setSelectedWeekdays
  } = useContext(RecurrenceTaskContext);

  const weekdayOnSelect = (selectedWeekdayValue) => {
    if (selectedWeekdays.includes(selectedWeekdayValue)) {
      setSelectedWeekdays(selectedWeekdays.filter((weekday) => weekday !== selectedWeekdayValue));
    } else {
      setSelectedWeekdays([...selectedWeekdays, selectedWeekdayValue]);
    }
  };

  return (
    <div className="custom-recurring-rate-selectors">
      <label className="label">Repeat every</label>
      <div className="selectors-container">
        <input
          type="number"
          className="input recurrence-rate-input"
          name="to_do_list[custom_recur_frequency_number]"
          value={selectedCustomRecurrenceRate}
          onChange={(e) => setSelectedCustomRecurrenceRate(e.target.value)}
        />
        <div className="select is-rounded">
          <select
            name="to_do_list[custom_recur_frequency_interval]"
            id="to_do_list_recurring_task"
            value={selectedCustomRecurrenceType}
            onChange={(e) => setSelectedCustomRecurrenceType(e.target.value)}
          >
            {CUSTOM_RECURRENCE_RATES.map((customRecurrenceRate) => (
              <option key={customRecurrenceRate.value} value={customRecurrenceRate.value}>
                {customRecurrenceRate.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedCustomRecurrenceType === "weeks" && (
        <div className="row">
          <label className="label">Repeats on</label>
          <div className="weekday-selector">
            {WEEKDAY_MAPPING.map((weekdayMapping, index) => (
              <div
                key={`weekday-selector-${index}`}
                className={`weekday-selector-option ${
                  selectedWeekdays.includes(weekdayMapping.value) ? "selected" : ""
                }`}
                onClick={() => weekdayOnSelect(weekdayMapping.value)}
              >
                {weekdayMapping.label}
                <input
                  name={`to_do_list[repeat_on_${weekdayMapping.value}]`}
                  type="hidden"
                  value={selectedWeekdays.includes(weekdayMapping.value) ? "true" : "false"}
                  autoComplete="off"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ShiftSelector = () => {
  const {
    shifts,
    selectedShiftNum,
    setSelectedShiftNum,
    isAutoShiftNum,
    setIsAutoShiftNum,
    setToDoListTime,
    controllerAction,
    shiftNumPreview
  } = useContext(RecurrenceTaskContext);
  const selected_faked_shift_num = useMemo(
    () => (isAutoShiftNum ? SHIFT_ALL_AUTO_OPTIONS[1] : SHIFT_ALL_AUTO_OPTIONS[0]),
    [isAutoShiftNum]
  );
  useEffect(() => {
    if (!isAutoShiftNum) {
      setSelectedShiftNum("All Shifts");
      setToDoListTime(null);
    }
  }, [isAutoShiftNum]);

  return (
    <div className="row">
      <label htmlFor="shift_num" className="label">
        Shift number
      </label>
      <div className="auto_shift_selection_container">
        <div className="select is-rounded">
          <select
            id="to_do_list_shift_num_all_auto"
            value={selected_faked_shift_num}
            onChange={(e) => setIsAutoShiftNum(e.target.value === SHIFT_ALL_AUTO_OPTIONS[1])}
            disabled={controllerAction !== "new"}
          >
            {SHIFT_ALL_AUTO_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {shiftNumPreview && (
          <label className="preview_shift">{`SELECTED ${shiftNumPreview.toUpperCase()}`}</label>
        )}
      </div>

      <div className="select is-rounded hidden">
        <select
          name="to_do_list[shift_num]"
          id="to_do_list_shift_num"
          value={selectedShiftNum}
          className="hidden"
          onChange={(e) => setSelectedShiftNum(e.target.value)}
        >
          {shifts.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
const TimeSelector = () => {
  const { toDoListTime, setToDoListTime, shiftNumPreview, setSelectedShiftNum, previousShiftNum } =
    useContext(RecurrenceTaskContext);

  const [userSelectedHour, setUserSelectedHour] = useState();
  const [userSelectedMinute, setUserSelectedMinute] = useState();

  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    if (toDoListTime && !hasRunOnce && isComponentMounted) {
      const hour = dayjs(`2023-01-01 ${toDoListTime}`, "HH:mm").format("HH");
      const minute = dayjs(`2023-01-01 ${toDoListTime}`, "HH:mm").format("mm");
      const isValidTime = !(hour?.includes("Invalid") || minute?.includes("Invalid"));
      if (isValidTime) {
        setUserSelectedHour(hour);
        setUserSelectedMinute(minute);
      } else {
        setUserSelectedHour("--");
        setUserSelectedMinute("--");
      }
      setHasRunOnce((prev) => !prev);
    }
  }, [toDoListTime, isComponentMounted, hasRunOnce]);

  useEffect(() => {
    if (userSelectedHour && userSelectedMinute) {
      const isValid = !`${userSelectedHour}:${userSelectedMinute}`.includes("Invalid");
      const hasTime = userSelectedHour !== "--" && userSelectedMinute !== "--";
      if (!isValid || !hasTime) {
        setToDoListTime(null);
      } else {
        setToDoListTime(`${userSelectedHour}:${userSelectedMinute}`);
        setSelectedShiftNum(getCorrectShift(userSelectedHour, userSelectedMinute));
      }
    }
  }, [userSelectedHour, userSelectedMinute]);

  return (
    <>
      <div className="row task-time-selector">
        <label className="label">Task time</label>
        <div className="task-time-selector__input-container">
          <i className="far fa-clock fa-2x"></i>
          <select
            value={userSelectedHour}
            name="to_do_list_time_user_selected_hour"
            onChange={(e) => {
              setUserSelectedHour(e.target.value);
            }}
          >
            {hourOptions.map((hour) => {
              return (
                <option key={hour} value={hour}>
                  {hour}{" "}
                </option>
              );
            })}
          </select>
          <span>:</span>
          <select
            value={userSelectedMinute}
            name="to_do_list_time_user_selected_minute"
            onChange={(e) => {
              setUserSelectedMinute(e.target.value);
            }}
          >
            {minuteOptions.map((minute) => {
              return (
                <option key={minute} value={minute}>
                  {minute}{" "}
                </option>
              );
            })}
          </select>
        </div>

        {previousShiftNum && shiftNumPreview && previousShiftNum !== shiftNumPreview && (
          <div className="previous_selected_shift">
            {`You are moving the task from ${previousShiftNum.toUpperCase()} to ${shiftNumPreview.toUpperCase()}`}
          </div>
        )}
        <input
          className="input"
          name="to_do_list[to_do_list_time]"
          type="hidden"
          step="3600"
          value={toDoListTime}
          onChange={(e) => setToDoListTime(e.target.value)}
        />
      </div>
    </>
  );
};

export default RecurrenceSelector;
