import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NameField from "./name_fields";

const WorkShiftLogModal = ({ workShiftLog, onClose }) => {
  const [formState, setFormState] = useState({
    names: workShiftLog.names.length > 0 ? workShiftLog.names : [""], // Ensure at least one name field is displayed by default
    shift_date: workShiftLog.shift_date,
    shift: workShiftLog.shift,
    location: workShiftLog.location
  });

  useEffect(() => {
    // Ensure there's always at least one name field when the component mounts
    if (formState.names.length === 0) {
      setFormState((prevState) => ({
        ...prevState,
        names: [""]
      }));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNameChange = (index, value) => {
    const newNames = [...formState.names];
    newNames[index] = value;
    setFormState((prevState) => ({
      ...prevState,
      names: newNames
    }));
  };

  const addNameField = () => {
    setFormState((prevState) => ({
      ...prevState,
      names: [...prevState.names, ""]
    }));
  };

  const removeNameField = (index) => {
    const newNames = [...formState.names];
    if (newNames.length > 1) {
      // Prevent removing the last name field
      newNames.splice(index, 1);
      setFormState((prevState) => ({
        ...prevState,
        names: newNames
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("work_shift_log[names]", JSON.stringify(formState.names));
    formData.append("work_shift_log[shift_date]", formState.shift_date);
    formData.append("work_shift_log[shift]", formState.shift);

    fetch(`/work_shift_logs/${workShiftLog.id}`, {
      method: "PUT",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
    }).then((response) => {
      if (response.ok) {
        window.location.reload();
      } else {
        response.json().then((data) => {
          alert(`There was an error updating the staff roster: ${data.error}`);
        });
      }
    });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="work-shift-log-modal modal is-active">
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-header">
              <p className="modal-card-title">Staff Rosters</p>
              <p className="modal-card-description">
                Add your name and the names of all colleagues who are working with you on this shift
              </p>
            </div>

            <button className="delete" aria-label="close" onClick={onClose}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Names</label>

              <div className="names-container">
                {formState.names.map((name, index) => (
                  <NameField
                    key={index}
                    name={name}
                    index={index}
                    onNameChange={handleNameChange}
                    onKeyDown={handleKeyDown}
                    onRemove={removeNameField}
                  />
                ))}

                <button
                  type="button"
                  id="add-more-names-button"
                  className="button is-secondary"
                  onClick={addNameField}
                >
                  Add more names
                </button>
              </div>
            </div>
            <div className="field">
              <label className="label">Location</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="location"
                  disabled
                  style={{ color: "#7a7a7a" }}
                  value={formState.location}
                  onChange={handleInputChange}
                  placeholder="Select Location"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Shift Date</label>
              <div className="control">
                <input
                  className="input"
                  type="date"
                  disabled
                  style={{ color: "#7a7a7a" }}
                  name="shift_date"
                  value={formState.shift_date}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Shift</label>
              <div className="control">
                <div className="select">
                  <select
                    name="shift"
                    disabled
                    value={formState.shift}
                    onChange={handleInputChange}
                  >
                    <option value="1st">1st</option>
                    <option value="2nd">2nd</option>
                    <option value="3rd">3rd</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button type="submit" className="button is-primary">
              {workShiftLog.status === "submitted" ? "Update" : "Clock In"}
            </button>
            <button type="button" className="button" onClick={onClose}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </form>
  );
};

WorkShiftLogModal.propTypes = {
  workShiftLog: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
export default WorkShiftLogModal;
