import React, { useState } from "react";
import PropTypes from "prop-types";
import UnifiedSearchAutocompleteComponent from "../../unified_search_autocomplete_component";

const UserSearchForm = ({ selectedSearchUser }) => {
  const [searchUser, setSearchUser] = useState(selectedSearchUser);

  const handleFormSubmit = (searchUser) => {
    window.location.href = `/admin/users?search_user=${searchUser}`;
  };

  const handleDropdownItemClick = (option) => {
    const updatedSearchUser = option.name;
    setSearchUser(updatedSearchUser);
    handleFormSubmit(updatedSearchUser);
  };

  const handleClearClick = () => {
    const updatedSearchUser = "";
    setSearchUser(updatedSearchUser);
    handleFormSubmit(updatedSearchUser);
  };

  const handleInputChange = (e) => {
    setSearchUser(e.target.value);
  };

  return (
    <div className="user-search-bar">
      <div className="control">
        <UnifiedSearchAutocompleteComponent
          input_field_name="search_user"
          selected_name={searchUser}
          options={[]}
          handleInputChange={handleInputChange}
          hardCodedOptions={[]}
          handleFormSubmit={() => handleFormSubmit(searchUser)}
          handleDropdownItemClick={handleDropdownItemClick}
          handleClearClick={handleClearClick}
          inputFieldId="auto-complete-user-input-box"
          inputFieldPlaceholder="Search for User:"
          handleInputFocus={() => {}}
        />
      </div>
    </div>
  );
};

UserSearchForm.propTypes = {
  selectedSearchUser: PropTypes.string
};

UserSearchForm.defaultProps = {
  selectedSearchUser: ""
};

export default UserSearchForm;
