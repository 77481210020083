import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { CompositeDecorator, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { convertFromHTML, convertToHTML } from "draft-convert";
import PropTypes from "prop-types";
import CustomEditorUtils from "../../CustomEditorUtils";
import { NurseLogToggleContext } from "../client_logs/nurse_recap_toggle_provider";

const END_TAG_REGEX = /\[END\]/g;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text))) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

// eslint-disable-next-line no-unused-vars
const endStrategy = (contentBlock, callback, contentState) => {
  findWithRegex(END_TAG_REGEX, contentBlock, callback);
};
const EndSpan = ({ children }) => {
  return <span className="endTag">{children}</span>;
};
EndSpan.propTypes = {
  children: PropTypes.node.isRequired
};

const endDecorator = {
  strategy: endStrategy,
  component: EndSpan
};

export default function MentionEditor({
  defaultHTML,
  mentions = [],
  setHtmlOutput,
  isClientMentioned,
  setIsClientMentioned
}) {
  const { isNurseRecapHidden, isGeneralLogSeparate } = useContext(NurseLogToggleContext);
  const ref = useRef(null);
  const editorContainerRef = useRef(null);

  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator([endDecorator]);

    if (defaultHTML) {
      const contentState = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a" && node.className.includes("mention")) {
            const href = node.getAttribute("href");
            const pathParts = href.split("/");
            const clientId = pathParts[pathParts.length - 1]; // this will be the ID whether href is '/clients/52' or '52'
            return createEntity("mention", "IMMUTABLE", {
              mention: {
                name: node.innerText,
                link: clientId, // this will now contain only the id
                class: node.className
              }
            });
          } else if (nodeName === "span" && node.className.includes("endTag")) {
            // Making [END] tag a mention and not part of regular text
            return createEntity("mention", "IMMUTABLE", {
              mention: {
                name: node.innerText,
                link: "",
                class: node.className
              }
            });
          }
          return null;
        }
      })(defaultHTML);
      return EditorState.createWithContent(contentState, decorator);
    } else {
      return EditorState.createEmpty(decorator);
    }
  });

  // lastMentionIsEnd is simply used to place a placeholder [END] at the bottom to remind users to [END] clients
  const [lastMentionIsEnd, setLastMentionIsEnd] = useState(true);
  // Updating logic to check all clients mentions has [END] tags
  const onChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    let countNotEndedMentions = 0;
    // Check if a client or the [END] tag was just mentioned
    blockMap.forEach((block) => {
      block.findEntityRanges(
        (character) => {
          // If no @mentions, then return
          const entityKey = character.getEntity();
          if (entityKey === null) {
            return false;
          }
          // If Entity @mention is found
          const entity = currentContent.getEntity(entityKey);
          if (entity.getType() === "mention" && !isGeneralLogSeparate) {
            if (entity.getData().mention.name !== "[END]") {
              // lastMentionIsEnd is used to place [END] placeholder
              setLastMentionIsEnd(false);
              countNotEndedMentions++;
            } else {
              setLastMentionIsEnd(true);
              countNotEndedMentions--;
            }
          }
          return false;
        },
        () => {}
      );
    });
    // If the total [Mentions] count doesn't match total [END] counts
    if (countNotEndedMentions != 0) {
      // isClientMentioned is used to disable / enable save
      setIsClientMentioned(true);
    } else {
      setIsClientMentioned(false);
      setLastMentionIsEnd(true);
    }
    // Set the Editor's state
    setEditorState(newEditorState);
  };

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions || []);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: {
        mention: "mention",
        mentionSuggestions: "mentionSuggestions",
        mentionSuggestionsEntry: "mentionSuggestionsEntry",
        mentionSuggestionsEntryFocused: "mentionSuggestionsEntryFocused",
        mentionSuggestionsEntryText: "mentionSuggestionsEntryText",
        mentionSuggestionsEntryAvatar: "mentionSuggestionsEntryAvatar"
      },
      mentionComponent(mentionProps) {
        if (mentionProps.mention.name === "[END]") {
          return <span className="endTag">{mentionProps.children}</span>;
        }
        const linkId = mentionProps.mention.link;
        const { editorPath } = CustomEditorUtils.createClientMention(linkId);

        return (
          <a href={editorPath} className={mentionProps.className} target="_blank" rel="noreferrer">
            {mentionProps.children}
          </a>
        );
      }
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((open) => {
    setOpen(open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }) => {
      const END_TAG = "[END]";
      let mentionsWithEndTag = [];
      // If isClientMentioned is true, only add the [END] tag to the mentions list
      if (isClientMentioned && !isGeneralLogSeparate) {
        mentionsWithEndTag = [
          {
            name: END_TAG,
            link: END_TAG
          }
        ];
      } else {
        // If isClientMentioned is false, add all other mentions except the [END] tag
        mentionsWithEndTag = mentions.filter((mention) => mention.name !== END_TAG);
      }
      // Get the current text in the editor
      const currentContent = editorState.getCurrentContent();
      const currentText = currentContent.getPlainText();

      const filteredClients = mentionsWithEndTag.filter(
        (mention) =>
          mention.name.toLowerCase().includes(value.toLowerCase()) &&
          (mention.name === END_TAG || !currentText.includes(mention.name)) // Exclude if already mentioned, unless it's the [END] tag
      );
      const sortedClients = filteredClients.sort((a, b) => a.name.localeCompare(b.name));
      setSuggestions(sortedClients);
    },
    [editorState, isClientMentioned]
  );

  useEffect(() => {
    // Setting the editor content
    const contentState = editorState.getCurrentContent();
    const htmlContent = convertToHTML({
      entityToHTML: (entity, originalText) => {
        const isEndTag = entity.data.mention.name === "[END]";
        if (isEndTag) {
          return `<span class="endTag">${originalText}</span>`;
        }
        if (entity.type === "mention") {
          const linkId = entity.data.mention.link;
          const { editorPath } = CustomEditorUtils.createClientMention(linkId);
          return `<a href="${editorPath}" class="mention" target="_blank">${originalText}</a>`;
        }
        return originalText;
      }
    })(contentState);
    setHtmlOutput(htmlContent);
  }, [editorState]);
  const content = editorState.getCurrentContent();
  useEffect(() => {
    // dynamically set the height of the editor
    if (editorContainerRef && editorContainerRef.current) {
      editorContainerRef.current.style.height = "inherit";
      editorContainerRef.current.style.height = `${editorContainerRef.current?.scrollHeight}px`;
    }
  }, [content]);

  return (
    <>
      <div
        className={`editor ${!lastMentionIsEnd ? "client-mentioned" : ""}`}
        ref={editorContainerRef}
        onClick={() => {
          ref.current.focus();
        }}
      >
        <Editor
          editorKey={"editor"}
          editorState={editorState}
          onChange={onChange}
          decorators={[endDecorator]}
          plugins={plugins}
          ref={ref}
        />

        {/* Disabling Nurse Recap Features in Current Deployment */}
        {!isNurseRecapHidden && (
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
          />
        )}
      </div>
    </>
  );
}

MentionEditor.propTypes = {
  defaultHTML: PropTypes.string,
  mentions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ),
  setHtmlOutput: PropTypes.func.isRequired,
  isClientMentioned: PropTypes.bool,
  setIsClientMentioned: PropTypes.func
};
