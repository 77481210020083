import React, { useState } from "react";
import PropTypes from "prop-types";
import InventoryQuantity from "./inventory_quantity";

const AddRemoveStockModal = ({ inventory, closeModal }) => {
  const [formState, setFormState] = useState({
    addedQuantity: 0,
    removedQuantity: 0,
    updatedQuantity: inventory.quantity,
    reason: "",
    initials: ""
  });

  const [isError, setIsError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => {
      const isQuantityChange = name === "addedQuantity" || name === "removedQuantity";
      const newValue = isQuantityChange ? parseInt(value, 10) : value;

      const newQuantities = {
        addedQuantity: name === "addedQuantity" ? newValue : prevState.addedQuantity,
        removedQuantity: name === "removedQuantity" ? newValue : prevState.removedQuantity
      };

      const newUpdatedQuantity =
        (inventory.quantity || 0) +
        (newQuantities.addedQuantity || 0) -
        (newQuantities.removedQuantity || 0);

      // Check if the removed quantity is more than the new updated quantity
      if (
        (newQuantities.removedQuantity || 0) >
        (inventory.quantity || 0) + (newQuantities.addedQuantity || 0)
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }

      return {
        ...prevState,
        [name]: newValue,
        updatedQuantity: newUpdatedQuantity
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isError) return;

    const formData = new FormData();
    formData.append("inventory[updated_quantity]", formState.updatedQuantity);
    formData.append("inventory[added_quantity]", formState.addedQuantity);
    formData.append("inventory[removed_quantity]", formState.removedQuantity);
    formData.append("inventory[reason]", formState.reason);
    formData.append("inventory[initial]", formState.initials);

    fetch(`/inventories/${inventory.id}/update_inventory_stock`, {
      method: "PATCH",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
    }).then((response) => {
      if (response.ok) {
        window.location.reload();
      } else {
        response.json().then((data) => {
          alert(data.error);
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card" style={{ maxWidth: "480px" }}>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <h1> {inventory.name}</h1>
              <InventoryQuantity
                style={{
                  display: "inline-block"
                }}
                quantity={inventory.quantity}
              />
            </p>
            <button className="delete" aria-label="close" onClick={closeModal}></button>
          </header>
          <section className="modal-card-body">
            <div className="modal-subtitle">
              <h2 className="title is-5" style={{ color: "black" }}>
                {inventory.category}
              </h2>
              <p>{inventory.description}</p>
            </div>
            <div className="horizontal-field">
              <label className="label">Add stock</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  name="addedQuantity"
                  value={formState.addedQuantity}
                  onChange={handleInputChange}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            </div>
            <div className="horizontal-field">
              <label className="label">Remove stock</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  name="removedQuantity"
                  value={formState.removedQuantity}
                  onChange={handleInputChange}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            </div>

            {/* Show error message if the remove quantity is more than the new quantity */}
            {isError && (
              <div className="field error">
                Error: Remove stock quantity cannot be more than the current quantity.
              </div>
            )}

            <div className="horizontal-field">
              <label className="label">New Quantity</label>
              <InventoryQuantity quantity={formState.updatedQuantity} />
            </div>
            <div className="field">
              <label className="label">Please enter your initials</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="initials"
                  value={formState.initials}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Reason</label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="reason"
                  value={formState.reason}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button type="submit" className="button is-primary" disabled={isError}>
              Update
            </button>
            <button type="button" className="button" onClick={closeModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </form>
  );
};

AddRemoveStockModal.propTypes = {
  inventory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired
  }),
  closeModal: PropTypes.func.isRequired
};
export default AddRemoveStockModal;
