import React, { useContext } from "react";
import ColSpanPadding from "./ColspanPadding";
import { DynamicTableContext } from "../dynamic_table";
import ColumnHeading from "./ColumnHeading";
import PropTypes from "prop-types";

function DynamicTableComponent({ showId = true }) {
  const { data, filteredData, columns } = useContext(DynamicTableContext);

  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              <ColumnHeading heading={column} index={index} />
            </th>
          ))}
          <ColSpanPadding data={data} columns={columns} />
        </tr>
      </thead>
      <tbody>
        {filteredData.map((row, index) => (
          <tr key={index}>
            {Object.keys(row).map((rowKey, r_index) => {
              // Check if showId is false and the rowKey is 'id', then do not render it
              if (!showId && rowKey === "id") {
                return null;
              }
              return (
                <td key={`${rowKey} ${r_index}`}>
                  {_isHtmlTag(row[rowKey]) ? (
                    <div dangerouslySetInnerHTML={{ __html: row[rowKey] }} />
                  ) : (
                    row[rowKey]
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

DynamicTableComponent.propTypes = {
  showId: PropTypes.bool
};
function _isHtmlTag(str) {
  const regex = /^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$/;
  return regex.test(str);
}

export default DynamicTableComponent;
