import { useState, useEffect } from "react";

export const useShiftLogTimeObserver = (hourRef, minuteRef, amPmRef) => {
  const [hourValue, setHourValue] = useState("");
  const [minuteValue, setMinuteValue] = useState("");
  const [amPmValue, setAmPmValue] = useState("");

  useEffect(() => {
    if (!hourRef.current || !minuteRef.current || !amPmRef.current) return;

    const observeRef = (ref, setValue) => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "attributes" && mutation.attributeName === "value") {
            const newValue = ref.current.value;
            setValue(newValue);
          }
        });
      });

      observer.observe(ref.current, {
        attributes: true,
        attributeFilter: ["value"]
      });

      return observer;
    };

    const hourObserver = observeRef(hourRef, setHourValue);
    const minuteObserver = observeRef(minuteRef, setMinuteValue);
    const amPmObserver = observeRef(amPmRef, setAmPmValue);

    return () => {
      hourObserver.disconnect();
      minuteObserver.disconnect();
      amPmObserver.disconnect();
    };
  }, [hourRef, minuteRef, amPmRef]);

  return { hourValue, minuteValue, amPmValue };
};
