/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import ReactSelect, { components } from "react-select";
import PropTypes from "prop-types";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const toOptionArray = (value) =>
  value.map((location) => {
    if (location.location) {
      return {
        label: location.location,
        value: location.location
      };
    } else {
      return {
        label: location,
        value: location
      };
    }
  });

const MultipleLocationsSelector = (props) => {
  const { name, locations, selectedLocation, required, array_name } = props;

  const [value, setValue] = useState(selectedLocation ? toOptionArray(selectedLocation) : []);
  const handleChange = (selected) => {
    setValue(selected);
  };

  const getValues = () => {
    return { locations: value.map((val) => val.value) };
  };

  const options = useMemo(() => toOptionArray(locations), [locations]);
  const array_value = useMemo(() => getValues(), [value]);

  return (
    <>
      <input type="hidden" name={array_name} value={JSON.stringify(array_value)} />
      <ReactSelect
        options={options}
        isMulti
        id="user_locations"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option
        }}
        required={required}
        name={name}
        onChange={handleChange}
        allowSelectAll={true}
        value={value}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: "1px solid #26547c",
            minHeight: "3.5rem",
            fontSize: "1.2rem",
            padding: "0 1.5rem",
            paddingLeft: "1rem",
            boxShadow: "inset 0 1px 2px rgb(0 0 0 / 10%)"
          })
        }}
      />
    </>
  );
};
Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};
MultipleLocationsSelector.propTypes = {
  name: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  selectedLocation: PropTypes.array.isRequired
};

export default MultipleLocationsSelector;
