import React, { useEffect, useRef, useState } from "react";
import {
  CHECKBOX_FIELDS,
  HOSPICE_SECTIONS_CHECKBOX_FIELDS,
  useDenseViewContext
} from "./DenseViewContext";

const CustomSelect = () => {
  const { state, dispatch, location } = useDenseViewContext();
  const [selectAll, setSelectAll] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    const fieldsToRender =
      location === "Diane Morrison Hospice" ? HOSPICE_SECTIONS_CHECKBOX_FIELDS : CHECKBOX_FIELDS;

    const allSelected = fieldsToRender.every((field) => state[field.value]);
    setSelectAll(allSelected);
  }, [state]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCheckboxChange = (field, value) => {
    dispatch({ type: field, payload: value });
  };

  const renderCheckboxes = () => {
    const fieldsToRender =
      location === "Diane Morrison Hospice" ? HOSPICE_SECTIONS_CHECKBOX_FIELDS : CHECKBOX_FIELDS;

    return fieldsToRender.map((field) => (
      <div key={field.value}>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state[field.value]}
            onChange={() => handleCheckboxChange(field.value, !state[field.value])}
          />
          {field.label}
        </label>
      </div>
    ));
  };

  return (
    <div className="custom-select">
      <div className="button custom-select-btn" onClick={() => setShowDropdown(!showDropdown)}>
        Select All... <i className="fas fa-caret-down" />
      </div>
      <div ref={dropdownContainerRef} className="dropdown-container">
        {showDropdown && (
          <div className="custom-select-dropdown">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e) => {
                    setSelectAll(e.target.checked);
                    dispatch({ type: e.target.checked ? "selectAll" : "deselectAll" });
                  }}
                />
                Select All
              </label>
            </div>

            {renderCheckboxes()}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
