import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const BackToTopButton = ({ elSelector, isScrollEl = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollTop = () => {
    const el = document.querySelector(elSelector);
    if (el) {
      if (isScrollEl) {
        el.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    }
  };

  useEffect(() => {
    const el = document.querySelector(elSelector);

    const wheelListener = () => {
      setIsVisible(isTop100pxGoneOver(el, isScrollEl));
    };

    if (el) {
      el.addEventListener("wheel", wheelListener, true);
    }

    return () => {
      if (el) {
        el.removeEventListener("wheel", wheelListener);
      }
    };
  }, [elSelector]);

  if (!isVisible) return null;
  return (
    <button
      onClick={scrollTop}
      className="button is-primary back-to-top-button"
      style={!isScrollEl ? { position: "fixed" } : {}}
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};

BackToTopButton.propTypes = {
  elSelector: PropTypes.string.isRequired,
  isScrollEl: PropTypes.bool
};

export default BackToTopButton;

function isTop100pxGoneOver(el, isScrollEl) {
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  const rect = el.getBoundingClientRect();

  if (isScrollEl) {
    const visibleHeight = el.scrollTop;
    return visibleHeight > 100;
  } else {
    const visibleHeight =
      Math.min(rect.top + 100, window.innerHeight || document.documentElement.clientHeight) -
      Math.max(rect.top, 0);
    return visibleHeight < 100;
  }
}
