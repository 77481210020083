import React, { useState } from "react";
import PropTypes from "prop-types";

function InventorySearch({ selected_inventory_name = "" }) {
  const [selectedInventoryName, setSelectedInventoryName] = useState(selected_inventory_name);

  function handleClick() {
    const params = new URLSearchParams(window.location.search);
    params.set("inventory_name", selectedInventoryName);
    window.location.href = `/inventories?${params.toString()}`;
  }

  function handleClear() {
    setSelectedInventoryName("");
    updateUrlParams({ inventory_name: "" });
  }

  return (
    <div className="inventory-search">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <input
          autoComplete="off"
          id="selected_inventory_name"
          className="input"
          type="text"
          placeholder="Search by inventory name"
          value={selectedInventoryName}
          onChange={(e) => setSelectedInventoryName(e.target.value)}
        />
        <button type="submit" className="search-btn" onClick={handleClick}>
          <i className="fas fa-search"></i>
        </button>
      </form>

      {selectedInventoryName && (
        <label className="clear-label" onClick={handleClear}>
          <i className="fas fa-times"></i> Clear
        </label>
      )}
    </div>
  );
}

InventorySearch.propTypes = {
  selected_inventory_name: PropTypes.string
};

function updateUrlParams(params) {
  const currentUrl = new URL(window.location);
  Object.entries(params).forEach(([key, value]) => {
    currentUrl.searchParams.set(key, value);
  });
  window.location.href = currentUrl.href;
}

export default InventorySearch;
