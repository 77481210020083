/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";

function RemoveClientBtn({ clientId, removeClient }) {
  const btnOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    softDeleteClient(clientId, removeClient);
  };
  return (
    <a
      explorator_test_id={`client-remove-${clientId}`}
      className={`client-remove`}
      onClick={btnOnClick}
    >
      Ignore
    </a>
  );
}

RemoveClientBtn.propTypes = {
  clientId: PropTypes.number.isRequired,
  removeClient: PropTypes.func.isRequired
};

export default RemoveClientBtn;

function softDeleteClient(clientId, removeClientCallback) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
  const url = `/clients/${clientId}/soft_delete.json`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
  };

  fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      if (data.is_soft_deleted) {
        removeClientCallback();
      } else {
        alert("Failed to soft delete the client.");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
