import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReadMorePopup from "./read_more_popup";

const ReadMore = ({ text }) => {
  const [show, setShow] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const paragraphRef = useRef(null);
  useEffect(() => {
    const element = paragraphRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  }, []);
  return (
    <>
      <div>
        <p
          ref={paragraphRef}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: "5",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            maxWidth: "100%"
          }}
        >
          {text}
        </p>
        {isOverflowing && (
          <a id="readMore" onClick={() => setShow(!show)}>
            {" Read more"}
          </a>
        )}
      </div>
      <ReadMorePopup show={show} setShow={setShow}>
        {text}
      </ReadMorePopup>
    </>
  );
};

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  maxCharacterCount: PropTypes.number
};

export default ReadMore;
