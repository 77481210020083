import React, { useState } from "react";
import PropTypes from "prop-types";

function ShiftLogSeenCheckbox({ isSeen, onChange }) {
  const [seen, setSeen] = useState(isSeen);
  const handleCheckboxChange = (newSeen) => {
    setSeen(newSeen);
    if (onChange) {
      onChange(newSeen);
    }
  };
  return (
    <>
      <label className={`checkbox_container ${!seen ? "is-not-seen" : ""}`}>
        <input name="shift_log[not_seen]" type="hidden" value="1" autoComplete="off" />
        <input
          value="0"
          className="checkbox"
          type="checkbox"
          checked={!seen}
          onChange={() => handleCheckboxChange(false)}
          name="shift_log[not_seen]"
          id="shift_log_not_seen"
        />
        <b>Not seen</b>
      </label>
      <label className={`checkbox_container ${seen ? "is-seen" : ""}`}>
        <input name="shift_log[seen]" type="hidden" value="0" autoComplete="off" />
        <input
          value="1"
          className="checkbox"
          type="checkbox"
          checked={seen}
          onChange={() => handleCheckboxChange(true)}
          name="shift_log[seen]"
          id="shift_log_seen"
        />
        <b>Seen</b>
      </label>
    </>
  );
}

ShiftLogSeenCheckbox.propTypes = {
  isSeen: PropTypes.bool,
  onChange: PropTypes.func
};

export default ShiftLogSeenCheckbox;
