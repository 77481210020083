/* eslint-disable react/no-unknown-property */
import React, { useMemo, useState } from "react";
import {
  todoListInProgress,
  todoListMarkCompleteTask,
  todoListMarkInCompleteTask,
  todoListSkipTask
} from "./todo_list_util";
import { Dialog, DialogContent } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import PropTypes from "prop-types";
import SeenCheckbox from "../seen_checkbox";

const selectOptions = ["pass to next shift", "skip occurrence"];

const checkIfTwoDatesAreSameDayOrEarlier = (date1, date2) => {
  return (
    date1.getFullYear() <= date2.getFullYear() &&
    date1.getMonth() <= date2.getMonth() &&
    date1.getDate() <= date2.getDate()
  );
};

export const TODOListActionButton = (props) => {
  const {
    toDoListTitle,
    isInComplete,
    shiftNumber,
    todoListId,
    todoListDate,
    time: unsafeTime,
    type,
    isComplete
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [reason, setReason] = useState(undefined);
  const [userInitial, setUserInitial] = useState("");
  const [seen, setSeen] = useState("0");
  const [skipOccurrenceType, setSkipOccurrenceType] = useState("pass to next shift");

  const time = useMemo(() => {
    if (unsafeTime) return unsafeTime;
    if (shiftNumber === "Shift: 1st") return `${todoListDate} 07:00:00`;
    if (shiftNumber === "Shift: 2nd") return `${todoListDate} 15:00:00`;
    if (shiftNumber === "Shift: 3rd") return `${todoListDate} 23:00:00`;
  }, [unsafeTime]);

  const todoListIsInPresentOrPast = useMemo(() => {
    if (!time) return false;
    const todoListTime = new Date(time);
    const toDoListJSDateAndTime = new Date(
      todoListDate + " " + todoListTime.getHours() + ":" + todoListTime.getMinutes() + ":00"
    );
    return checkIfTwoDatesAreSameDayOrEarlier(toDoListJSDateAndTime, new Date());
  }, [time]);

  const markAsComplete = (event) => {
    if (event.preventDefault) event.preventDefault();
    switch (type) {
      case "complete":
        todoListMarkCompleteTask(todoListId, todoListDate, time, userInitial, reason);
        break;
      case "incomplete":
        todoListMarkInCompleteTask(todoListId, todoListDate, time, userInitial, reason);
        break;
      case "skip":
        todoListSkipTask(
          todoListId,
          todoListDate,
          time,
          seen,
          skipOccurrenceType,
          userInitial,
          reason
        );
        break;
    }
  };

  const buttonContentStyleAndIcon = useMemo(() => {
    switch (type) {
      case "complete":
        return {
          label: "Mark as complete",
          style: "button to-do-list-action-button-base to-do-list-complete-button",
          icon: "far fa-check-circle"
        };
      case "incomplete":
        return {
          label: "Mark as incomplete",
          style: "button to-do-list-action-button-base to-do-list-incomplete-button",
          icon: "fas fa-not-equal"
        };
      case "skip":
        return {
          label: "Skip occurrence",
          style: "button to-do-list-action-button-base to-do-list-skip-occurrence-button",
          icon: "far fa-clock"
        };
      case "inprogress":
        return {
          label: "Mark as in progress",
          icon: "far fa-clock",
          style: "button to-do-list-action-button-base to-do-list-in-progress-button"
        };
    }
  }, [type]);

  const onActionButtonClicked = () => {
    if (type === "inprogress") {
      todoListInProgress(todoListId);
    } else {
      setModalOpen(true);
    }
  };

  const onSetSeen = () => {
    if (seen === "1") setSeen("0");
    if (seen === "0") setSeen("1");
  };

  if (type !== "inprogress" && (isComplete || isInComplete)) return <></>;
  if (type === "inprogress" && !isComplete && !isInComplete) return <></>;
  if ((type === "incomplete" || type === "skip") && !todoListIsInPresentOrPast) return <></>;
  return (
    <>
      <TODOListActionButtonConfirmationModal
        skipOccurrenceType={skipOccurrenceType}
        setSkipOccurrenceType={setSkipOccurrenceType}
        seen={seen === "1"}
        setSeen={onSetSeen}
        type={type}
        handleSubmit={markAsComplete}
        reason={reason}
        setReason={setReason}
        userInitial={userInitial}
        setUserInitial={setUserInitial}
        modalOpen={modalOpen}
        handleClose={() => setModalOpen(false)}
        todoListIsInPresentOrPast={todoListIsInPresentOrPast}
      />
      <button
        explorator_to_do_list_test_id_title={`to_do_list_action_button_${type}_${toDoListTitle}`}
        explorator_test_id={`to_do_list_action_button_${type}`}
        className={buttonContentStyleAndIcon.style}
        onClick={onActionButtonClicked}
      >
        <i className={buttonContentStyleAndIcon.icon}></i>
        <span>{buttonContentStyleAndIcon.label}</span>
      </button>
    </>
  );
};

TODOListActionButton.propTypes = {
  todoListId: PropTypes.string,
  todoListDate: PropTypes.string,
  time: PropTypes.string,
  type: PropTypes.string, // can be: complete, incomplete, skip, inprogress
  isComplete: PropTypes.bool,
  isInComplete: PropTypes.bool,
  shiftNumber: PropTypes.number,
  toDoListTitle: PropTypes.string
};

const TODOListActionButtonConfirmationModal = ({
  skipOccurrenceType,
  setSkipOccurrenceType,
  seen,
  setSeen,
  type,
  handleClose,
  handleSubmit,
  modalOpen,
  requireReason,
  userInitial,
  setUserInitial,
  setReason,
  reason,
  todoListIsInPresentOrPast
}) => {
  if (!modalOpen) return <></>;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className={`to-do-completed-popup ${requireReason ? "warning" : ""}`}>
        <form className="user-initial-popup-container">
          <p>Please enter your initial</p>
          <input
            type="text"
            className="input"
            name="userInitialInput"
            value={userInitial}
            onChange={(e) => setUserInitial(e.target.value)}
            explorator_test_id={"to_do_list_action_button_model_initials_input"}
          />
          {(type === "incomplete" ||
            type === "skip" ||
            (type === "complete" && !todoListIsInPresentOrPast)) && (
            <>
              {type === "complete" && !todoListIsInPresentOrPast && (
                <div className="warning-message-container">
                  <WarningIcon className="warning-icon" />
                  <p>
                    {
                      "Caution: You're about to mark a task as complete that is scheduled for the future."
                    }
                  </p>
                </div>
              )}
              <p>Reason</p>
              <textarea
                className="textarea"
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                explorator_test_id={"to_do_list_action_button_model_reason_input"}
              ></textarea>
            </>
          )}
          {type === "skip" && (
            <>
              <SeenCheckbox seen={seen} setSeen={setSeen} />
              <div
                style={{ width: "fit-content", margin: "0.5rem 0" }}
                className="select is-rounded"
              >
                <select
                  className="pass-to-next-shift-options-select"
                  value={skipOccurrenceType}
                  onChange={(e) => setSkipOccurrenceType(e.target.value)}
                  explorator_test_id={"to_do_list_action_button_model_select_skip_type"}
                >
                  {selectOptions.map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          )}
          <button
            className="button is-success mark-as-completed-button"
            onClick={handleSubmit}
            disabled={!userInitial}
            explorator_test_id={"to_do_list_action_button_model_submit_button"}
          >
            Confirm
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

TODOListActionButtonConfirmationModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  modalOpen: PropTypes.bool,
  requireReason: PropTypes.bool,
  userInitial: PropTypes.string,
  setUserInitial: PropTypes.func,
  setReason: PropTypes.func,
  reason: PropTypes.string,
  type: PropTypes.string,
  seen: PropTypes.bool,
  setSeen: PropTypes.func,
  skipOccurrenceType: PropTypes.string,
  setSkipOccurrenceType: PropTypes.func,
  todoListIsInPresentOrPast: PropTypes.bool
};
