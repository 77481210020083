import dayjs from "dayjs";
import { getHourFormat24 } from "../../timeUtils";

export function getEditShiftLogPath(shiftLog) {
  const location = shiftLog.location;
  const date = shiftLog.log_date;
  const shiftNum = shiftLog.shift_num;
  const id = shiftLog.id;

  const url = new URL(`/shift_logs/${id}/edit`, window.location.origin);
  url.searchParams.append("date", date);
  url.searchParams.append("location", location);
  url.searchParams.append("shift_num", shiftNum);

  return url.toString();
}
export function getClientPath(shiftLog) {
  return new URL(`/clients/${shiftLog.client_id}/`, window.location.origin);
}

export function getFormattedDateTime(shiftLog) {
  const minute = shiftLog.minute;
  const am_pm = shiftLog.am_pm;
  const hour = getHourFormat24(shiftLog.hour, am_pm);
  const log_date = shiftLog.log_date;

  return dayjs(`${hour || ""}:${minute || ""} ${log_date}`).format("HH:mm YYYY-MM-DD");
}

export function getMaintenanceClass(maintenance) {
  return maintenance ? "fa fa-check log-rows__icon__green" : "fa fa-times log-rows__icon__red";
}

export const goToNewShitLogPage = (newShiftLogPath, rowShiftNum, date, clientId) => {
  let defaultNewPathParams = new URLSearchParams(newShiftLogPath);
  window.location.href = `/shift_logs/new?shift_num=${rowShiftNum}&location=${defaultNewPathParams.get(
    "location"
  )}&date=${date}&client_id=${clientId}`;
};

export const goToEditShiftLogPage = (editShiftLogPath, shiftLogId, date, rowShiftNum) => {
  let defaultEditPathParams = new URLSearchParams(editShiftLogPath);
  window.location.href = `/shift_logs/${shiftLogId}/edit?=${date}&location=${defaultEditPathParams.get(
    "location"
  )}&shift_num=${rowShiftNum}`;
};

export const replaceWhitespaceWithUnderscore = (str) => {
  return str.replace(/\s+/g, "_");
};

// export const shitLogDetailsKeysAndLabels = ["meal_prep", "laundry", "goods", "bed_checks", "personal_care", "room_cleaning", "shower", "shave", "hair_wash", "hair_cut", "peri_care", "nail_care", "bed_linen_change", "clothing_change", "vitals", "medication"];

export const shitLogDetailsKeysAndLabels = [
  { key: "meal_prep", label: "Meal Prep" },
  { key: "laundry", label: "Laundry" },
  { key: "goods", label: "Goods Distributions" },
  { key: "bed_checks", label: "Bed Checks" },
  { key: "personal_care", label: "Personal Care" },
  { key: "room_cleaning", label: "Room Cleaning" },
  { key: "shower", label: "Shower" },
  { key: "shave", label: "Shave" },
  { key: "hair_wash", label: "Hair Wash" },
  { key: "hair_cut", label: "Hair Cut" },
  { key: "peri_care", label: "Peri Care" },
  { key: "nail_care", label: "Nail Care" },
  { key: "bed_linen_change", label: "Bed Linen Change" },
  { key: "clothing_change", label: "Clothing Change" },
  { key: "vitals", label: "Vitals" },
  { key: "medication", label: "Prescribed Meds Given?" }
];
