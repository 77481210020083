import React, { createContext } from "react";
import PropTypes from "prop-types";
import TableComponent from "./InventoryTableComponent";

export const InventoriesTableContext = createContext();
function InventoriesTable({ data }) {
  return (
    <InventoriesTableContext.Provider
      value={{
        data
      }}
    >
      <div>
        <TableComponent />
      </div>
    </InventoriesTableContext.Provider>
  );
}

InventoriesTable.propTypes = {
  data: PropTypes.array.isRequired
};

export default InventoriesTable;
