import React from "react";
import styled from "styled-components";
import ReadMore from "../read_more";
import { replaceWhitespaceWithUnderscore } from "./utility_functions";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { ShiftLogDetailsDisplay } from "./ShiftLogDetailsDisplay";

const ShiftLogOverviewDisplayActionButton = ({ clientName, onAction, rowShiftNum, actionType }) => {
  return (
    <Button
      id={`${replaceWhitespaceWithUnderscore(clientName)}_shift_${rowShiftNum}_${actionType}_btn`}
      variant={actionType == "new" ? "contained" : "outlined"}
      onClick={onAction}
    >
      {actionType.charAt(0).toUpperCase() + actionType.slice(1)}
    </Button>
  );
};

ShiftLogOverviewDisplayActionButton.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  clientName: PropTypes.string,
  onAction: PropTypes.string,
  actionType: PropTypes.string
};

export const ShiftLogOverviewDisplay = ({
  shiftLogToRender,
  clientName,
  onAction,
  rowShiftNum,
  actionType
}) => {
  console.log(shiftLogToRender);
  if (!shiftLogToRender) {
    return (
      <ShiftLogShiftOverviewDisplayStyle>
        <ShiftLogOverviewDisplayActionButton
          clientName={clientName}
          onAction={onAction}
          rowShiftNum={rowShiftNum}
          actionType={actionType}
        />
      </ShiftLogShiftOverviewDisplayStyle>
    );
  }
  return (
    <ShiftLogShiftOverviewDisplayStyle>
      {shiftLogToRender.log_description && (
        <SectionLabel variant="subtitle1">Description:</SectionLabel>
      )}
      <ReadMore text={shiftLogToRender.log_description ?? ""}></ReadMore>
      <ShiftLogDetailsDisplay shiftLog={shiftLogToRender} />
      <ShiftLogOverviewDisplayActionButton
        clientName={clientName}
        onAction={onAction}
        rowShiftNum={rowShiftNum}
        actionType={actionType}
      />
    </ShiftLogShiftOverviewDisplayStyle>
  );
};

const ShiftLogShiftOverviewDisplayStyle = styled.div``;

const SectionLabel = styled(Typography)`
  && {
    font-weight: bold;
  }
`;

ShiftLogOverviewDisplay.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  shiftLogToRender: PropTypes.object.isRequired,
  clientName: PropTypes.string,
  onAction: PropTypes.string,
  actionType: PropTypes.string
};
