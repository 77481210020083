import { useState, useEffect } from "react";

export function useFetchData(asyncCallback, args = []) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await asyncCallback(...args);
        setData(response);
        setError(null);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [asyncCallback]);

  return { data, error, loading };
}
