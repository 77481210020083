/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

const styles = {
  option: (styles) => ({
    ...styles,
    color: "black",
    cursor: "pointer"
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: "3em",
    display: "flex",
    alignItems: "center"
  })
};

const options = [
  {
    label: "All",
    value: "all",
    id: "no_filter"
  },
  {
    label: "Inactive",
    value: false,
    id: "inactive"
  },
  {
    label: "Active",
    value: true,
    id: "active"
  }
];

const Input = (props) => {
  const { autoComplete } = props.selectProps;
  return <components.Input {...props} autoComplete={autoComplete} />;
};

const ClientInactiveFilter = ({ initialValue }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [inactiveActiveValue, setInactiveActiveValue] = useState(options[0]);

  useEffect(() => {
    if (initialValue === undefined || initialValue === null) return;
    if (initialValue == "true") setInactiveActiveValue(options[2]);
    if (initialValue == "false") setInactiveActiveValue(options[1]);
  }, [initialValue]);

  useEffect(() => {
    const form = document.getElementById("clients-search-inacitive-active-filter-id");
    if (initialLoad || !form) {
      return;
    }
    form.submit();
  }, [inactiveActiveValue]);

  return (
    <div style={{ marginRight: "0.75rem" }}>
      {inactiveActiveValue.value !== undefined && (
        <input type="hidden" name="filter_clients_by_active" value={inactiveActiveValue.value} />
      )}
      <Select
        className="client-inactive-filter-select"
        styles={styles}
        options={options}
        components={{ Input }}
        isSearchable={false}
        value={inactiveActiveValue}
        id="filter_clients_by_active_select"
        onChange={(selectedOption) => {
          setInactiveActiveValue(selectedOption);
          setInitialLoad(false);
        }}
      />
    </div>
  );
};

export default ClientInactiveFilter;
