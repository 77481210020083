import React from "react";

import PropTypes from "prop-types";
import MentionEditorRenderer from "./MentionEditorRenderer";

export default function CustomEditorRender({
  htmlContent = "",
  clientName = "",
  showFullContent = false
}) {
  return (
    <MentionEditorRenderer
      defaultHTML={htmlContent}
      clientName={clientName}
      showFullContent={showFullContent}
    />
  );
}

CustomEditorRender.propTypes = {
  htmlContent: PropTypes.string,
  clientName: PropTypes.string,
  showFullContent: PropTypes.bool
};
