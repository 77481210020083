/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";

import PropTypes from "prop-types";
import WorkShiftLogModal from "./work_shift_log_modal";
const WorkShiftLogButton = (props) => {
  const { show_popup: showPopup, work_shift_log: workShiftLog } = props;
  const [showModal, setShowModal] = useState(showPopup);

  return (
    <>
      <button
        explorator_test_id="work_shift_log_button"
        className={`work-shift-log-button ${workShiftLog.status}`}
        onClick={() => setShowModal(!showModal)}
      >
        {workShiftLog.status === "submitted" ? (
          <i className="fas fa-check-circle"></i>
        ) : (
          <i className="fas fa-regular fa-clock"></i>
        )}
        {workShiftLog.status === "submitted" ? "Clocked in" : "Clock in"}
      </button>
      {showModal && (
        <WorkShiftLogModal workShiftLog={workShiftLog} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

WorkShiftLogButton.propTypes = {
  show_popup: PropTypes.bool.isRequired,
  work_shift_log: PropTypes.object.isRequired
};

export default WorkShiftLogButton;
