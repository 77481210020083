import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Modal, TextField } from "@mui/material";
import "toastr/build/toastr.min.css";
import { styleForPourAndDispenseModal } from "./pour_and_dispense_utils";
import PourAndDispenseModalCloseIcon from "./pour_and_dispense_close_icon";

const PourAndDispenseNotesModal = ({ isOpen, onComplete, currentValue }) => {
  const [noteText, setNoteText] = useState();

  useEffect(() => {
    setNoteText(currentValue);
  }, [currentValue]);

  const onClose = () => {
    onComplete(noteText);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={styleForPourAndDispenseModal}>
        <PourAndDispenseModalCloseIcon onClick={() => onComplete()} />
        <div className="pour_and_dispense_modal_container_shared_style">
          <h2>Notes</h2>
          <br />
          <TextField
            onChange={(event) => setNoteText(event.target.value)}
            value={noteText}
            rows={3}
          />
        </div>
      </Box>
    </Modal>
  );
};

PourAndDispenseNotesModal.propTypes = {
  isOpen: PropTypes.bool,
  onComplete: PropTypes.func,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default PourAndDispenseNotesModal;
