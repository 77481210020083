import React from "react";

const ToDoListFormLocationSelector = (props) => {
  const {
    locations,
    selected_location
  } = props;

  const locationOnChange = (e) => {
    const newLocation = e.target.value;

    if(props.controller_action === "new") {
      window.location = encodeURI("/to_do_lists/new?location=" + newLocation);
    }
  };

  return(
    <div className="select is-rounded">
      <select name="to_do_list[location]" id="location" selected="selected" onChange={locationOnChange} defaultValue={selected_location}>
        {
          locations.map((location, index) => (
              <option key={index} value={location}>{location}</option>
            )
          )
        }
      </select>
    </div>
  )
};

export default ToDoListFormLocationSelector;
