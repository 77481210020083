import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { formatDateTimeByString } from "../../timeUtils";
import axios from "axios";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import TableViewContextProvider, { useTableViewContext } from "./TableViewContext";
import TableViewPopup from "./table_view_popup";
import { RefreshShiftLogsContext } from "./RefreshShiftLogsContext";
import ReadMore from "../read_more";

const TableView = ({ client_id }) => {
  const {
    loading,
    error,
    data: formattedShiftLogs,
    execute: refresh
  } = useAsyncCallback(async () => getShiftLogsGroupByDateAndClient(client_id), []);

  return (
    <TableViewContextProvider pageParams={{ client_id }}>
      <RefreshShiftLogsContext.Provider value={refresh}>
        <div className="table-container">
          {loading && <div>loading...</div>}
          {error && <div>Error: {error.message}</div>}
          {!loading && (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Client Detail</th>
                  <th>Shift Number</th>
                  <th>Shift Note (What happened?)</th>
                  <th>Care Plan</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {formattedShiftLogs.map((shiftLog, index) => {
                  return (
                    <Fragment key={index}>
                      <tr id={`${shiftLog.date}`}>
                        <td rowSpan={3}>{shiftLog.date}</td>
                        <td rowSpan={3}>
                          <div>{shiftLog.client_detail.pronoun}</div>
                          <div>{shiftLog.client_detail.healthcard_number}</div>
                          <div>{shiftLog.client_detail.dob}</div>
                          {shiftLog.client_detail.avatar_url && (
                            <img
                              src={shiftLog.client_detail.avatar_url}
                              className="table-container-avatar_url"
                            />
                          )}
                        </td>
                        <TableTd rowShiftNum="1st" shiftLog={shiftLog} date={shiftLog.date} />
                      </tr>
                      <tr>
                        <TableTd rowShiftNum="2nd" shiftLog={shiftLog} date={shiftLog.date} />
                      </tr>
                      <tr>
                        <TableTd rowShiftNum="3rd" shiftLog={shiftLog} date={shiftLog.date} />
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          <TableViewPopup />
        </div>
      </RefreshShiftLogsContext.Provider>
    </TableViewContextProvider>
  );
};
function TableTd({ rowShiftNum, shiftLog, date }) {
  const isNewLog = !(shiftLog.shifts[rowShiftNum] && shiftLog.shifts[rowShiftNum].id);
  const action = isNewLog ? "new" : "edit";

  const { state, dispatch } = useTableViewContext();
  const togglePopup = () => {
    if (isNewLog) {
      dispatch({ type: "selectedDateForNewLog", payload: date });
    }

    dispatch({ type: "action", payload: action });
    dispatch({ type: "selectedRowShiftNum", payload: rowShiftNum });
    dispatch({ type: "selectedShiftLog", payload: shiftLog.shifts[rowShiftNum] });
    dispatch({ type: "selectedClientId", payload: shiftLog.client_id });
    dispatch({ type: "selectedClientName", payload: shiftLog.client_name });
    dispatch({ type: "selectedClientDetail", payload: shiftLog.client_detail });

    dispatch({ type: "popup", payload: !state.popup });
  };

  return (
    <>
      <td>{rowShiftNum}</td>
      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLog.shifts[rowShiftNum]?.log_description ?? ""}></ReadMore>
          <div>
            <button
              id={`${shiftLog.date}_shift_${rowShiftNum}_${action}_btn`}
              className={`button is-small ${action == "new" ? "is-primary" : ""} `}
              onClick={togglePopup}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </button>
          </div>
        </div>
      </td>

      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLog.client_detail.care_plan}></ReadMore>
        </div>
      </td>

      <td>
        {shiftLog.shifts[rowShiftNum]
          ? formatDateTimeByString(shiftLog.shifts[rowShiftNum]?.updated_at)
          : ""}
      </td>
    </>
  );
}

TableView.propTypes = {
  client_id: PropTypes.string.isRequired
};
TableTd.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  shiftLog: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired
};

export default TableView;
async function getShiftLogsGroupByDateAndClient(client_id) {
  const res = await axios.post("/shift_logs/group_by_date_and_client.json", {
    client_id
  });
  return res.data;
}
