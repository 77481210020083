import React from "react";
import PropTypes from "prop-types";

function SeenCheckbox({ seen, setSeen }) {
  return (
    <div className="flex">
      <label className={`checkbox_container ${!seen ? "is-not-seen" : ""}`}>
        <input
          className="checkbox"
          type="checkbox"
          checked={!seen}
          onChange={() => setSeen(false)}
        />
        <b>Not seen</b>
      </label>
      <label className={`checkbox_container ${seen ? "is-seen" : ""}`}>
        <input className="checkbox" type="checkbox" checked={seen} onChange={() => setSeen(true)} />
        <b>Seen</b>
      </label>
    </div>
  );
}

SeenCheckbox.propTypes = {
  seen: PropTypes.bool,
  setSeen: PropTypes.func
};

export default SeenCheckbox;
