import { stringToDate } from "./pour_and_dispense_table";

export const PossibleTimesToDispense = [
  {
    hour: 7,
    minutes: 30,
    disableCannabis: true
  },
  {
    hour: 8,
    minutes: 30,
    disableCannabis: true
  },
  {
    hour: 9,
    minutes: 30
  },
  {
    hour: 10,
    minutes: 30
  },
  {
    hour: 11,
    minutes: 30
  },
  {
    hour: 12,
    minutes: 30
  },
  {
    hour: 13,
    minutes: 30
  },
  {
    hour: 14,
    minutes: 30
  },
  {
    hour: 15,
    minutes: 30
  },
  {
    hour: 16,
    minutes: 30
  },
  {
    hour: 17,
    minutes: 30
  },
  {
    hour: 18,
    minutes: 30
  },
  {
    hour: 19,
    minutes: 30
  },
  {
    hour: 20,
    minutes: 30
  },
  {
    hour: 21,
    minutes: 30,
    disableCannabis: true
  }
];

export const addZeroInFrontIfUnder10 = (value) => (value < 10 ? "0" + value : value);

export const formatTimeToJSDatObject = (time, day, removeMonth) => {
  const formattedTime = new Date(stringToDate(day, true));
  formattedTime.setMonth(formattedTime.getMonth() + (removeMonth ? -1 : 0));
  formattedTime.setHours(time.hour);
  formattedTime.setMinutes(time.minutes);
  formattedTime.setSeconds(0);
  formattedTime.setMilliseconds(0);
  return formattedTime;
};

export const formatMinuteAndHour = (time) =>
  time ? `${addZeroInFrontIfUnder10(time.hour)}:${addZeroInFrontIfUnder10(time.minutes)}` : "error";

export const getEntryFromTime = (time, iSAlcoholEntry, entries) =>
  entries.find((entryEle) => {
    const value = new Date(entryEle[iSAlcoholEntry ? "pour_time" : "dispense_time"]);
    if (value.getHours() === time.hour && value.getMinutes() === time.minutes) return true;
    return false;
  });

export const getAlcoholDisplayFromTime = (time, alcohol_given_time_entries) => {
  const entry = getEntryFromTime(time, true, alcohol_given_time_entries);
  if (!entry) return "";
  if (Math.round(entry.volume) === -1 || entry.volume.localeCompare("-1") === -1) {
    return `${entry.pour_type} - N/A`;
  }
  return `${capitalize(entry.pour_type)} - ${entry.volume}`;
};

export const getCannabisDispenseDisplay = (time, cannabis_dispense_time_entries) => {
  const entry = getEntryFromTime(time, false, cannabis_dispense_time_entries);
  if (!entry) return "NO";
  return "YES";
};

export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const styleForPourAndDispenseModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
