/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ClientStatusToggle from "./clients/client_status_toggle";
import RemoveClientBtn from "./clients/remove_client_btn";

const ShowDuplicatesModal = ({ similar_clients, action }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [similarClients, setSimilarClients] = useState(similar_clients);
  if (!similar_clients || !Array.isArray(similar_clients) || similar_clients.length === 0)
    return <></>;
  if (!isOpen) return <></>;

  // const byPassDuplicateCreation = () => {
  //   setIsOpen(false);
  //   const submitBtn = document.querySelector(".client-action-edit-active input[type='submit']");
  //   if (!submitBtn) {
  //     alert("Unknown error, please click Save manually");
  //   } else {
  //     submitBtn.click();
  //   }
  // };

  useEffect(() => {
    if (similarClients.length === 0) {
      setIsOpen(false);
    }
  }, [similarClients]);

  // TODO test this with new inactive/is deceased logic
  return (
    <div className="clients-page-import-export-modal-container">
      <div className="clients-page-import-export-modal">
        <i
          explorator_test_id="client-edit-create-duplicate-modal-close-icon"
          className="fa fa-times modal-close-icon"
          onClick={() => setIsOpen(false)}
        />
        <h1 className="title modal-title">Duplicate Clients</h1>

        <div className="client-body client-body-in-modal">
          <table>
            <thead>
              <tr className="clients-title not-hidden">
                <th>REG#</th>
                <th>Client Name</th>
                <th>Location</th>
                <th>Date of Birth</th>
                <th>Healthcard #</th>
                <th>Healthcard Expiry</th>
                <th>Healthcard Version</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="client-box">
              {similarClients &&
                similarClients.map((newClient) => (
                  <React.Fragment key={newClient.id}>
                    <tr
                      explorator_test_id={`client-duplicated-row-${newClient.id}`}
                      className="card-container__top not-hidden clients-page-import-export-modal-client-container"
                    >
                      <td>{newClient.id}</td>
                      <td>
                        {`${newClient.first_name} ${newClient.last_name} `}
                        <b>{`(${newClient.inactive ? "Inactive" : "Active"})`}</b>
                      </td>
                      <td>{newClient.location}</td>
                      <td>{newClient.dob}</td>
                      <td>{newClient.healthcard_number}</td>
                      <td>{newClient.healthcard_expiry}</td>
                      <td>{newClient.healthcard_version_code}</td>
                      <td>
                        <a
                          explorator_test_id={`client-duplicated-row-edit-link-${newClient.id}`}
                          href={newClient.edit_url}
                          className="client-duplicated-row-edit-link"
                        >
                          Edit
                        </a>
                      </td>
                      <td>
                        <ClientStatusToggle
                          clientId={newClient.id}
                          isActive={!newClient.inactive}
                          setClientInActive={(inActive) => {
                            setSimilarClients(
                              similarClients.map((client) =>
                                client.id === newClient.id
                                  ? { ...client, inactive: inActive }
                                  : client
                              )
                            );
                          }}
                        />
                      </td>
                      <td>
                        <RemoveClientBtn
                          clientId={newClient.id}
                          removeClient={() => {
                            setSimilarClients(
                              similarClients.filter((client) => client.id !== newClient.id)
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr className="space"></tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          {/* Hiding create duplicate functionality */}
          {/* {(action === "create" || action === "new") && (
            <button
              onClick={byPassDuplicateCreation}
              className="button is-primary"
              explorator_test_id="client-duplicate-create-btn"
            >
              Create Duplicate
            </button>
          )} */}
          {(action === "edit" || action === "update" || action === "show") && (
            <button
              type="button"
              className="button is-light"
              onClick={() => setIsOpen(false)}
              explorator_test_id="client-duplicate-close-btn"
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ShowDuplicatesModal.propTypes = {
  similar_clients: PropTypes.array,
  action: PropTypes.string
};

export default ShowDuplicatesModal;
