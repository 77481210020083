import React, { useState } from "react";
import PropTypes from "prop-types";
import NameField from "./name_fields";

const NameFieldsList = ({ initialNames }) => {
  const [names, setNames] = useState(initialNames);

  const handleNameChange = (index, newName) => {
    const updatedNames = names.map((name, i) => (i === index ? newName : name));
    setNames(updatedNames);
  };

  const removeNameField = (index) => {
    const updatedNames = names.filter((_, i) => i !== index);
    setNames(updatedNames);
  };

  const addNameField = () => {
    setNames([...names, ""]);
  };

  return (
    <div className="names-container">
      {names.map((name, index) => (
        <NameField
          key={index}
          name={name}
          index={index}
          onNameChange={handleNameChange}
          onRemove={removeNameField}
        />
      ))}
      <button
        type="button"
        id="add-more-names-button"
        className="button is-secondary"
        onClick={addNameField}
      >
        Add more names
      </button>
      {/* Hidden field to store the names as a JSON string */}
      <input type="hidden" name="work_shift_log[names]" value={JSON.stringify(names)} />
    </div>
  );
};

NameFieldsList.propTypes = {
  initialNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default NameFieldsList;
