import React from "react";
import PropTypes from "prop-types";
import FieldsFilter from "./FieldsFilter";
import DenseViewContextProvider from "./DenseViewContext";
import ShiftLogList from "./shift_log_lists";

const DenseView = ({ shiftLogs, location }) => {
  return (
    <DenseViewContextProvider location={location}>
      <FieldsFilter />
      <ShiftLogList shiftLogs={shiftLogs} />
    </DenseViewContextProvider>
  );
};

DenseView.propTypes = {
  shiftLogs: PropTypes.array.isRequired,
  location: PropTypes.string.isRequired
};

export default DenseView;
