import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TODOListActionButtonContainer from "../todo_list/todo_list_all_action_buttons_container";

// We use UTC to avoid converting dates already stored in the ET timezone.
dayjs.extend(utc);

const ToDoListContainer = ({ toDoList }) => {
  return (
    <div className="box to-do-list-container">
      {/*-------------------------- Task title & Urgent mark-------------------------- */}
      <div className="title-row">
        <div className="row-title">
          <b>{toDoList.to_do_list_title}</b>
          {toDoList.is_urgent && <div className="urgent-task">Urgent Task123</div>}
        </div>
        <TODOListActionButtonContainer todoListData={toDoList} />
      </div>
      <div>
        {/*--------------------------Passed down records styling---------------------------------- */}
        {toDoList.pass_to_next_shift_records && toDoList.pass_to_next_shift_records.length > 0 && (
          <div className="pass-to-next-shift-records-row">
            <div className="passdown-record-title">Passdown Records</div>
            <div className="passdown-record-list">
              <ul>
                {toDoList.pass_to_next_shift_records.map((record, index) => (
                  <li key={index}>
                    <i className="far fa-user-circle"></i>
                    {` ${record.user} - `}
                    <span>{record.reason} </span>
                    {record.initial && (
                      <>
                        by <span className="user-highlight">{record.initial} </span>
                      </>
                    )}
                    {record.seen !== undefined && (
                      <span className={record.seen ? "is-seen" : "is-not-seen"}>
                        <b>{record.seen ? "(Seen)" : "(Not seen)"}</b>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="info-container">
        {/* -------------------------- Assigned User name --------------------------*/}
        <div className="info-row">
          <i className="fas fa-user-circle"></i>
          {toDoList.assigned_user_name}
        </div>
        {/*-------------------------- Client name -------------------------- */}
        <div className="info-row">
          <i className="far fa-user-circle"></i>
          {toDoList.client_name ? toDoList.client_name : "N/A"}
        </div>
        {/*-------------------------- Task type -------------------------- */}
        <div className="info-row">
          <i className="far fa-sticky-note"></i>
          {toDoList.task_type ? toDoList.task_type : "N/A"}
        </div>
        {/* ------------------------------ Current and Next occurrence ---------------------------------------------  */}
        <div className="time-row">
          <>
            <i className="far fa-calendar"></i>{" "}
            {/*  For recurring task display format:
            Daily until July 16, 2024, at  14:00 (next occurrence: July 10, 2024, at  14:00)*/}
            {toDoList.is_recurring_to_do_list ? (
              <>
                {/* Daily until July 16, 2024, at */}
                {toDoList.recurrence_description}, at {/* Clock icon */}
                <i className="far fa-clock"></i>
                {/* 14:00 */}
                {dayjs(new Date(toDoList.to_do_list_time)).format("HH:mm")}
                {/* (next occurrence: July 10, 2024, at  14:00) */}
                {toDoList.next_occurrence ? (
                  <>
                    <b>
                      {" "}
                      {"("}next occurrence: {toDoList.next_occurrence.split("#")[0]}, at{" "}
                      <i className="far fa-clock"></i> {toDoList.next_occurrence.split("#")[1]}
                      {")"}
                    </b>
                  </>
                ) : (
                  <>
                    <b>{" (no next occurrence) "}</b>
                  </>
                )}
              </>
            ) : (
              <>
                {/*  For non-recurring task display format: July 08, 2024, at 10:00 */}
                {dayjs.utc(new Date(toDoList.to_do_date)).format("MMMM DD, YYYY")}, at{" "}
                <i className="far fa-clock"></i>
                {dayjs(new Date(toDoList.to_do_list_time)).format("HH:mm")}
              </>
            )}
          </>
        </div>
        {/* -------------------------------------Task description------------------------------------------------------  */}
        <div className="desc-row">
          <div dangerouslySetInnerHTML={{ __html: toDoList.task_description }} />
        </div>
        <br />
      </div>
      {/*  ------------------------------------Action buttons: Mark as Complete, Skip, Incomplete-------------------------------------------- */}
      <div className="actions-column">{/* Add action buttons here if needed */}</div>
    </div>
  );
};

ToDoListContainer.propTypes = {
  toDoList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    to_do_list_title: PropTypes.string,
    is_urgent: PropTypes.bool,
    assigned_user_name: PropTypes.string,
    client_name: PropTypes.string,
    task_type: PropTypes.string,
    is_recurring_to_do_list: PropTypes.bool,
    recurrence_description: PropTypes.string,
    to_do_list_time: PropTypes.string,
    next_occurrence: PropTypes.string,
    to_do_date: PropTypes.string,
    task_description: PropTypes.string,
    is_complete: PropTypes.bool,
    is_incomplete: PropTypes.bool,
    pass_to_next_shift_records: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.string,
        reason: PropTypes.string,
        initial: PropTypes.string,
        seen: PropTypes.bool
      })
    )
  }).isRequired
};

export default ToDoListContainer;
