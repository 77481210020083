import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AddRemoveStockModal from "./add_remove_stock_modal";

const AddRemoveStock = ({ inventory }) => {
  const [isModalActive, setModalActive] = useState(false);

  const openModal = () => setModalActive(true);
  const closeModal = () => setModalActive(false);
  useEffect(() => {
    const buttonId = `add_remove_stock_${inventory.id}`;
    const handleClick = () => {
      openModal();
    };
    document.getElementById(buttonId).addEventListener("click", handleClick);
    return () => {
      document.getElementById(buttonId).removeEventListener("click", handleClick);
    };
  }, [inventory.id]);

  if (isModalActive) {
    return <AddRemoveStockModal inventory={inventory} closeModal={closeModal} />;
  }
  return null;
};

AddRemoveStock.propTypes = {
  inventory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired
  })
};

export default AddRemoveStock;
