import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import ShiftLogForm from "./shift_log_form";
import { useTableViewContext } from "./TableViewContext";

function TableViewPopup() {
  const {
    state: { popup },
    dispatch
  } = useTableViewContext();
  return (
    <Dialog
      open={popup}
      onClose={() => {
        dispatch({ type: "reset" });
      }}
      PaperProps={{ sx: { width: "90vw", height: "90vh" } }}
      maxWidth={false}
    >
      <DialogContent>{popup && <ShiftLogForm />}</DialogContent>
    </Dialog>
  );
}

export default TableViewPopup;
