import React from "react";
import PropTypes from "prop-types";
import UnifiedSearchAutocompleteComponent from "./unified_search_autocomplete_component";
const hardCodedOptions = [
  { name: "REPORT FOR GUEST", id: null },
  { name: "UNKNOWN CLIENT (UNLINKED)", id: null }
];

function ClientSearchInputAutocomplete({ selected_client_name = "", options }) {
  const handleFormSubmit = (option) => {
    window.location.href = `/incident_reports?client_name=${option.name}`;
  };

  const handleDropdownClick = (option) => {
    window.location.href = `/incident_reports?client_name=${option.name}`;
  };
  const handleClearClick = () => {
    window.location.href = `/incident_reports`;
  };

  return (
    <UnifiedSearchAutocompleteComponent
      selected_name={selected_client_name}
      options={options}
      hardCodedOptions={hardCodedOptions}
      handleFormSubmit={handleFormSubmit}
      handleDropdownItemClick={handleDropdownClick}
      inputFieldId="selected_client_name"
      inputFieldPlaceholder="Search by Client name"
      handleClearClick={handleClearClick}
      handleOnSelectedOptionChange={() => {}}
    />
  );
}

ClientSearchInputAutocomplete.propTypes = {
  selected_client_name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ClientSearchInputAutocomplete;
