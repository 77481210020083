/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";
import { createContext } from "react";
import SeenCheckbox from "./seen_checkbox";

const PassToNextShift = createContext();
const selectOptions = ["Pass to Next shift", "Skip Occurrence"];

const ToDoListSkipOccurrencePopup = ({ uniqueId }) => {
  const [open, setOpen] = useState(false);
  const form = document.querySelector(
    `form[data-id="skip-occurrence-to-do-form-with-${uniqueId}"]`
  );
  const userReasonHiddenInput = form.querySelector(`#reason-field-with-${uniqueId}`);
  const userInitialHiddenInput = form.querySelector(`#initial-field-with-${uniqueId}`);
  const userSeenHiddenCheckbox = form.querySelector(`#seen-field-with-${uniqueId}`);
  const userSkipOccurrenceOptionHiddenInput = form.querySelector(
    `#skip-occurrence-option-field-with-${uniqueId}`
  );
  const formSubmitBtn = form.querySelector(`button[type="submit"]`);

  const popupFormId = `reason-popup-container-${uniqueId}`;

  const submitEventHandler = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    formSubmitBtn.addEventListener("click", submitEventHandler);
    return () => {
      formSubmitBtn.removeEventListener("click", submitEventHandler);
    };
  }, []);
  const handleClose = () => {
    userReasonHiddenInput.value = "";
    userInitialHiddenInput.value = "";
    userSeenHiddenCheckbox.checked = false;
    userSkipOccurrenceOptionHiddenInput.value = "pass to next shift";
    setOpen(false);
  };

  return (
    <PassToNextShift.Provider
      value={{
        uniqueId,
        popupFormId,
        form,
        userReasonHiddenInput,
        userInitialHiddenInput,
        userSeenHiddenCheckbox,
        userSkipOccurrenceOptionHiddenInput,
        formSubmitBtn
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <UserInputReasonForm />
        </DialogContent>
      </Dialog>
    </PassToNextShift.Provider>
  );
};

ToDoListSkipOccurrencePopup.propTypes = {
  uniqueId: PropTypes.string.isRequired
};
export default ToDoListSkipOccurrencePopup;

const UserInputReasonForm = () => {
  const {
    popupFormId,
    form,
    userReasonHiddenInput,
    userInitialHiddenInput,
    userSeenHiddenCheckbox,
    userSkipOccurrenceOptionHiddenInput,
    formSubmitBtn
  } = useContext(PassToNextShift);
  const [initialInput, setInitialInput] = useState("");
  const [reasonInput, setReasonInput] = useState("");
  const [seen, setSeen] = useState(false);
  const [skipOccurrenceInput, setSkipOccurrenceInput] = useState("Pass to Next shift");

  const handleSubmit = (e) => {
    e.preventDefault();
    formSubmitBtn.click();
    const $form = $(`form#${popupFormId}`);
    const isValid = $form.valid();
    if (!isValid) {
      return;
    }
    userReasonHiddenInput.value = reasonInput;
    userSkipOccurrenceOptionHiddenInput.value = skipOccurrenceInput;
    form.submit();
  };

  useEffect(() => {
    const $form = $(`form#${popupFormId}`);
    $form.validate({
      rules: {
        reasonInput: {
          required: true
        },
        initialInput: {
          required: true
        }
      }
    });
    return () => {
      $form.off("submit");
      $form.validate().destroy();
    };
  }, [skipOccurrenceInput]);
  useEffect(() => {
    userReasonHiddenInput.value = reasonInput;
  }, [reasonInput]);
  useEffect(() => {
    userInitialHiddenInput.value = initialInput;
  }, [initialInput]);
  useEffect(() => {
    userSeenHiddenCheckbox.checked = seen;
  }, [seen]);

  return (
    <form className="pass-to-next-shift-popup-container" id={popupFormId}>
      <p>Please enter the reason passing to next shift</p>
      <input
        type="text"
        className="input"
        name="reasonInput"
        value={reasonInput}
        placeholder="Reason"
        onChange={(e) => setReasonInput(e.target.value)}
        explorator_test_id={"to_do_list_mark_complete_reason_input"}
      />
      <input
        type="text"
        className={`input`}
        name="initialInput"
        value={initialInput}
        placeholder="Initial"
        onChange={(e) => setInitialInput(e.target.value)}
        explorator_test_id={"to_do_list_mark_complete_reason_input"}
      />

      <SeenCheckbox seen={seen} setSeen={setSeen} />

      <div className="select is-rounded">
        <select
          className="pass-to-next-shift-options-select"
          value={skipOccurrenceInput}
          onChange={(e) => setSkipOccurrenceInput(e.target.value)}
        >
          {selectOptions.map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
      <button
        className="button is-primary submit-to-do"
        onClick={handleSubmit}
        disabled={
          (skipOccurrenceInput === "Skip Occurrence" && (!initialInput || !reasonInput)) ||
          !reasonInput
        }
      >
        Confirm
      </button>
    </form>
  );
};
