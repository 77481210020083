/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

const amountOfBorderRadius = "1rem";

const borderRadiusRight = {
  borderTopRightRadius: amountOfBorderRadius,
  borderBottomRightRadius: amountOfBorderRadius
};

const borderRadiusLeft = {
  borderTopLeftRadius: amountOfBorderRadius,
  borderBottomLeftRadius: amountOfBorderRadius
};

const ProgressBarSection = ({ color, start, end, numberOfTasks, totalNumberOfTasks }) => {
  const borderRadiusStyle = useMemo(() => {
    let objectToRender = {};
    if (start) objectToRender = { ...objectToRender, ...borderRadiusLeft };
    if (end) objectToRender = { ...objectToRender, ...borderRadiusRight };
    return objectToRender;
  }, [numberOfTasks]);

  const percentageOfTasks = useMemo(() => {
    return Math.round((numberOfTasks / totalNumberOfTasks) * 100);
  }, [totalNumberOfTasks, numberOfTasks]);

  if (numberOfTasks === 0) return <></>;
  return (
    <div style={{ width: `${percentageOfTasks}%` }}>
      <div
        className="to-do-list-progress-bar-section"
        style={{ ...borderRadiusStyle, backgroundColor: color }}
      />
    </div>
  );
};

const ToDoListProgressBar = (to_do_list_progress_bar_counts) => {
  // Extracting label information into a single variable
  const labelsInfo = [
    { color: "#48cd62", count: to_do_list_progress_bar_counts.completed, label: "completed" },
    { color: "#f48a1e", count: to_do_list_progress_bar_counts.skipped, label: "skipped" },
    { color: "#ffdd57", count: to_do_list_progress_bar_counts.incomplete, label: "incomplete" },
    { color: "#d3d3d3", count: to_do_list_progress_bar_counts.ignored, label: "ignored" },
    { color: "#ffffff", count: to_do_list_progress_bar_counts.inProgress, label: "inprogress" }
  ];
  // Filtering out and displaying labels with count>0
  const filteredLabelsInfo = labelsInfo.filter((info) => info.count > 0);
  const total = to_do_list_progress_bar_counts.total;

  return (
    <div>
      <div className="to-do-list-progress-bar-container">
        {filteredLabelsInfo.map((info, index) => (
          <ProgressBarSection
            key={info.label}
            color={info.color}
            numberOfTasks={info.count}
            start={index === 0}
            end={index === filteredLabelsInfo.length - 1}
            totalNumberOfTasks={total}
          />
        ))}
      </div>
      <div className="to-do-list-progress-bar-label-container">
        {filteredLabelsInfo.map((info) => (
          <div
            key={info.label}
            className="to-do-list-progress-bar-label"
            style={{ backgroundColor: info.color }}
            data-todo_list_test_id_count={`todo_list_progress_bar_section_label_count_${info.label}_${info.count}`}
            data-explorator_test_id={`todo_list_progress_bar_section_label_${info.label}`}
          >
            <span>
              {info.label}: {info.count}
            </span>
          </div>
        ))}
        {filteredLabelsInfo.length > 0 && (
          <div className="to-do-list-progress-bar-total-count">Total: {total}</div>
        )}
      </div>
    </div>
  );
};

export default ToDoListProgressBar;
