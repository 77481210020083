import dayjs from "dayjs";

export function getCorrectShift(hour, minute) {
  const time = dayjs().hour(hour).minute(minute);
  if (time.isBetween(dayjs().hour(7).minute(0), dayjs().hour(14).minute(59), "minute", "[]")) {
    return "Shift: 1st";
  } else if (
    time.isBetween(dayjs().hour(15).minute(0), dayjs().hour(22).minute(59), "minute", "[]")
  ) {
    return "Shift: 2nd";
  } else {
    return "Shift: 3rd";
  }
}
