import React, { useContext, useState } from "react";

import { InventoriesTableContext } from "./inventories_table";
import PropTypes from "prop-types";
import InventoryQuantity from "./inventory_quantity";
import ReadMore from "../read_more";
const columns = ["Name", "Category", "Quantity", "Description", "Updated At"];

function InventoryTableComponent() {
  const { data } = useContext(InventoriesTableContext);

  const [sortingColumn, setSortingColumn] = useState(getUrlParams("sort_by", "created_at"));
  const [sortOrder, setSortOrder] = useState(getUrlParams("sort_order", "desc")); // "asc" or "desc"

  const toggleSortOrder = (currentOrder) => (currentOrder === "asc" ? "desc" : "asc");

  const handleHeaderClick = (column) => {
    const columnInSnakeCase = snakeCase(column);
    const newSortOrder = sortingColumn === columnInSnakeCase ? toggleSortOrder(sortOrder) : "asc";
    setSortingColumn(columnInSnakeCase);
    setSortOrder(newSortOrder);

    updateUrlParams({ sort_by: columnInSnakeCase, sort_order: newSortOrder });
  };
  return (
    <table className="table is-striped is-fullwidth">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              <div className="column-cell" onClick={() => handleHeaderClick(snakeCase(column))}>
                {column}
                <span style={{ display: sortingColumn === snakeCase(column) ? "unset" : "none" }}>
                  {sortOrder === "desc" ? "↓" : "↑"}
                </span>
              </div>
            </th>
          ))}
          <th colSpan={3} />
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.keys(row).map((rowKey, r_index) => {
              if (rowKey === "id") return null;

              return (
                <td key={`${rowKey} ${r_index}`}>
                  <RowComponent rowKey={rowKey} value={row[rowKey]} />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const RowComponent = ({ rowKey, value }) => {
  switch (rowKey) {
    case "quantity":
      return <InventoryQuantity quantity={parseInt(value)} />;
    case "description":
      return <ReadMore text={value} />;
    default:
      return _isHtmlTag(value) ? (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <span>{value}</span>
      );
  }
};

RowComponent.propTypes = {
  rowKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

RowComponent.displayName = "RowComponent";

function _isHtmlTag(str) {
  const regex = /^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$/;
  return regex.test(str);
}

function snakeCase(str) {
  return str.toLowerCase().replace(/ /g, "_");
}
function getUrlParams(param, defaultValue) {
  return new URLSearchParams(window.location.search).get(param) || defaultValue;
}

function updateUrlParams(params) {
  const currentUrl = new URL(window.location);
  Object.entries(params).forEach(([key, value]) => {
    currentUrl.searchParams.set(key, value);
  });
  window.location.href = currentUrl.href;
}
export default InventoryTableComponent;
