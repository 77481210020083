import React, { useState, useEffect } from "react";
import axios from "axios";
import ToDoListContainer from "./_to_do_list_container";
import dayjs from "dayjs";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [location, setLocation] = useState("");
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [untilDate, setUntilDate] = useState(dayjs().add(7, "day"));
  const [error, setError] = useState(false);
  const [isAppointmentVisible, setIsAppointmentVisible] = useState(() => {
    const savedState = sessionStorage.getItem("isAppointmentVisible");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  useEffect(() => {
    if (isAppointmentVisible) {
      const initialUrl = `/home/load_appointments.json?last_date=${untilDate.format("YYYY-MM-DD")}`;
      loadAppointments(initialUrl);
    }
  }, [isAppointmentVisible]);

  useEffect(() => {
    // Count total appointments
    const count = Object.values(appointments).reduce(
      (acc, appointmentLists) => acc + appointmentLists.length,
      0
    );
    setAppointmentCount(count);
    // Extract location from the first appointment
    const firstDateKey = Object.keys(appointments)[0];
    if (firstDateKey && appointments[firstDateKey].length > 0) {
      const firstAppointment = appointments[firstDateKey][0];
      if (firstAppointment && firstAppointment.location) {
        setLocation("Location : " + firstAppointment.location);
      } else {
        setLocation("No record found");
      }
    }
  }, [appointments]);

  const loadAppointments = (url) => {
    axios
      .get(url)
      .then((response) => {
        const appointments = response.data;
        setAppointments(appointments);
      })
      .catch((error) => {
        console.error("Error loading appointments:", error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const loadMoreAppointments = () => {
    setIsLoading(true);
    const newUntilDate = dayjs(untilDate).add(15, "day");
    setUntilDate(newUntilDate);
    loadAppointments(`/home/load_appointments.json?last_date=${newUntilDate.format("YYYY-MM-DD")}`);
  };

  const toggleAppointmentSection = () => {
    const newState = !isAppointmentVisible;
    setIsAppointmentVisible(newState);
    sessionStorage.setItem("isAppointmentVisible", JSON.stringify(newState));
  };

  return (
    <div id="appointment-section" className="appointment-container">
      {/* -------------------------- Appointment (count) --------------------------*/}
      <div className="tabs-container" onClick={toggleAppointmentSection}>
        <div className="tabs-row container">
          <div className="tabs is-boxed">
            <ul>
              <li id="appointment-tab" className="tab is-active" htmlFor="appointment-tab-content">
                <a className="count-container">
                  <div className="count">{isLoading ? "..." : `${appointmentCount}`}</div>
                  Appointment
                  {isAppointmentVisible ? (
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* -------------------------- Appointments container --------------------------*/}
      {isAppointmentVisible && (
        <div id="appointment-content">
          <div className="appointment-todos-container container box">
            <div className="location">
              {isLoading && !error
                ? "Loading..."
                : error
                ? "Error: Try again or report it in feedback."
                : location}
            </div>
            {/* -------------------------- Load appointments --------------------------*/}
            {Object.entries(appointments).map(([date, appointmentLists]) => (
              <div key={date}>
                <div className="shift-banner">{dayjs(date).format("MMMM DD, YYYY (dddd)")}</div>
                {appointmentLists.map((appointment, idx) => (
                  <ToDoListContainer
                    key={idx}
                    toDoList={{ ...appointment, to_do_date: date ? date : appointment.to_do_date }}
                  />
                ))}
              </div>
            ))}
            {/* -------------------------- Show more appointments --------------------------*/}
            <div id="more-appointments" className="more-appointments">
              {isLoading && !error ? (
                untilDate > dayjs().add(7, "day") ? (
                  "Loading..."
                ) : (
                  ""
                )
              ) : error ? (
                "Error: Try again or report it in feedback."
              ) : (
                <>
                  <div>
                    No more appointments until <b>{untilDate.format("MMMM DD, YYYY")}</b>
                  </div>
                  <button onClick={loadMoreAppointments}>
                    <u>{"Show for 15 more days"}</u>
                  </button>
                </>
              )}
            </div>
            {/* ----------------------------------------------------------------------------*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointments;
