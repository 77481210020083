import React, { useMemo } from "react";
import { getMaintenanceClass, shitLogDetailsKeysAndLabels } from "./utility_functions";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ShiftLogDetailsDisplay = ({ shiftLog }) => {
  const { filledOutDetails, emptyDetails } = useMemo(() => {
    const filledOutDetails = [];
    const emptyDetails = [];
    shitLogDetailsKeysAndLabels.forEach((shitLogDetailKeysAndLabel) => {
      console.log(shiftLog[shitLogDetailKeysAndLabel.key + "_text"]);
      if (shiftLog[shitLogDetailKeysAndLabel.key])
        filledOutDetails.push({
          value: shiftLog[shitLogDetailKeysAndLabel.key + "_text"],
          label: shitLogDetailKeysAndLabel.label
        });
      else emptyDetails.push({ label: shitLogDetailKeysAndLabel.label });
    });
    return {
      emptyDetails,
      filledOutDetails
    };
  });
  return (
    <StyledShiftLogDetailsDisplay>
      <SubTitle>Tasks Performed: </SubTitle>
      <FilledOutDetailsContainer>
        {filledOutDetails.map((shitLogDetailsKeyAndLabel) => (
          <FilledOutDetailContainer
            data-explorator-test-id={`shift_log_details_performed_task_${shitLogDetailsKeyAndLabel.label}`}
            key={shitLogDetailsKeyAndLabel.label}
          >
            <span className={`log-rows__icon ${getMaintenanceClass(true)}`}></span>
            <b>{shitLogDetailsKeyAndLabel.label}:</b>
            <FilledOutDetailsText className="log-rows__text">
              {shitLogDetailsKeyAndLabel.value}
            </FilledOutDetailsText>
          </FilledOutDetailContainer>
        ))}
      </FilledOutDetailsContainer>
      <SubTitle>Tasks not performed:</SubTitle>
      <TasksNotPerformedContainer>
        {emptyDetails.map((shitLogDetailsKeyAndLabel) => (
          <TaskNotPerformedLabel
            data-explorator-test-id={`shift_log_details_task_not_performed_${shitLogDetailsKeyAndLabel.label}`}
            key={shitLogDetailsKeyAndLabel.label}
          >
            {shitLogDetailsKeyAndLabel.label},{" "}
          </TaskNotPerformedLabel>
        ))}
      </TasksNotPerformedContainer>
    </StyledShiftLogDetailsDisplay>
  );
};

ShiftLogDetailsDisplay.propTypes = {
  shiftLog: PropTypes.object
};

const StyledShiftLogDetailsDisplay = styled.div`
  max-width: 20rem;
`;

const SubTitle = styled.h2`
  font-weight: bolder;
`;

const FilledOutDetailsContainer = styled.div`
  width: 100%;
`;

const FilledOutDetailContainer = styled.div`
  display: flex;
`;

const FilledOutDetailsText = styled.p`
  width: 100%:
  text-overflow: ellipsis;
`;

const TasksNotPerformedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TaskNotPerformedLabel = styled.div`
  width: fit-content;
  min-width: 0 !important;
  margin-left: 0.5rem;
`;
