import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import NativeElementListener from "./native_element_value_listener";
import UnifiedSearchAutocompleteComponent from "./unified_search_autocomplete_component";

const guestOption = { name: "The report is for guest", value: "GUEST", id: "GUEST" };
const unknownClientOption = { name: "Unknown Client", value: "", id: "" };

const IncidentReportClientSearchAutocomplete = (props) => {
  const {
    name,
    selected_client_name,
    selected_client_id,
    is_users_active = "all",
    is_for_guest: isForGuestSelected
  } = props;

  const originalSelectedClientName = selected_client_id
    ? selected_client_name
    : unknownClientOption.name;
  const [selectedClientName, setSelectedClientName] = useState(
    isForGuestSelected ? guestOption.name : originalSelectedClientName
  );
  const [selectedClientId, setSelectedClientId] = useState(
    isForGuestSelected ? guestOption.id : selected_client_id
  );
  const [allClients, setAllClients] = useState([]);
  const clientAutocompleteIdRef = useRef(null);
  const [isInitializing, setIsInitializing] = useState(true);

  // This is only used when listen_location_change_input_id is passed in.
  const [location, setLocation] = useState("");

  const loadClientsAutoCompleteList = (location = "", isInitializing = false) => {
    let url = "/clients/client_autocomplete_authorized_clients.json";
    if (location) {
      url += `?location=${location}`;
    }
    axios.get(url).then((response) => {
      let filteredClients = response.data.filter((client) => {
        if (is_users_active === "true") return !client.inactive;
        if (is_users_active === "false") return client.inactive;
        if (!is_users_active || is_users_active === "all") return true;

        return false;
      });

      setAllClients(filteredClients);
      if (isInitializing) return;

      // Update the UI only after the initial load
      if (!filteredClients.includes(selected_client_name)) {
        setSelectedClientName(originalSelectedClientName);
      }
    });
  };

  const options = useMemo(() => {
    const createClientOption = (client) => ({
      name: `${client.first_name} ${client.last_name}`,
      value: `${client.first_name} ${client.last_name}`,
      id: client.id
    });

    const clientsToDisplay = location
      ? allClients.filter((client) => client.location === location)
      : allClients;

    const clientOptions = clientsToDisplay.map(createClientOption);

    return clientOptions;
  }, [allClients, location]);

  const selectClient = useMemo(() => {
    if (!selectedClientId) {
      return null;
    }
    if (selectedClientId === guestOption.id) {
      return guestOption;
    }
    if (selectedClientId) {
      return allClients.find((allClient) => +allClient.id === +selectedClientId);
    }

    return null;
  }, [selectedClientId, allClients]);

  useEffect(() => {
    if (clientAutocompleteIdRef.current) {
      clientAutocompleteIdRef.current.value = selectClient ? selectClient.id : "";
    }
  }, [selectClient, selectedClientId]);

  useEffect(() => {
    if (!isInitializing) {
      setSelectedClientName(unknownClientOption.name);
      setSelectedClientId(unknownClientOption.id);
    }

    setTimeout(() => {
      loadClientsAutoCompleteList(location, isInitializing);
      setIsInitializing(false);
    });
  }, [location]);

  const handleClearClick = () => {
    setSelectedClientName("");
    setSelectedClientId("");
  };

  const handleOnSelectedOptionChange = (option) => {
    setSelectedClientName(option?.name || "");
    setSelectedClientId(option?.id || "");
  };

  useUpdateIncidentReportClientAttr({ selectClient });

  return (
    <div className="client-search-autocomplete">
      {props.listen_location_change_input_id && (
        <NativeElementListener
          listenId={props.listen_location_change_input_id}
          setFieldValue={setLocation}
        />
      )}
      <UnifiedSearchAutocompleteComponent
        input_field_name={name}
        selected_name={selectedClientName}
        options={options}
        hardCodedOptions={[guestOption, unknownClientOption]}
        handleClearClick={handleClearClick}
        handleOnSelectedOptionChange={handleOnSelectedOptionChange}
        inputFieldId="incident-report-client-search"
        inputFieldPlaceholder="Search for a client..."
      />
    </div>
  );
};

const useUpdateIncidentReportClientAttr = ({ selectClient }) => {
  useEffect(() => {
    if (selectClient) {
      $("#incident_report_client_first_name").val(selectClient.first_name);
      $("#incident_report_client_last_name").val(selectClient.last_name);
      $("#incident_report_client_dob").val(selectClient.dob);
      $("#incident_report_client_id").val(selectClient.id);
    } else {
      $("#incident_report_client_first_name").val("");
      $("#incident_report_client_last_name").val("");
      $("#incident_report_client_dob").val("");
      $("#incident_report_client_id").val("");
    }
  }, [selectClient]);
};

export default IncidentReportClientSearchAutocomplete;

IncidentReportClientSearchAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  selected_client_name: PropTypes.string,
  selected_client_id: PropTypes.string,
  is_users_active: PropTypes.string,
  listen_location_change_input_id: PropTypes.string,
  is_for_guest: PropTypes.bool
};
