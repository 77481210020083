import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function getHourFormat24(hour12, am_pm) {
  let hour24 = +hour12;

  if (am_pm.toUpperCase() === "PM" && +hour12 !== 12) {
    hour24 = +hour12 + 12;
  } else if (am_pm.toUpperCase() === "AM" && +hour12 === 12) {
    hour24 = 0;
  }

  return hour24;
}
function formatDateTimeByString(dateString) {
  return dayjs(dateString).tz("America/Toronto").format("YYYY-MM-DD HH:mm");
}
export { getHourFormat24, formatDateTimeByString };
