import React from "react";
import styled from "styled-components";

const GoToCurrentDayButton = () => {
  const onClick = () => {
    const currentDayElement = document.getElementById("current-day");
    if (currentDayElement) {
      currentDayElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <StyledGoToCurrentDayButton className="button is-primary" onClick={onClick}>
      Jump to today
    </StyledGoToCurrentDayButton>
  );
};

export default GoToCurrentDayButton;

const StyledGoToCurrentDayButton = styled.div`
  StyledGoToCurrentDayButton
`;
