import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useEffect } from "react";
import { getHourFormat24 } from "../timeUtils";
import { useShiftLogTimeObserver } from "../hooks/useShiftLogTimeObserver";

const hourOptions = Array.from(Array(24).keys()).map((hour) => {
  return hour.toString().padStart(2, "0");
});
const minuteOptions = Array.from(Array(60).keys()).map((minute) => {
  return minute.toString().padStart(2, "0");
});

const ShiftLogTimeSelector = (props) => {
  const { hour, minute, am_pm } = props;
  const dateTime = dayjs(
    `2023-01-01 ${getHourFormat24(hour, am_pm)}:${minute}:00`,
    "YYYY-MM-DD HH:mm:ss"
  );

  const [userSelectedHour, setUserSelectedHour] = useState(dateTime.format("HH"));
  const [userSelectedMinute, setUserSelectedMinute] = useState(dateTime.format("mm"));

  const hourInput = useRef(null);
  const minuteInput = useRef(null);
  const amPmInput = useRef(null);

  // NOTE: This hooks observes the time input fields when the user changes the view between DEFAULT and ORIGINAL on hospice page
  const { hourValue, minuteValue, amPmValue } = useShiftLogTimeObserver(
    hourInput,
    minuteInput,
    amPmInput
  );
  useEffect(() => {
    // NOTE: It will read those value from LocalStorage and set the state accordingly
    // when the user changes the view between DEFAULT and ORIGINAL on hospice page
    if (hourValue && minuteValue && amPmValue) {
      const dateTime = dayjs(
        `2023-01-01 ${getHourFormat24(hourValue, amPmValue)}:${minuteValue}:00`,
        "YYYY-MM-DD HH:mm:ss"
      );
      setUserSelectedHour(dateTime.format("HH"));
      setUserSelectedMinute(minuteValue);
    }
  }, [hourValue, minuteValue, amPmValue]);

  useEffect(() => {
    hourInput.current.value = getHourFormat12(userSelectedHour);
    minuteInput.current.value = userSelectedMinute;
    amPmInput.current.value = getAmPm(userSelectedHour);
  }, [userSelectedHour, userSelectedMinute]);

  return (
    <>
      <i className="far fa-clock"></i>
      <select
        value={userSelectedHour}
        onChange={(e) => {
          setUserSelectedHour(e.target.value);
        }}
      >
        {hourOptions.map((hour) => {
          return (
            <option key={hour} value={hour}>
              {hour}{" "}
            </option>
          );
        })}
      </select>
      <span>:</span>
      <select
        value={userSelectedMinute}
        onChange={(e) => {
          setUserSelectedMinute(e.target.value);
        }}
      >
        {minuteOptions.map((minute) => {
          return (
            <option key={minute} value={minute}>
              {minute}{" "}
            </option>
          );
        })}
      </select>

      <input type="hidden" name="shift_log[hour]" id="shift_log_hour" ref={hourInput}></input>
      <input type="hidden" name="shift_log[minute]" id="shift_log_minute" ref={minuteInput}></input>
      <input type="hidden" name="shift_log[am_pm]" id="shift_log_am_pm" ref={amPmInput}></input>
    </>
  );
};

ShiftLogTimeSelector.propTypes = {
  hour: PropTypes.string,
  minute: PropTypes.string,
  am_pm: PropTypes.string
};

export default ShiftLogTimeSelector;

function getHourFormat12(hour24) {
  let hour12 = hour24 % 12;

  if (hour12 === 0) {
    hour12 = 12;
  }

  return hour12;
}

function getAmPm(hour24) {
  if (+hour24 >= 12) {
    return "PM";
  } else {
    return "AM";
  }
}
