import React from "react";
import ActionDropdown from "./action_dropdown";
import PropTypes from "prop-types";

function ShiftLogsActionDropdown({
  showDownloadLocationsShiftLogsBtn,
  showDownloadAllShiftLogsBtn
}) {
  const actions = [
    {
      actionText: "Download Locations Shift Logs",
      actionFn: () => {
        document.querySelector("#download-locations-shift-logs").click();
      },
      show: showDownloadLocationsShiftLogsBtn
    },
    {
      actionText: "Download All Shift Logs",
      actionFn: () => {
        document.querySelector("#download-all-shift-logs").click();
      },
      show: showDownloadAllShiftLogsBtn
    }
  ];

  return (
    <ActionDropdown
      buttonText="Download ShiftLogs"
      actions={actions}
      buttonWidth="205px"
      dropdownWidth="255px"
    />
  );
}

ShiftLogsActionDropdown.propTypes = {
  showDownloadLocationsShiftLogsBtn: PropTypes.bool.isRequired,
  showDownloadAllShiftLogsBtn: PropTypes.bool.isRequired
};

export default ShiftLogsActionDropdown;
