import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

export const HOSPICE_SECTIONS_CHECKBOX_FIELDS = [
  { value: "Hygiene", label: "Hygiene" },
  { value: "Additional Hygiene", label: "Additional Hygiene" },
  { value: "Mobility", label: "Mobility" },
  { value: "Cognition", label: "Cognition" },
  { value: "Nutrition", label: "Nutrition" },
  { value: "Elimination", label: "Elimination" },
  { value: "Cardio/Resp", label: "Cardio/Resp" },
  { value: "Skin", label: "Skin" },
  { value: "General", label: "General" }
];
export const CHECKBOX_FIELDS = [
  { value: "shower", label: "Shower" },
  { value: "shave", label: "Shave" },
  { value: "hair_wash", label: "Hair Wash" },
  { value: "hair_cut", label: "Hair Cut" },
  { value: "peri_care", label: "Peri Care" },
  { value: "nail_care", label: "Nail Care" },
  { value: "bed_linen_change", label: "Bed Linen Change" },
  { value: "clothing_change", label: "Clothing Change" },
  { value: "medication", label: "Medication" },
  { value: "meal_prep", label: "Meal Prep" },
  { value: "laundry", label: "Laundry" },
  { value: "goods", label: "Goods Distribution" },
  { value: "bed_checks", label: "Bed Checks" },
  { value: "personal_care", label: "Personal Care" },
  { value: "room_cleaning", label: "Room Cleaning" },
  { value: "vitals", label: "Vitals" }
];

const initialState = {
  clientName: true,
  shiftNumber: true,
  location: true,
  loggedBy: true,
  whatHappened: true,

  isSeen: "All"
};
const DenseViewContext = createContext(null);

function reducer(state, action) {
  if (action.type === "selectAll") {
    return {
      ...state,
      ...CHECKBOX_FIELDS.reduce((acc, field) => ({ ...acc, [field.value]: true }), {}),
      ...HOSPICE_SECTIONS_CHECKBOX_FIELDS.reduce(
        (acc, field) => ({ ...acc, [field.value]: true }),
        {}
      )
    };
  }
  if (action.type === "deselectAll") {
    return {
      ...state,
      ...CHECKBOX_FIELDS.reduce((acc, field) => ({ ...acc, [field.value]: false }), {}),
      ...HOSPICE_SECTIONS_CHECKBOX_FIELDS.reduce(
        (acc, field) => ({ ...acc, [field.value]: false }),
        {}
      )
    };
  }
  if (action.type === "Hygiene") {
    const newState = { ...state };
    CHECKBOX_FIELDS.forEach((field) => {
      newState[field.value] = action.payload;
    });
    newState["Hygiene"] = action.payload;
    return newState;
  }

  return { ...state, [action.type]: action.payload };
}

function useDenseViewContext() {
  const context = useContext(DenseViewContext);
  return context;
}

function DenseViewContextProvider({ children, location }) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...HOSPICE_SECTIONS_CHECKBOX_FIELDS.reduce(
      (acc, field) => ({ ...acc, [field.value]: true }),
      {}
    ),
    ...CHECKBOX_FIELDS.reduce((acc, field) => ({ ...acc, [field.value]: true }), {})
  });

  return (
    <DenseViewContext.Provider value={{ state, dispatch, location }}>
      {children}
    </DenseViewContext.Provider>
  );
}
DenseViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string.isRequired
};

export default DenseViewContextProvider;
export { useDenseViewContext };
