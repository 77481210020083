import React from "react";
import ActionDropdown from "./action_dropdown";
import PropTypes from "prop-types";

function ToDoListActionDropdown({
  showDownloadLocationsToDoListBtn,
  showDownloadAllLocationsToDoListBtn
}) {
  const actions = [
    {
      actionText: "Download Locations To Do List",
      actionFn: () => {
        document.querySelector("#download-location-to-do-list").click();
      },
      show: showDownloadLocationsToDoListBtn
    },
    {
      actionText: "Download All Locations To Do Lists",
      actionFn: () => {
        document.querySelector("#download-all-locations-to-do-list").click();
      },
      show: showDownloadAllLocationsToDoListBtn
    }
  ];

  return (
    <ActionDropdown
      buttonText="Download To Do List"
      actions={actions}
      buttonWidth="205px"
      dropdownWidth="255px"
    />
  );
}

ToDoListActionDropdown.propTypes = {
  showDownloadLocationsToDoListBtn: PropTypes.bool.isRequired,
  showDownloadAllLocationsToDoListBtn: PropTypes.bool.isRequired
};

export default ToDoListActionDropdown;
