import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const RepeatDates = ({ is_repeat, start_date, end_date }) => {
  const [isRepeat, setIsRepeat] = useState(is_repeat);
  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);

  useEffect(() => {
    document.getElementById("is_repeat").value = isRepeat;
    document.getElementById("start_date").value = startDate;
    document.getElementById("end_date").value = endDate;
  }, [isRepeat, startDate, endDate]);

  return (
    <div className="nurse-recap-repeat-selection-block">
      <div>
        <b>
          <label>Repeat?</label>{" "}
        </b>
        <input
          className="checkbox"
          type="checkbox"
          checked={isRepeat}
          onChange={(e) => {
            setIsRepeat(e.target.checked);
          }}
        />
      </div>
      {isRepeat && (
        <div className="date-range">
          <div>
            <label className="label">Start Date</label>
            <input
              type="date"
              name="nurse_recap[start_date]"
              className="input"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label className="label">End Date</label>
            <input
              type="date"
              name="nurse_recap[end_date]"
              className="input"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
RepeatDates.propTypes = {
  is_repeat: PropTypes.bool,
  start_date: PropTypes.string,
  end_date: PropTypes.string
};

export default RepeatDates;
