/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";
import AddRemoveStock from "./add_remove_stock";
import InventoriesTable from "./inventories_table";

const InventoryView = ({ inventories, is_showing_deleted_records }) => {
  return (
    <div className="inventory_view_component">
      <InventoriesTable data={inventories} />
      {!is_showing_deleted_records &&
        inventories.map((inventory) => {
          return <AddRemoveStock key={`inventory_${inventory.id}`} inventory={inventory} />;
        })}
    </div>
  );
};
InventoryView.propTypes = {
  inventories: PropTypes.array,
  is_showing_deleted_records: PropTypes.bool
};

export default InventoryView;
