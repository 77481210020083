import React from "react";
import PropTypes from "prop-types";

const PourAndDispenseModalCloseIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className="pour_and_dispense_close_icon_container">
      <div className="fa fa-times" />
    </div>
  );
};

PourAndDispenseModalCloseIcon.propTypes = {
  onClick: PropTypes.func
};

export default PourAndDispenseModalCloseIcon;
