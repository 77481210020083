/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";

import { TODOListActionButton } from "./todo_list_action_button";

const TODOListActionButtonContainer = ({ todoListData, selectedDate }) => {
  const toDoListDate = selectedDate ? selectedDate : todoListData.to_do_date;
  return (
    <div className="to-do-list-action-button-container">
      <a
        className="button is-warning to-do-list-edit-button"
        href={`/to_do_lists/${todoListData.id}/edit`}
        data-explorator_test_id={`to_do_list_edit_button_${todoListData.to_do_list_title}`}
        explorator_test_id={`to_do_list_edit_button_${todoListData.to_do_list_title}`}
      >
        <i className="fas fa-edit" />
      </a>
      <TODOListActionButton
        time={todoListData.to_do_list_time}
        todoListDate={toDoListDate}
        todoListId={todoListData.id}
        shiftNumber={todoListData.shift_num}
        isInComplete={todoListData.is_incomplete}
        toDoListTitle={todoListData.to_do_list_title}
        type="complete"
      />
      <TODOListActionButton
        time={todoListData.to_do_list_time}
        todoListDate={toDoListDate}
        todoListId={todoListData.id}
        shiftNumber={todoListData.shift_num}
        isInComplete={todoListData.is_incomplete}
        toDoListTitle={todoListData.to_do_list_title}
        type="incomplete"
      />
      <TODOListActionButton
        time={todoListData.to_do_list_time}
        todoListDate={toDoListDate}
        todoListId={todoListData.id}
        shiftNumber={todoListData.shift_num}
        isInComplete={todoListData.is_incomplete}
        toDoListTitle={todoListData.to_do_list_title}
        type="skip"
      />
      <TODOListActionButton
        time={todoListData.to_do_list_time}
        todoListDate={toDoListDate}
        todoListId={todoListData.id}
        shiftNumber={todoListData.shift_num}
        isInComplete={todoListData.is_incomplete}
        toDoListTitle={todoListData.to_do_list_title}
        type="inprogress"
      />
    </div>
  );
};

TODOListActionButtonContainer.propTypes = {
  todoListData: PropTypes.object,
  selectedDate: PropTypes.string
};

export default TODOListActionButtonContainer;
