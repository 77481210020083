import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getClientLinks } from "./nurse_log_editor";

export function ClientList({ isClientMentioned, taggedClients }) {
  // Control the state of "Save Log" button
  const saveLogCreateTask = document.querySelector("#save-log-create-task");
  useEffect(() => {
    if (isClientMentioned) {
      document.querySelector("#save-log").setAttribute("disabled", true);
      if (saveLogCreateTask) {
        saveLogCreateTask.setAttribute("disabled", true);
      }
    } else {
      document.querySelector("#save-log").removeAttribute("disabled");
      if (saveLogCreateTask) {
        saveLogCreateTask.removeAttribute("disabled");
      }
    }
  }, [isClientMentioned]);

  return (
    <div id="client-list">
      {/* TO BE LINKED CLIENT LIST */}
      <div className="client-to-be-linked-list">
        {taggedClients.length > 0 && (
          <label className="label">{"The nurse log will be linked to the following Clients"}</label>
        )}
        <ul>
          <li className={`${taggedClients.length > 0 ? "hidden" : ""}`}>
            Tag clients or finish their note by typing <code>@</code>
          </li>
          {/* TO BE LINKED CLIENT LIST */}
          {taggedClients.map((client, index) => {
            const clientLink = getClientLinks([client])[0];
            return (
              <li
                key={`${clientLink} ${index}`}
                dangerouslySetInnerHTML={{
                  __html: clientLink
                }}
              ></li>
            );
          })}
        </ul>
      </div>
      {/* Display error if an [END] tag is missing */}
      <div className={`error ${isClientMentioned ? "" : "hidden"}`}>
        Error: The <code>[END]</code> tag is missing. Please type <code>@</code> to insert the{" "}
        <code>[END]</code> tag at the correct location.
      </div>
    </div>
  );
}
ClientList.propTypes = {
  isClientMentioned: PropTypes.bool,
  taggedClients: PropTypes.array.isRequired
};
