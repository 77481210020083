import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getCorrectShift } from "./utils";

dayjs.extend(isBetween);

function ShiftNumberDisplay({ time_field_id }) {
  const [selectedTime, setSelectedTime] = useState("");
  const [shift, setShift] = useState("");

  useEffect(() => {
    const timeField = document.getElementById(time_field_id);
    const handleTimeChange = (event) => {
      setSelectedTime(event.target.value);
    };

    if (timeField) {
      timeField.addEventListener("change", handleTimeChange);
    }

    return () => {
      if (timeField) {
        timeField.removeEventListener("change", handleTimeChange);
      }
    };
  }, [time_field_id]);

  useEffect(() => {
    if (selectedTime !== "") {
      const [hour, minute] = selectedTime.split(":");
      setShift(getCorrectShift(hour, minute));
    }
  }, [selectedTime]);

  return (
    <div className="flex">
      <p id="shift_number_display_react_component">{shift}</p>
    </div>
  );
}

ShiftNumberDisplay.propTypes = {
  time_field_id: PropTypes.string
};

export default ShiftNumberDisplay;
