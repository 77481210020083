import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PourAndDispenseInputModal from "./pour_and_dispense_input_modal";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getCannabisDispenseDisplay,
  getAlcoholDisplayFromTime,
  getEntryFromTime,
  formatMinuteAndHour,
  formatTimeToJSDatObject,
  PossibleTimesToDispense
} from "./pour_and_dispense_utils";
import { Box, createTheme, TextField, ThemeProvider, Typography } from "@mui/material";
import { useEffect } from "react";
import PourAndDispenseNotesModal from "./pour_and_dispense_notes_modal";
import { useMemo } from "react";

const theme = createTheme({
  palette: {
    mode: "dark"
  }
});

export const dateToString = (date, addMonth) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + (addMonth ? 1 : 0)).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const stringToDate = (value, removeMonth) => {
  const newDateValues = value.split("-");
  return new Date(
    parseInt(newDateValues[0]),
    parseInt(newDateValues[1]) + (removeMonth ? -1 : 0),
    parseInt(newDateValues[2])
  );
};

const PourAndDispenseTable = (props) => {
  const { clients: propsClients } = props;
  const [timeToEdit, setTimeToEdit] = useState(undefined);
  const [clientToEdit, setClientToEdit] = useState(undefined);
  const [clients, setClients] = useState(propsClients);
  const [createdEntry, setCreatedEntry] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [editNote, setEditNote] = useState(false);

  const [clientsToShow, setClientsToShow] = useState([]);

  const hasClientsToShow = useMemo(() => clientsToShow.length !== 0, [clientsToShow]);

  const day = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const date = searchParams.get("date");
    if (!date) return dateToString(new Date(), true);
    return date;
  }, []);

  useEffect(() => {
    if (search === undefined || search.length === 0) {
      setClientsToShow(clients);
      return;
    }
    setClientsToShow(
      clients.filter((client) =>
        (client.first_name + " " + client.last_name).toLowerCase().match(search.toLowerCase())
      )
    );
  }, [search, clients]);

  const resetVariables = () => {
    setTimeToEdit(undefined);
    setClientToEdit(undefined);
    setCreatedEntry(undefined);
  };

  const deleteAlcoholEntry = async () => {
    const pourTime = formatTimeToJSDatObject(timeToEdit, day);
    try {
      const response = await axios.delete(
        "/pour_and_dispense/alcohol_given_time_entry_delete.json",
        {
          data: { client_id: clientToEdit, pour_time: pourTime }
        }
      );
      if (response.status !== 200) throw "";
      setClients(
        clients.map((client) => {
          if (client.id !== clientToEdit) return client;
          return {
            ...client,
            alcohol_given_time_entries: client.alcohol_given_time_entries.filter((entry) => {
              if (new Date(entry.pour_time).getTime() !== pourTime.getTime()) {
                return true;
              }
            })
          };
        })
      );
      resetVariables();
    } catch (err) {
      console.log(err);
      toastr.error("Something went wrong");
      resetVariables();
      return;
    }
    return;
  };

  const onFinishAlcoholInput = async (type, amount, isDeleting) => {
    const pourTime = formatTimeToJSDatObject(timeToEdit, day);
    if (isDeleting) {
      deleteAlcoholEntry(type, amount);
      return;
    }

    if (type === undefined || amount === undefined) {
      resetVariables();
      return;
    }
    try {
      const response = await axios.post("/pour_and_dispense/alcohol_given_time_entry_create.json", {
        client_id: clientToEdit,
        pour_time: pourTime,
        pour_type: type,
        volume: amount
      });
      if (response.status !== 200) throw "";
      // updating local state
      setClients(
        clients.map((client) => {
          if (client.id !== clientToEdit) return client;
          const newOrUpdatedInformation = {
            client_id: clientToEdit,
            pour_time: pourTime,
            pour_type: type,
            volume: amount
          };
          if (
            !client.alcohol_given_time_entries.find(
              (entry) => new Date(entry.pour_time).getTime() === pourTime.getTime()
            )
          ) {
            return {
              ...client,
              alcohol_given_time_entries: [
                ...client.alcohol_given_time_entries,
                newOrUpdatedInformation
              ]
            };
          }
          return {
            ...client,
            alcohol_given_time_entries: client.alcohol_given_time_entries.map((entry) => {
              if (new Date(entry.pour_time).getTime() === pourTime.getTime()) {
                return newOrUpdatedInformation;
              }
              return entry;
            })
          };
        })
      );
      resetVariables();
    } catch (err) {
      console.log(err);
      toastr.error("Something went wrong");
      resetVariables();
    }
  };

  const onCannabisInput = async (time, client_to_edit) => {
    try {
      const dispenseTime = formatTimeToJSDatObject(time, day);
      const response = await axios.post(
        "/pour_and_dispense/cannabis_given_time_entry_create.json",
        {
          client_id: client_to_edit,
          dispense_time: dispenseTime
        }
      );
      if (response.status !== 200) throw "";
      // updating local state
      setClients(
        clients.map((client) => {
          if (client.id !== client_to_edit) return client;
          const newOrUpdatedInformation = {
            client_id: client_to_edit,
            dispense_time: dispenseTime
          };
          if (
            !client.cannabis_dispense_time_entries.find(
              (entry) => new Date(entry.dispense_time).getTime() === dispenseTime.getTime()
            )
          ) {
            return {
              ...client,
              cannabis_dispense_time_entries: [
                ...client.cannabis_dispense_time_entries,
                newOrUpdatedInformation
              ]
            };
          }
          return {
            ...client,
            cannabis_dispense_time_entries: client.cannabis_dispense_time_entries.filter(
              (entry) => {
                if (new Date(entry.dispense_time).getTime() !== dispenseTime.getTime()) {
                  return true;
                }
              }
            )
          };
        })
      );
      resetVariables();
    } catch (err) {
      console.log(err);
      toastr.error("Something went wrong");
      resetVariables();
    }
  };

  const getBackgroundColorForSubstanceInput = (value, isAlcohol, disabled) => {
    if (disabled) return "darkgrey";
    if (value !== undefined) return "lightgrey";
    if (isAlcohol) return "#fefed0";
    return "#d6fefd";
  };

  const onFinishEditingNotes = async (newNoteState) => {
    try {
      if (newNoteState === undefined) {
        setClientToEdit();
        setEditNote(false);
        return;
      }
      const response = await axios.post("/pour_and_dispense/pour_and_dispense_edit_notes.json", {
        client_id: clientToEdit,
        pour_and_dispense_note: newNoteState
      });
      if (response.status !== 200) throw "";
      setClientToEdit();
      setEditNote(false);
      setClients(
        clients.map((client) => {
          if (client.id !== clientToEdit) return client;
          return {
            ...client,
            pour_and_dispense_note: newNoteState
          };
        })
      );
    } catch (err) {
      console.log(err);
      toastr.error("Something went wrong saving the note.");
    }
  };

  const onDateChange = (event) => {
    const newUrl = `${window.location.origin}${window.location.pathname}?date=${event.target.value}`;
    window.location.href = newUrl;
  };

  const onDateDayChange = (previous) => {
    const currentDayDate = new Date(stringToDate(day, true));
    const newDate = new Date(
      currentDayDate.setDate(currentDayDate.getDate() + (previous ? -1 : 1))
    ); // Add the days to the current date
    const newUrl = `${window.location.origin}${window.location.pathname}?date=${dateToString(
      newDate,
      true
    )}`;
    window.location.href = newUrl;
  };

  return (
    <div className="table-container">
      <div className="pour-and-dispense-table-inputs">
        <ThemeProvider theme={theme}>
          <TextField
            fullWidth
            sx={{ marginBottom: "1rem", width: "20rem" }}
            label="Search"
            variant={"filled"}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </ThemeProvider>
        <div className="pour-and-dispense-previous-and-next-day-buttons-container">
          <div
            className="pour-and-dispense-change-day-buttons previous-day-button"
            onClick={() => onDateDayChange(true)}
          >
            « Previous Day
          </div>
          <input
            value={day}
            onChange={onDateChange}
            type="date"
            className="pour-and-dispense-day-picker"
          />
          <div
            onClick={() => onDateDayChange(false)}
            className="pour-and-dispense-change-day-buttons next-day-button"
          >
            Next Day »
          </div>
        </div>
      </div>
      <PourAndDispenseNotesModal
        isOpen={editNote}
        onComplete={onFinishEditingNotes}
        currentValue={editNote === true ? "" : editNote}
      />
      <PourAndDispenseInputModal
        isOpen={!!timeToEdit}
        time={formatMinuteAndHour(timeToEdit)}
        onComplete={onFinishAlcoholInput}
        canDelete={!!createdEntry}
      />
      {clientsToShow.length === 0 && (
        <Box
          backgroundColor="white"
          sx={{ width: "100%", height: "400px", display: "flex", justifyContent: "center" }}
        >
          <Typography sx={{ textAlign: "center", margin: "auto" }}>No users with filter</Typography>
        </Box>
      )}
      {clientsToShow.length != 0 && (
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            sx={{
              maxHeight: 400,
              borderCollapse: "collapse",
              position: "relative"
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Notes</TableCell>

                {PossibleTimesToDispense.map((time, index) => (
                  <TableCell key={`possible times to dispense head ${index} ${time}`}>
                    {hasClientsToShow && <div className="">{formatMinuteAndHour(time)}</div>}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsToShow.map((row, index) => (
                <TableRow
                  key={`${row.name} ${index}`}
                  sx={{
                    borderBottom: "#26547c 2px solid !important"
                  }}
                >
                  <TableCell component="th" scope="row">
                    {`${row.first_name} ${row.last_name}`}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setClientToEdit(row.id);
                      setEditNote(row.pour_and_dispense_note ? row.pour_and_dispense_note : true);
                    }}
                    sx={{ cursor: "pointer" }}
                    component="th"
                    scope="row"
                  >
                    {row.pour_and_dispense_note}
                  </TableCell>
                  {PossibleTimesToDispense.map((time, index) => (
                    <TableCell
                      key={`possible times to dispense body ${index} ${time} ${row.name}`}
                      padding="none"
                    >
                      <div className="alcohol_cannabis_input_container">
                        <div
                          onClick={() => {
                            setTimeToEdit(time);
                            setClientToEdit(row.id);
                            setCreatedEntry(
                              !!getEntryFromTime(time, true, row.alcohol_given_time_entries)
                            );
                          }}
                          className="alcohol_cannabis_button_base_style alcohol_button_style"
                          style={{
                            backgroundColor: getBackgroundColorForSubstanceInput(
                              getEntryFromTime(time, true, row.alcohol_given_time_entries),
                              true
                            )
                          }}
                        >
                          {getAlcoholDisplayFromTime(time, row.alcohol_given_time_entries)}
                        </div>
                        <div
                          onClick={() => {
                            if (!time.disableCannabis) onCannabisInput(time, row.id);
                          }}
                          className="alcohol_cannabis_button_base_style"
                          style={{
                            backgroundColor: getBackgroundColorForSubstanceInput(
                              getEntryFromTime(time, false, row.cannabis_dispense_time_entries),
                              false,
                              time.disableCannabis
                            ),
                            cursor: time.disableCannabis && "default"
                          }}
                        >
                          {!time.disableCannabis &&
                            getCannabisDispenseDisplay(time, row.cannabis_dispense_time_entries)}
                        </div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            {!hasClientsToShow && (
              <TableBody>
                <TableRow>
                  <TableCell
                    className="pour_and_dispense_table_no_result_container"
                    component="th"
                    scope="row"
                  >
                    No result
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
PourAndDispenseTable.propTypes = {
  clients: PropTypes.array.isRequired
};

export default PourAndDispenseTable;
