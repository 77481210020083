import React from "react";
import PropTypes from "prop-types";

const NameField = ({ name, index, onNameChange, onRemove, onKeyDown }) => {
  return (
    <div className="control is-flex name-field">
      <input
        id="work_shift_log_names"
        className="input"
        type="text"
        placeholder="Enter name"
        name={`work_shift_log[names][]`}
        value={name}
        onChange={(e) => onNameChange(index, e.target.value)}
        onKeyDown={onKeyDown}
        required
      />
      <button
        type="button"
        className="button is-outline"
        disabled={index === 0}
        onClick={() => onRemove(index)}
      >
        Remove
      </button>
    </div>
  );
};

NameField.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};

export default NameField;
