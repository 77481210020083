import { useEffect } from "react";
import PropTypes from "prop-types";

const NativeElementListener = ({ listenId, setFieldValue }) => {
  useEffect(() => {
    const el = document.getElementById(listenId);

    const handleElementChange = (event) => {
      const value = event.target.value;
      setFieldValue(value);
    };

    if (el) {
      if (el.value) {
        setFieldValue(el.value);
      }
      el.addEventListener("change", handleElementChange);
    }

    return () => {
      if (el) {
        el.removeEventListener("change", handleElementChange);
      }
    };
  }, []);

  return null;
};
NativeElementListener.propTypes = {
  listenId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default NativeElementListener;
