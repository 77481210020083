/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";
import { createContext } from "react";
import WarningIcon from "@mui/icons-material/Warning";

const UserInitialContext = createContext();
const ToDoListMarkCompletedPopup = ({ uniqueId, requireReason }) => {
  const [open, setOpen] = useState(false);
  const form = document.querySelector(`form[data-id="complete-to-do-form-with-${uniqueId}"]`);
  const userInitialHiddenInput = form.querySelector(
    `#complete-to-do-initial-field-with-${uniqueId}`
  );
  const reasonHiddenInput = form.querySelector(`#complete-to-do-reason-field-with-${uniqueId}`);
  const formSubmitBtn = form.querySelector(`button[type="submit"]`);

  const popupFormId = `user-initial-popup-container-${uniqueId}`;

  const submitEventHandler = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    formSubmitBtn.addEventListener("click", submitEventHandler);
    return () => {
      formSubmitBtn.removeEventListener("click", submitEventHandler);
    };
  }, []);
  const handleClose = () => {
    userInitialHiddenInput.value = "";
    reasonHiddenInput.value = "";
    setOpen(false);
  };

  return (
    <UserInitialContext.Provider
      value={{
        uniqueId,
        requireReason,
        popupFormId,
        form,
        userInitialHiddenInput,
        reasonHiddenInput,
        formSubmitBtn
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={`to-do-completed-popup ${requireReason ? "warning" : ""}`}>
          <UserInitialForm />
        </DialogContent>
      </Dialog>
    </UserInitialContext.Provider>
  );
};

ToDoListMarkCompletedPopup.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  requireReason: PropTypes.bool
};
export default ToDoListMarkCompletedPopup;

const UserInitialForm = () => {
  const {
    requireReason,
    popupFormId,
    form,
    userInitialHiddenInput,
    reasonHiddenInput,
    formSubmitBtn
  } = useContext(UserInitialContext);
  const [userInitial, setUserInitial] = useState("");
  const [reason, setReason] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    formSubmitBtn.click();
    const $form = $(`form#${popupFormId}`);
    const isValid = $form.valid();
    if (!isValid) {
      return;
    }
    userInitialHiddenInput.value = userInitial;
    if (requireReason) {
      reasonHiddenInput.value = reason;
    }
    form.submit();
  };

  useEffect(() => {
    const $form = $(`form#${popupFormId}`);
    $form.validate({
      rules: {
        userInitialInput: {
          required: true
        },
        reason: {
          required: requireReason
        }
      }
    });
    return () => {
      $form.off("submit");
      $form.validate().destroy();
    };
  }, []);
  useEffect(() => {
    userInitialHiddenInput.value = userInitial;
  }, [userInitial]);
  return (
    <form className="user-initial-popup-container" id={popupFormId}>
      {requireReason && (
        <div className="warning-message-container">
          <WarningIcon className="warning-icon" />
          <p>
            {"Caution: You're about to mark a task as complete that is scheduled for the future."}
          </p>
        </div>
      )}
      <p>Please enter your initial</p>
      <input
        type="text"
        className="input"
        name="userInitialInput"
        value={userInitial}
        onChange={(e) => setUserInitial(e.target.value)}
        explorator_test_id={"to_do_list_mark_complete_initials_input"}
      />
      {requireReason && (
        <>
          <p>Reason</p>
          <textarea
            className="textarea"
            name="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            explorator_test_id={"to_do_list_mark_complete_reason_input"}
          ></textarea>
        </>
      )}
      <button
        className="button is-primary submit-to-do"
        onClick={handleSubmit}
        disabled={!userInitial}
        explorator_test_id={"to_do_list_mark_complete_initials_input_submit_button"}
      >
        Confirm
      </button>
    </form>
  );
};
