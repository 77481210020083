import React from "react";
import PropTypes from "prop-types";
import { useDenseViewContext } from "./DenseViewContext";
import { getMaintenanceClass } from "./utility_functions";

const ShiftLogContainerHospice = ({ shiftLog }) => {
  const { state } = useDenseViewContext();
  // TODO refactor these to use an array of the ones you want to display
  return (
    <>
      {state["Hygiene"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Hygiene</h3>
          </summary>
          <div className="log-rows">
            {state.meal_prep && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.meal_prep)}`}
                ></span>
                <b>Meal Prep:</b>
                <div className="log-rows__text">{shiftLog.meal_prep_text}</div>
              </>
            )}

            {state.laundry && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.laundry)}`}></span>
                <b>Laundry:</b>
                <div className="log-rows__text">{shiftLog.laundry_text}</div>
              </>
            )}

            {state.goods && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.goods)}`}></span>
                <b>Goods Distribution:</b>
                <div className="log-rows__text">{shiftLog.goods_text}</div>
              </>
            )}

            {state.bed_checks && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_checks)}`}
                ></span>
                <b>Bed Checks:</b>
                <div className="log-rows__text">{shiftLog.bed_checks_text}</div>
              </>
            )}

            {state.personal_care && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.personal_care)}`}
                ></span>
                <b>Personal Care:</b>
                <div className="log-rows__text">{shiftLog.personal_care_text}</div>
              </>
            )}

            {state.room_cleaning && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.room_cleaning)}`}
                ></span>
                <b>Room Cleaning:</b>
                <div className="log-rows__text">{shiftLog.room_cleaning_text}</div>
              </>
            )}

            {state.shower && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shower)}`}></span>
                <b>Shower:</b>
                <div className="log-rows__text">{shiftLog.shower_text}</div>
              </>
            )}

            {state.shave && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shave)}`}></span>
                <b>Shave:</b>
                <div className="log-rows__text">{shiftLog.shave_text}</div>
              </>
            )}

            {state.hair_wash && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_wash)}`}
                ></span>
                <b>Hair Wash:</b>
                <div className="log-rows__text">{shiftLog.hair_wash_text}</div>
              </>
            )}

            {state.hair_cut && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_cut)}`}></span>
                <b>Hair Cut:</b>
                <div className="log-rows__text">{shiftLog.hair_cut_text}</div>
              </>
            )}

            {state.peri_care && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.peri_care)}`}
                ></span>
                <b>Peri Care:</b>
                <div className="log-rows__text">{shiftLog.peri_care_text}</div>
              </>
            )}

            {state.nail_care && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.nail_care)}`}
                ></span>
                <b>Nail Care:</b>
                <div className="log-rows__text">{shiftLog.nail_care_text}</div>
              </>
            )}

            {state.bed_linen_change && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_linen_change)}`}
                ></span>
                <b>Bed Linen Change:</b>
                <div className="log-rows__text">{shiftLog.bed_linen_change_text}</div>
              </>
            )}

            {state.clothing_change && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.clothing_change)}`}
                ></span>
                <b>Clothing Change:</b>
                <div className="log-rows__text">{shiftLog.clothing_change_text}</div>
              </>
            )}

            {state.vitals && (
              <>
                <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.vitals)}`}></span>
                <b>Vitals:</b>
                <div className="log-rows__text">{shiftLog.vitals_text}</div>
              </>
            )}

            {state.medication && (
              <>
                <span
                  className={`log-rows__icon ${getMaintenanceClass(shiftLog.medication)}`}
                ></span>
                <b>Prescribed Meds Given?:</b>
                <div className="log-rows__text">{shiftLog.medication_text}</div>
              </>
            )}
          </div>
        </details>
      )}

      {state["Additional Hygiene"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Additional Hygiene</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bedbath)}`}
            ></span>
            <b>Bedbath:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_partial_bedbath)}`}
            ></span>
            <b>Partial Bedbath:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_shower)}`}
            ></span>
            <b>Shower:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_shave)}`}
            ></span>
            <b>Shave:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_hair_wash)}`}
            ></span>
            <b>Hair Wash:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_peri_care)}`}
            ></span>
            <b>Peri-Care:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_mouth_care)}`}
            ></span>
            <b>Mouth-Care:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_repositioned)}`}
            ></span>
            <b>Repositioned:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_pa_checked)}`}
            ></span>
            <b>P.A. Checked:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_nail_care)}`}
            ></span>
            <b>Nail Care:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_other_hygiene)}`}
            ></span>
            <b>Other Hygiene:</b>
            <div className="log-rows__text">{shiftLog.hospice_other_hygiene_text}</div>
          </div>
        </details>
      )}

      {state["Mobility"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Mobility</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bed_bound)}`}
            ></span>
            <b>Bed Bound:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_assist_x1)}`}
            ></span>
            <b>Assist x1:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_assist_x2)}`}
            ></span>
            <b>Assist x2:</b>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_lift)}`}></span>
            <b>Lift:</b>
          </div>
        </details>
      )}

      {state["Cognition"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Cognition</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_alert)}`}
            ></span>
            <b>Alert:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_drowsy)}`}
            ></span>
            <b>Drowsy:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_somnolent)}`}
            ></span>
            <b>Somnolent:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_semi_coma)}`}
            ></span>
            <b>Semi-coma:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_confusion)}`}
            ></span>
            <b>Confusion:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_hallucination)}`}
            ></span>
            <b>Hallucination:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_agitation)}`}
            ></span>
            <b>Agitation:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_anxiety)}`}
            ></span>
            <b>Anxiety:</b>
          </div>
        </details>
      )}

      {state["Nutrition"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Nutrition</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_dat_good_poor)}`}
            ></span>
            <b>DAT, Good, Poor:</b>
            <div className="log-rows__text">{shiftLog.hospice_dat_good_poor_text}</div>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_soft)}`}></span>
            <b>Soft:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_fluids)}`}
            ></span>
            <b>Fluids:</b>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_sips)}`}></span>
            <b>Sips:</b>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_npo)}`}></span>
            <b>NPO:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_nausea_vomiting)}`}
            ></span>
            <b>Nausea/Vomiting:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_dysphagia)}`}
            ></span>
            <b>Dysphagia:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_enteral_feed)}`}
            ></span>
            <b>Enteral Feed:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_food_intake)}`}
            ></span>
            <b>Food intake:</b>
          </div>
        </details>
      )}

      {state["Elimination"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Elimination</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bladder_void)}`}
            ></span>
            <b>Bladder void:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_continent_urine)}`}
            ></span>
            <b>Continent Urine:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(
                shiftLog.hospice_foley_neph_amount
              )}`}
            ></span>
            <b>Foley/Neph amount:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bowel_void)}`}
            ></span>
            <b>Bowel void:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_continent_feces)}`}
            ></span>
            <b>Continent Feces:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bm_size)}`}
            ></span>
            <b>BM Small/Med/Large:</b>
            <div className="log-rows__text">{shiftLog.hospice_bm_size_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bm_type)}`}
            ></span>
            <b>BM Normal/Loose/Diarrhea/Constipated:</b>
            <div className="log-rows__text">{shiftLog.hospice_bm_type_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(
                shiftLog.hospice_urine_foley_amount
              )}`}
            ></span>
            <b>Urine Foley Amount:</b>
            <div className="log-rows__text">{shiftLog.hospice_urine_foley_amount_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_commode)}`}
            ></span>
            <b>Commode:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_urinal)}`}
            ></span>
            <b>Urinal:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_attends)}`}
            ></span>
            <b>Attends:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_colostomy)}`}
            ></span>
            <b>Colostomy:</b>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_ileo)}`}></span>
            <b>Ileo:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(
                shiftLog.hospice_colostomy_emptied
              )}`}
            ></span>
            <b>Colostomy emptied:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(
                shiftLog.hospice_colostomy_changed
              )}`}
            ></span>
            <b>Colostomy changed:</b>
          </div>
        </details>
      )}

      {state["Cardio/Resp"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Cardio/Resp</h3>
          </summary>
          <div className="log-rows">
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_oxygen)}`}
            ></span>
            <b>Oxygen:</b>

            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_rate)}`}></span>
            <b>Rate:</b>
            <div className="log-rows__text">{shiftLog.hospice_rate_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_o2_sat)}`}
            ></span>
            <b>O2 Sat:</b>
            <div className="log-rows__text">{shiftLog.hospice_o2_sat_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_dyspnea)}`}
            ></span>
            <b>Dyspnea:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_congestion)}`}
            ></span>
            <b>Congestion:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_cough)}`}
            ></span>
            <b>Cough:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_hemoptosis)}`}
            ></span>
            <b>Hemoptosis:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_pleurex)}`}
            ></span>
            <b>Pleurex:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_pulse)}`}
            ></span>
            <b>Pulse:</b>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_resp_rate)}`}
            ></span>
            <b>Resp Rate:</b>
            <div className="log-rows__text">{shiftLog.hospice_resp_rate_text}</div>

            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_apnea)}`}
            ></span>
            <b>Apnea:</b>
          </div>
        </details>
      )}

      {state["Skin"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>Skin</h3>
          </summary>
          <div className="log-rows">
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_rash)}`}></span>
            <b>Rash:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_puritis)}`}
            ></span>
            <b>Puritis:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bruising)}`}
            ></span>
            <b>Bruising:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_edema)}`}
            ></span>
            <b>Edema (A, L, Abd.):</b>
            <div className="log-rows__text">{shiftLog.hospice_edema_text}</div>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_wound)}`}
            ></span>
            <b>Wound, See tx. sh.:</b>
            <div className="log-rows__text">{shiftLog.hospice_wound_text}</div>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_mottling)}`}
            ></span>
            <b>Mottling:</b>
          </div>
        </details>
      )}

      {state["General"] && (
        <details open>
          <summary className="log-rows-category">
            <h3>General</h3>
          </summary>
          <div className="log-rows">
            {" "}
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_sleep)}`}
            ></span>{" "}
            <b>Sleep:</b>{" "}
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_teaching)}`}
            ></span>
            <b>Teaching:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(
                shiftLog.hospice_emotional_support
              )}`}
            ></span>
            <b>Emotional Support:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_bed_alarm)}`}
            ></span>
            <b>Bed Alarm:</b>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.hospice_other)}`}
            ></span>
            <b>Other:</b>
            <div className="log-rows__text">{shiftLog.hospice_other_text}</div>
          </div>
        </details>
      )}
    </>
  );
};

export default ShiftLogContainerHospice;
ShiftLogContainerHospice.propTypes = {
  shiftLog: PropTypes.object.isRequired
};
