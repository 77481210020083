import React, { Fragment } from "react";
import PropTypes from "prop-types";

const ShiftLogFormHospice = ({ formValues, setFormValues, hospiceView }) => {
  if (!formValues) return null;
  const hospiceFields = getHospiceFields(hospiceView);
  if (hospiceView === "original") {
    return (
      <div className="original_view">
        <div className="original_view_col1">
          {hospiceFields
            .filter((field) => field.col === 1)
            .map((hospiceField, index) => (
              <Fragment key={`${index} hospiceField`}>
                <div className="log-rows-category">
                  <h3>{hospiceField.title}</h3>
                </div>

                <div className="log-rows-form">
                  {hospiceField.rows.map((row, rowIndex) => (
                    <Fragment key={`${index} hospiceField ${rowIndex} row`}>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          name={row.checkbox_field_key}
                          className="checkbox"
                          checked={formValues[row.checkbox_field_key]}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [row.checkbox_field_key]: e.target.checked
                            });
                          }}
                        />
                        <RowLabel label={row.label} />
                      </label>
                      {row.text_field_key && (
                        <div className="input_container">
                          {formValues[row.checkbox_field_key] && (
                            <input
                              required
                              type="text"
                              name={row.text_field_key}
                              placeholder={`${row.label} Notes`}
                              value={formValues[`${row.text_field_key}`]}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  [`${row.text_field_key}`]: e.target.value
                                });
                              }}
                              className="input"
                            />
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            ))}
        </div>
        <div className="original_view_mid">
          <div className="log-rows-category">
            <h3></h3>
          </div>
          <div className="divider"></div>
        </div>

        <div className="original_view_col2">
          {hospiceFields
            .filter((field) => field.col === 2)
            .map((hospiceField, index) => (
              <Fragment key={`${index} hospiceField`}>
                <div className="log-rows-category">
                  <h3>{hospiceField.title}</h3>
                </div>

                <div className="log-rows-form">
                  {hospiceField.rows.map((row, rowIndex) => (
                    <Fragment key={`${index} hospiceField ${rowIndex} row`}>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          name={row.checkbox_field_key}
                          className="checkbox"
                          checked={formValues[row.checkbox_field_key]}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [row.checkbox_field_key]: e.target.checked
                            });
                          }}
                        />

                        <RowLabel label={row.label} />
                      </label>
                      {row.text_field_key && (
                        <div className="input_container">
                          {formValues[row.checkbox_field_key] && (
                            <input
                              required
                              type="text"
                              name={row.text_field_key}
                              placeholder={`${row.label} Notes`}
                              value={formValues[`${row.text_field_key}`]}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  [`${row.text_field_key}`]: e.target.value
                                });
                              }}
                              className="input"
                            />
                          )}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {hospiceFields.map((hospiceField, index) => (
          <Fragment key={`${index} hospiceField`}>
            <div className="log-rows-category">
              <h3>{hospiceField.title}</h3>
            </div>
            <div className="log-rows-form">
              {hospiceField.rows.map((row, rowIndex) => (
                <Fragment key={`${index} hospiceField ${rowIndex} row`}>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      name={row.checkbox_field_key}
                      className="checkbox"
                      checked={formValues[row.checkbox_field_key]}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          [row.checkbox_field_key]: e.target.checked
                        });
                      }}
                    />

                    <RowLabel label={row.label} />
                  </label>
                  {row.text_field_key && (
                    <div className="input_container">
                      {formValues[row.checkbox_field_key] && (
                        <input
                          required
                          type="text"
                          name={row.text_field_key}
                          placeholder={`${row.label} Notes`}
                          value={formValues[`${row.text_field_key}`]}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              [`${row.text_field_key}`]: e.target.value
                            });
                          }}
                          className="input"
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </>
    );
  }
};

ShiftLogFormHospice.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  shiftLog: PropTypes.object,
  hospiceView: PropTypes.string
};

export default ShiftLogFormHospice;

const getHospiceFields = (hospiceView) => {
  const fields = [
    {
      title: "Mobility",
      col: 1,
      rows: [
        {
          checkbox_field_key: "hospice_bed_bound",
          label: "Bed Bound",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_assist_x1",
          label: "Assist x1",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_assist_x2",
          label: "Assist x2",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_lift",
          label: "Lift",
          text_field_key: null
        }
      ]
    },
    {
      title: "Cognition",
      col: 1,
      rows: [
        {
          checkbox_field_key: "hospice_alert",
          label: "Alert",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_drowsy",
          label: "Drowsy",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_somnolent",
          label: "Somnolent",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_semi_coma",
          label: "Semi-coma",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_confusion",
          label: "Confusion",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_hallucination",
          label: "Hallucination",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_agitation",
          label: "Agitation",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_anxiety",
          label: "Anxiety",
          text_field_key: null
        }
      ]
    },
    {
      title: "Nutrition",
      col: 1,
      rows: [
        {
          checkbox_field_key: "hospice_dat_good_poor",
          label: "DAT, Good, Poor",
          text_field_key: "hospice_dat_good_poor_text"
        },
        {
          checkbox_field_key: "hospice_soft",
          label: "Soft",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_fluids",
          label: "Fluids",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_sips",
          label: "Sips",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_npo",
          label: "NPO",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_nausea_vomiting",
          label: "Nausea/Vomiting",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_dysphagia",
          label: "Dysphagia",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_enteral_feed",
          label: "Enteral Feed",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_food_intake",
          label: "Food intake",
          text_field_key: null
        }
      ]
    },
    {
      title: "Elimination",
      col: 1,
      rows: [
        {
          checkbox_field_key: "hospice_bladder_void",
          label: "Bladder void",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_continent_urine",
          label: "Continent Urine",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_foley_neph_amount",
          label: "Foley/Neph amount",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_bowel_void",
          label: "Bowel void",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_continent_feces",
          label: "Continent Feces",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_bm_size",
          label: "BM Small/Med/Large",
          text_field_key: "hospice_bm_size_text"
        },
        {
          checkbox_field_key: "hospice_bm_type",
          label: "BM Normal/Loose/Diarrhea/Constipated",
          text_field_key: "hospice_bm_type_text"
        },
        {
          checkbox_field_key: "hospice_urine_foley_amount",
          label: "Urine Foley Amount",
          text_field_key: "hospice_urine_foley_amount_text"
        },
        {
          checkbox_field_key: "hospice_commode",
          label: "Commode",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_urinal",
          label: "Urinal",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_attends",
          label: "Attends",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_colostomy",
          label: "Colostomy",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_ileo",
          label: "Ileo",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_colostomy_emptied",
          label: "Colostomy emptied",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_colostomy_changed",
          label: "Colostomy changed",
          text_field_key: null
        }
      ]
    },
    {
      title: "Cardio/Resp",
      col: 2,
      rows: [
        {
          checkbox_field_key: "hospice_oxygen",
          label: "Oxygen",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_rate",
          label: "Rate",
          text_field_key: "hospice_rate_text"
        },
        {
          checkbox_field_key: "hospice_o2_sat",
          label: "O2 Sat",
          text_field_key: "hospice_o2_sat_text"
        },
        {
          checkbox_field_key: "hospice_dyspnea",
          label: "Dyspnea",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_congestion",
          label: "Congestion",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_cough",
          label: "Cough",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_hemoptosis",
          label: "Hemoptosis",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_pleurex",
          label: "Pleurex",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_pulse",
          label: "Pulse",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_resp_rate",
          label: "Resp Rate",
          text_field_key: "hospice_resp_rate_text"
        },
        {
          checkbox_field_key: "hospice_apnea",
          label: "Apnea",
          text_field_key: null
        }
      ]
    },
    {
      title: "Skin",
      col: 2,
      rows: [
        {
          checkbox_field_key: "hospice_rash",
          label: "Rash",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_puritis",
          label: "Puritis",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_bruising",
          label: "Bruising",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_edema",
          label: "Edema (A, L, Abd.)",
          text_field_key: "hospice_edema_text"
        },
        {
          checkbox_field_key: "hospice_wound",
          label: "Wound, See tx. sh.",
          text_field_key: "hospice_wound_text"
        },
        {
          checkbox_field_key: "hospice_mottling",
          label: "Mottling",
          text_field_key: null
        }
      ]
    },
    {
      title: "General",
      col: 2,
      rows: [
        {
          checkbox_field_key: "hospice_sleep",
          label: "Sleep",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_teaching",
          label: "Teaching",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_emotional_support",
          label: "Emotional Support",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_bed_alarm",
          label: "Bed Alarm",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_other",
          label: "Other",
          text_field_key: "hospice_other_text"
        }
      ]
    },
    {
      title: "Additional Hygiene",
      col: 2,
      rows: [
        {
          checkbox_field_key: "hospice_bedbath",
          label: "Bedbath",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_partial_bedbath",
          label: "Partial Bedbath",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_shower",
          label: "Shower",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_shave",
          label: "Shave",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_hair_wash",
          label: "Hair Wash",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_peri_care",
          label: "Peri-Care",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_mouth_care",
          label: "Mouth-Care",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_repositioned",
          label: "Repositioned",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_pa_checked",
          label: "P.A. checked",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_nail_care",
          label: "Nail Care",
          text_field_key: null
        },
        {
          checkbox_field_key: "hospice_other_hygiene",
          label: "Other",
          text_field_key: "hospice_other_hygiene_text"
        }
      ]
    }
  ];

  if (hospiceView !== "original") {
    const additionalHygiene = fields.pop();
    fields.unshift(additionalHygiene);
  }

  return fields;
};

const RowLabel = ({ label }) => {
  return (
    <>
      {label === "BM Small/Med/Large" ? (
        <span dangerouslySetInnerHTML={{ __html: "BM <u>S</u>mall/<u>M</u>ed/<u>L</u>arge" }} />
      ) : label === "BM Normal/Loose/Diarrhea/Constipated" ? (
        <span
          dangerouslySetInnerHTML={{
            __html: "BM <u>N</u>ormal/<u>L</u>oose/<u>D</u>iarrhea/<u>C</u>onstipated"
          }}
        />
      ) : (
        label
      )}
    </>
  );
};

RowLabel.propTypes = {
  label: PropTypes.string.isRequired
};
