import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

async function todoListSendActionRequest(id, action_type, body) {
  try {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
    const response = await axios.post(`/to_do_lists/${id}/${action_type}.json`, body, {
      headers: {
        "X-CSRF-Token": csrfToken
      }
    });

    if ((response.status >= 200 && response.status < 300) || response.status === 302) {
      window.location.reload();
    }
  } catch (err) {
    toastr.error("Something went wrong");
  }
}

export async function todoListMarkInCompleteTask(
  id,
  inCompletedToDoDate,
  hourlyRecurrenceTime,
  userInitial,
  reason
) {
  await todoListSendActionRequest(id, "incomplete", {
    incompleted_to_do_date: inCompletedToDoDate,
    hourly_recurrence_time: hourlyRecurrenceTime,
    user_initial: userInitial,
    reason: reason
  });
}

export async function todoListMarkCompleteTask(
  id,
  toDoListDate,
  hourlyRecurrenceTime,
  userInitial,
  reason
) {
  await todoListSendActionRequest(id, "completed", {
    completed_to_date: toDoListDate,
    hourly_recurrence_time: hourlyRecurrenceTime,
    user_initial: userInitial,
    ...(reason ? { completed_future_task_reason: reason } : {})
  });
}

export async function todoListSkipTask(
  id,
  toDoListDate,
  taskToDoListTime,
  seen,
  skipOccurrenceOption,
  userInitial,
  reason
) {
  await todoListSendActionRequest(id, "skip_occurrence", {
    id: id,
    task_completed_to_do_date: toDoListDate,
    task_to_do_list_time: taskToDoListTime,
    initial: userInitial,
    seen: seen,
    skip_occurrence_option: skipOccurrenceOption,
    ...(reason ? { reason: reason } : {})
  });
}

export async function todoListInProgress(id) {
  await todoListSendActionRequest(id, "in_progress", {
    id: id
  });
}
