import React from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// DELETE Calendar library

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function monthStringToNumber(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const monthIndex = monthNames.indexOf(month);

  if (monthIndex !== -1) {
    return monthIndex + 1; // Months are usually represented as 1-12
  } else {
    return null; // Return null if the month string is not valid
  }
}

function formatDateToMonthDay(date) {
  const options = { month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function getFirstDayOfWeek(date) {
  const firstDay = new Date(date);
  const dayOfWeek = firstDay.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diff = firstDay.getDate() - dayOfWeek;
  firstDay.setDate(diff);
  return firstDay;
}

const createWeeksToRender = (month, year) => {
  let firstDayOfMonth = new Date(year, month, 1);
  const dayToStartOn = getFirstDayOfWeek(firstDayOfMonth);
  const weeks = [];
  for (let weekToGenerate = 0; weekToGenerate < 5; weekToGenerate++) {
    const daysInWeek = [];
    const dayToStartForThisWeek = new Date(dayToStartOn);
    dayToStartForThisWeek.setDate(dayToStartOn.getDate() + weekToGenerate * 7);
    console.log("RIGHT", dayToStartForThisWeek);
    for (let dayOfWeekGenerate = 0; dayOfWeekGenerate < 7; dayOfWeekGenerate++) {
      const dayToGenerate = new Date(dayToStartForThisWeek);
      dayToGenerate.setDate(dayToStartForThisWeek.getDate() + dayOfWeekGenerate);
      daysInWeek.push(dayToGenerate);
    }
    weeks.push(daysInWeek);
  }
  return weeks;
};

const ShiftLogCalendar = ({ month, year, dates }) => {
  const weeksToRender = useMemo(() => {
    console.log(month, year);
    return createWeeksToRender(monthStringToNumber(month), year);
  }, [month, year]);

  console.log(dates);

  return (
    <StyledTable>
      <thead>
        <StyledRow>
          <StyledHeader>Sunday</StyledHeader>
          <StyledHeader>Monday</StyledHeader>
          <StyledHeader>Tuesday</StyledHeader>
          <StyledHeader>Wednesday</StyledHeader>
          <StyledHeader>Thursday</StyledHeader>
          <StyledHeader>Friday</StyledHeader>
          <StyledHeader>Saturday</StyledHeader>
        </StyledRow>
      </thead>
      <tbody>
        {weeksToRender.map((days, rowIndex) => (
          <StyledRow key={rowIndex}>
            {days.map((day, cellIndex) => {
              // dates date is format year month day
              const dateLink = dates.find((date) => date.date === formatDate(day));
              return (
                <StyledCell key={cellIndex}>
                  <p>{formatDateToMonthDay(day)}</p>
                  <LinkButton className="shift-one" href={dateLink.nurse_recap}>
                    Nurse Recap
                  </LinkButton>
                  <SpacerBetweenLinkButton />
                  <LinkButton className="shift-one" href={dateLink.shift_log}>
                    Shift Log
                  </LinkButton>
                </StyledCell>
              );
            })}
          </StyledRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

ShiftLogCalendar.propTypes = {
  month: PropTypes.number,
  year: PropTypes.number,
  dates: PropTypes.array
};

export default ShiftLogCalendar;

// Define a styled table
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

// Define a styled table row
const StyledRow = styled.tr``;

// Define a styled table header
const StyledHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #26547c;
  color: white !important;
`;

// Define a styled table cell
const StyledCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  /* &:nth-child(even) {
    background-color: #f2f2f2;
  } */
`;

const LinkButton = styled.a`
  background-color: #e7e7e7;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 0.5rem;
`;

const SpacerBetweenLinkButton = styled.div`
  height: 0.5rem;
`;
