/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import DynamicTable from "../dynamic_table";

import PropTypes from "prop-types";

const HealthReportExportButton = () => {
  const handleExportClick = () => {
    document.querySelector("#client-page-header-download-health-report").click();
  };

  return (
    <div
      className="button is-primary is-outlined"
      explorator_test_id="export-data-page-download-health-report"
      onClick={handleExportClick}
    >
      Export Client Health card issue Report
    </div>
  );
};
const ExportButton = () => {
  return (
    <a
      href="/export_data_advanced"
      className="button is-primary"
      explorator_test_id="export-data-page-advanced-export"
    >
      Export Data
    </a>
  );
};
const RefreshButton = ({ onRefresh }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await onRefresh();
    setIsRefreshing(false);
  };

  return (
    <div className="has-text-right">
      <button className="button" explorator_test_id={"refresh-jobs-button"} onClick={handleRefresh}>
        <CachedIcon className={isRefreshing ? "spin" : ""} />
        Refresh
      </button>
    </div>
  );
};

RefreshButton.propTypes = {
  onRefresh: PropTypes.func.isRequired
};

const ExportDataView = () => {
  const [jobs, setJobs] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    next_page: 1,
    prev_page: 1
  });

  const fetchData = async (page = 1) => {
    try {
      const response = await fetch(`/export_data/tracking_jobs.json?page=${page}`);
      const data = await response.json();

      setJobs(data.jobs);
      setPagination({
        currentPage: data.pagination.current_page,
        totalPages: data.pagination.total_pages,
        next_page: data.pagination.next_page,
        prev_page: data.pagination.prev_page
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (page) => {
    fetchData(page);
  };

  return (
    <div className="export_data_view_component">
      <div className="export_data_view_component_buttons">
        <div className="export_data_view_component_buttons_left_menu">
          <ExportButton />
          <HealthReportExportButton />
        </div>

        <RefreshButton onRefresh={fetchData} />
      </div>

      <div className="export-data-jobs">
        <DynamicTable data={jobs} columns={JobsColumns} />
      </div>
      <nav className="pagination">
        {pagination.prev_page && (
          <a
            rel="prev"
            className="pagination-previous"
            onClick={() => handlePageChange(pagination.prev_page)}
          >
            ‹ Prev
          </a>
        )}{" "}
        {pagination.next_page && (
          <a
            rel="next"
            className="pagination-next"
            onClick={() => handlePageChange(pagination.next_page)}
          >
            Next ›
          </a>
        )}
        <ul className="pagination-list">
          {[...Array(pagination.totalPages).keys()].map((n) => (
            <li key={n + 1}>
              <a
                className={`pagination-link ${
                  pagination.currentPage === n + 1 ? "is-current" : ""
                }`}
                onClick={() => handlePageChange(n + 1)}
              >
                {n + 1}
              </a>
            </li>
          ))}
          {pagination.currentPage < pagination.totalPages ? (
            <a className="pagination-link" onClick={() => handlePageChange(pagination.totalPages)}>
              {`Last »`}
            </a>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};

const JobsColumns = ["Table Name", "Description", "Status", "Time used", "Created At"];

export default ExportDataView;
