import React from "react";
import { useDenseViewContext } from "./DenseViewContext";

import CustomSelectFields from "./custom_select_fields";

const Filter = () => {
  const { state, dispatch } = useDenseViewContext();

  return (
    <div className="dense-view-filter">
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state.clientName}
            onChange={() => {
              dispatch({ type: "clientName", payload: !state.clientName });
            }}
          />
          Client Name
        </label>
      </div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state.shiftNumber}
            onChange={() => {
              dispatch({ type: "shiftNumber", payload: !state.shiftNumber });
            }}
          />
          Shift Number
        </label>
      </div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state.location}
            onChange={() => {
              dispatch({ type: "location", payload: !state.location });
            }}
          />
          Location
        </label>
      </div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state.loggedBy}
            onChange={() => {
              dispatch({ type: "loggedBy", payload: !state.loggedBy });
            }}
          />
          Logged By
        </label>
      </div>
      <div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={state.whatHappened}
            onChange={() => {
              dispatch({ type: "whatHappened", payload: !state.whatHappened });
            }}
          />
          What happened?
        </label>
      </div>
      <div className="select is-rounded" style={{ height: "2rem" }}>
        <select
          style={{ height: "2rem" }}
          onChange={(e) => {
            const value = e.target.value;
            dispatch({ type: "isSeen", payload: value });
          }}
        >
          <option value="All">All</option>
          <option value="Seen">Seen</option>
          <option value="NotSeen">Not Seen</option>
        </select>
      </div>
      <CustomSelectFields />
    </div>
  );
};

export default Filter;
