import React from "react";

import PropTypes from "prop-types";
import MentionEditor from "./MentionEditor";
import axios from "axios";
import { useFetchData } from "../../hooks/useFetchData";

export default function CustomerEditor({
  defaultContent = "",
  name,
  isClientMentioned = false,
  setIsClientMentioned = () => {}
}) {
  function updateHiddenInput(newContent) {
    const hiddenInput = document.querySelector(`[name="${name}"]`);
    if (hiddenInput) {
      hiddenInput.value = newContent;
      // The attr "data-value" is being used by nurse_log_editor
      hiddenInput.setAttribute("data-value", newContent);
    }
  }
  const { data: mentions, error, loading } = useFetchData(getClientList);

  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Something went wrong</div>;
  } else {
    return (
      <MentionEditor
        defaultHTML={defaultContent}
        mentions={mentions}
        setHtmlOutput={updateHiddenInput}
        // Disabling Nurse Recap Features in Current Deployment
        isClientMentioned={isClientMentioned}
        setIsClientMentioned={setIsClientMentioned}
      />
    );
  }
}

CustomerEditor.propTypes = {
  defaultContent: PropTypes.string,
  name: PropTypes.string.isRequired,
  isClientMentioned: PropTypes.bool,
  setIsClientMentioned: PropTypes.func
};

async function getClientList() {
  const response = await axios.get("/clients/client_autocomplete_authorized_clients.json");

  const filteredClients = response.data.filter(
    (client) => client.inactive === false && client.is_deceased === false
  );
  const sortedClients = filteredClients.sort((a, b) => a.first_name.localeCompare(b.first_name));

  return sortedClients.map((client) => {
    const clientName = `${client.first_name} ${client.last_name}`;

    return {
      name: clientName + " #" + client.id.toString(),
      link: client.id.toString()
    };
  });
}
