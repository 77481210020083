// This function is used to create a path for the client

class ClientMention {
  constructor(clientId) {
    this.clientId = clientId;
  }

  get publicPath() {
    const currentHost = window.location.host;
    const currentProtocol = window.location.protocol;
    return `${currentProtocol}//${currentHost}/clients/${this.clientId}`;
  }
  get editorPath() {
    return `/clients/${this.clientId}`;
  }
}

class ExtractClientLog {
  constructor(html, clientName) {
    this.clientName = clientName;
    this.html = html;
    this.regex = /<p>.*?<\/p>(?:\s*<p>(?!.*?\[END\]).*?<\/p>)*\s*<p>.*?\[END\].*?<\/p>/g;
  }

  get groups() {
    let match;
    const groups = [];
    while ((match = this.regex.exec(this.html)) !== null) {
      groups.push(match[0]);
    }
    // Filter out groups that don't contain the 'mention' class
    const filteredGroups = groups
      .map((group) => {
        // The group should that match the following rules:
        //  - Starts with <p> with class mention
        //  - Ends with [END]
        // - Anything in between that does not contain <a class="mention">

        let matches = [];
        let inMatch = false;
        let match = "";

        group.split("<p>").forEach((part) => {
          if (part.includes("<a") && part.includes('class="mention"')) {
            inMatch = true;
            match = "<p>" + part;
          } else if (inMatch) {
            match += "<p>" + part;
          }

          if (part.includes("[END]")) {
            inMatch = false;
            matches.push(match);
            match = "";
          }
        });

        if (matches.length > 0) {
          return matches[0];
        } else {
          return null;
        }
      })
      .filter((group) => group !== null);
    return filteredGroups;
  }

  // Return the HTML that is not matched by the client regex
  // Meaning the log(HTML) does not contain any client mentions, thus return the whole HTML
  get generalGroups() {
    let unmatchedHTML = this.html;
    const matches = this.html.match(this.clientRegex);

    if (matches) {
      matches.forEach((match) => {
        unmatchedHTML = unmatchedHTML.replace(match, "");
      });
    }

    return unmatchedHTML;
  }

  // Return only the log that contains the client mention
  get clientGroups() {
    // Further filter out groups that don't include the client name
    const clientFilteredGroups = this.groups.filter((group) => group.includes(this.clientName));

    // Remove the Client mention tag and the [END] tag
    return clientFilteredGroups.map((group) => this._cleanUpClientGroup(group));
  }

  _cleanUpClientGroup(clientGroup) {
    let cleanedText = clientGroup.replace(/<a[^>]*class="mention"[^>]*>[^<]*<\/a>/g, "");
    cleanedText = cleanedText.replace(/\[END\]/g, "");
    return cleanedText;
  }
}

const CustomEditorUtils = {
  createClientMention: (clientId) => {
    return new ClientMention(clientId);
  },
  ExtractClientLog
};
export default CustomEditorUtils;
