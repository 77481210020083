import React from "react";
import PropTypes from "prop-types";

import { useDenseViewContext } from "./DenseViewContext";
import ReadMore from "../read_more";
import ShiftLogContainerHospice from "./shift_log_container_hospice";
import { getClientPath, getEditShiftLogPath, getFormattedDateTime } from "./utility_functions";
import { ShiftLogDetailsDisplay } from "./ShiftLogDetailsDisplay";

const ShiftLogContainer = ({ shiftLog }) => {
  const { state: filterFields } = useDenseViewContext();

  return (
    <div
      className="box"
      id="client-shift-log-container"
      data-explorator_test_id="client-shift-log-container"
    >
      <div className="log-display">
        <div>
          {filterFields.clientName && (
            <a className="client-name" href={getClientPath(shiftLog)}>
              {shiftLog.client_name}
            </a>
          )}
        </div>
        <div className="hover-field">
          <a href={getEditShiftLogPath(shiftLog)} className="button is-primary" id="edit-icon">
            <i className="fas fa-edit"></i>
          </a>
          <p className="hover-text">edit</p>
        </div>
      </div>
      <div className={`seen-log ${shiftLog.seen ? "is-seen" : "is-not-seen"}`}>
        <b>{shiftLog.seen ? "Seen" : "Not seen"}</b>
      </div>

      <div className="log-display">
        <div>
          {filterFields.shiftNumber && (
            <>
              <b>Shift Number:</b>
              {` ${shiftLog.shift_num}`}
            </>
          )}
        </div>
        <div>
          {filterFields.location && (
            <>
              <b>Location:</b>
              {` ${shiftLog.location}`}
            </>
          )}
        </div>
      </div>
      <div className="log-display">
        {filterFields.whatHappened && (
          <div>
            <b>Happened at: </b>
            <i className="fas fa-clock"></i>
            {` ${getFormattedDateTime(shiftLog)}`}
          </div>
        )}
        {filterFields.loggedBy && (
          <div>
            <b>Logged by: </b>
            <p className="highlight">{shiftLog.logged_by}</p>
          </div>
        )}
      </div>

      {shiftLog.location === "Diane Morrison Hospice" ? (
        <ShiftLogContainerHospice shiftLog={shiftLog} />
      ) : (
        <ShiftLogDetailsDisplay shiftLog={shiftLog} />
      )}

      {filterFields.what_happened && (
        <div className="log-what-happened">
          <b>What happened?</b>
          {shiftLog.log_description ? <ReadMore text={shiftLog.log_description} /> : <p>N/A</p>}
        </div>
      )}
    </div>
  );
};

ShiftLogContainer.propTypes = {
  shiftLog: PropTypes.object.isRequired
};

export default ShiftLogContainer;
