import React from "react";
import PropTypes from "prop-types";

const InventoryQuantity = ({ quantity, className = "", style = {} }) => {
  return (
    <div className={`${quantityClass(quantity)} ${className}`.trim()} style={style}>
      {quantity}
    </div>
  );
};
InventoryQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};
export default InventoryQuantity;

function quantityClass(quantity) {
  if (quantity < 10) {
    return "quantity-danger";
  } else if (quantity >= 10 && quantity <= 20) {
    return "quantity-warning";
  } else {
    return "quantity-good";
  }
}
