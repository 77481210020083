import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableComponent from "./dynamic_table/DynamicTableComponent";
import TextFilterComponent from "./dynamic_table/TextFilterComponent";

export const DynamicTableContext = createContext();
function DynamicTable({ data, columns, showFilter = false, showId = true }) {
  const [filteredData, setFilteredData] = useState(data);
  const [sortingColumnIndex, setSortingColumnIndex] = useState(null);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <DynamicTableContext.Provider
      value={{
        columns,
        data,
        filteredData,
        setFilteredData,
        sortingColumnIndex,
        setSortingColumnIndex
      }}
    >
      <div>
        {showFilter && <TextFilterComponent />}
        <TableComponent showId={showId} />
      </div>
    </DynamicTableContext.Provider>
  );
}

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  showFilter: PropTypes.bool,
  showId: PropTypes.bool
};

export default DynamicTable;
