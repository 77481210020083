import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ShiftLogVersionsList = ({ version, htmlContent }) => {
  return (
    <Accordion className="shift_log_version_summary" disabled={!htmlContent}>
      <AccordionSummary
        expandIcon={htmlContent ? <ExpandMoreIcon /> : null}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="shift_log_version_container">
          <div>Event Type: </div>
          <div> {version.event.toUpperCase()}</div>
          <div>Updated Time: </div>
          <div> {version.created_at}</div>
          <div>Updated by: </div>
          <div> {version.user_name || "Unknown"}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {htmlContent && (
          <div className="htmlContent" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
ShiftLogVersionsList.propTypes = {
  version: PropTypes.shape({
    id: PropTypes.number.isRequired,
    event: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    user_name: PropTypes.string
  }).isRequired,
  htmlContent: PropTypes.string
};
export default ShiftLogVersionsList;
