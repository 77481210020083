import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const ActionDropdown = ({ buttonText, actions, buttonWidth = "", dropdownWidth = "" }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="action-menu">
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="toggle-button"
        tabIndex="0"
      >
        <div className="button is-primary" style={buttonWidth ? { width: buttonWidth } : {}}>
          {buttonText}
        </div>
        <div className="button is-primary">
          <i className="fas fa-chevron-down"></i>
        </div>
      </div>
      {isDropdownOpen && (
        <ul className="dropdown-menu" style={dropdownWidth ? { width: dropdownWidth } : {}}>
          {actions.map(({ actionText, actionFn, show }) => {
            return (
              show && (
                <li
                  key={actionText}
                  onClick={() => {
                    actionFn();
                    setIsDropdownOpen(false);
                  }}
                >
                  {actionText}
                </li>
              )
            );
          })}
        </ul>
      )}
    </div>
  );
};

ActionDropdown.propTypes = {
  buttonText: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionText: PropTypes.string.isRequired,
      actionFn: PropTypes.func.isRequired,
      show: PropTypes.bool
    }).isRequired
  ).isRequired,
  buttonWidth: PropTypes.string,
  dropdownWidth: PropTypes.string
};

export default ActionDropdown;
